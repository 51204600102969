import React from "react";
import connectAllProps from "../../../shared/connect";
import { Redirect } from "react-router-dom";
import { Container, Header } from "semantic-ui-react";
import TableViewEditor from "./TableViewEditor";
import { CONTAINER_STYLE } from "../../../shared/styles";
import { FormattedMessage } from "react-intl";
import { isMobile } from "../dimension";
import { pendingRedirect } from "../../../shared/redirect";
import { ComponentProps as Props } from "../../../shared/ComponentProps";
import TableViewType from "../../../models/TableViewType";
import { PaginationStyle, TableViewViewType } from "@airjam/types";
import { sort } from "shelljs";

interface States { }

class CreateTableView extends React.Component<Props, States> {
    componentDidMount() {
        // this.props.state.tableViewState.loading = false;
        // console.log("loaded");
    }

    render(): React.ReactElement<any> {
        if (pendingRedirect(this.props)) {
            return <Redirect to={this.props.state.redirectTask.to} />;
        } else if (this.props.state.userState.currentUser) {
            const loading: boolean | undefined = this.props.state.tableViewState.loading;
            const containerStyle: any = isMobile() ? CONTAINER_STYLE :
                { ...CONTAINER_STYLE, paddingLeft: 20, paddingRight: 20 };
            return (
                <Container style={containerStyle}>
                    <Header size={"medium"}>
                        <FormattedMessage id="page.table.view.add" />
                    </Header>
                    <TableViewEditor
                        onSubmit={this.addTableView}
                        submitTextId="component.button.submit"
                        loading={loading} />
                </Container>
            );
        } else {
        return <p>not cool</p>;
        }
    }
    private addTableView = (projectId: string, title: string, viewType: string, paginationStyle: string, fieldMapping: any, sortBy: string): void => {
        if (this.props.state.userState.currentUser) {
            // fix this later
            // this.props.actions.addTableView(
            //     projectId,
            //     title,
            //     this.getViewType(viewType),
            //     this.getPaginationStyle(paginationStyle),
            //     "",
            //     "",
            //     fieldMapping,
            //     sortBy);
        }
    }
    private getViewType(viewTypeString: string): TableViewType {
        return TableViewType.GoogleSheet;
    }

    private getPaginationStyle(paginationStyleString: string): PaginationStyle {
        const pagination = paginationStyleString.toUpperCase();
        switch (pagination) {
            case PaginationStyle.NoPagination.toUpperCase():
                return PaginationStyle.NoPagination;
            case PaginationStyle.Paged.toUpperCase():
                return PaginationStyle.Paged;
            default:
                return PaginationStyle.InfiniteScroll;
        }
    }

    private getTableViewType(tableViewTypeString: string): TableViewViewType {
        const viewType = tableViewTypeString.toUpperCase();
        switch (viewType) {
            case TableViewViewType.Gallery.toUpperCase():
                return TableViewViewType.Gallery;
            default:
                return TableViewViewType.List;
        }
    }
}

export default connectAllProps(CreateTableView);