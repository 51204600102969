import React, { Fragment } from "react";
import connectAllProps from "../../../shared/connect";
import { Appointment } from "../../../models/Appointment";
import { byCreatedAtLatestFirst } from "../../../shared/date";
import { Container, Segment, Header, Icon, Button } from "semantic-ui-react";
import AppointmentItem from "./AppointmentItem";
import { CONTAINER_STYLE } from "../../../shared/styles";
import Loading from "./Loading";
import { FormattedMessage } from "react-intl";
import FabAction from "../../../models/client/FabAction";
import { ComponentProps as Props } from "../../../shared/ComponentProps";
import { Link } from "react-router-dom";

interface States {}

class AppointmentList extends React.Component<Props, States> {
    render(): React.ReactElement<any> {
        return <Container text style={CONTAINER_STYLE}>
            {this.renderCreateAppointmentSection()}
            {this.renderAppointments()}
            {this.renderLoadMore()}
        </Container>;
    }
    componentDidMount() {
        this.props.actions.resetRedirectTask();
        this.addFabActions();
    }
    componentDidUpdate(prevProps: Props) {
        if ((prevProps.state.appointmentState.loading
            && !this.props.state.appointmentState.loading) ||
            (!prevProps.state.userState.currentUser
            && this.props.state.userState.currentUser)) {
            this.addFabActions();
        }
    }
    componentWillUnmount() {
        this.props.actions.setFabActions([]);
    }
    private renderAppointments = (): React.ReactElement<any> => {
        if (this.props.state.appointmentState.loading) {
            return <Loading />;
        } else {
            return <Fragment>
            {
                this.props.state.appointmentState.data
                .sort(byCreatedAtLatestFirst).map(
                    (appointment: Appointment) => <AppointmentItem key={appointment._id} appointment={appointment} />
                )
            }
            </Fragment>;
        }
    }
    private addFabActions = (): void => {
        if (this.props.state.userState.currentUser) {
            const editUri: string = "/appointments/create";
            const actions: FabAction[] = [{
                text: this.props.intl.formatMessage({id: "page.appointment.add"}),
                icon: "add",
                onClick: () => { this.props.history.push(editUri); },
            }];
            this.props.actions.setFabActions(actions);
        }
    }
    private renderCreateAppointmentSection = (): React.ReactElement<any> | undefined => {
        const appointments: Appointment [] = this.props.state.appointmentState.data;
        if (this.props.state.appointmentState.loading) {
            return <Loading />;
        } else if (this.props.state.userState.currentUser) {
            if (!appointments || appointments.length === 0) {
                return <Segment placeholder textAlign="center">
                        <Header icon>
                            <Link to="/appointments/create">
                                <Icon name="plus circle" />
                                You do not have any components yet.
                            </Link>
                        </Header>
                        Click on the + button below to create a new component.
                    </Segment>;
            }
            return <></>;
        } else {
            if (appointments && appointments.length > 0) {
                return undefined;
            } else {
                return <></>;
            }
        }
    }

    private renderLoadMore = (): React.ReactElement<any> | undefined => {
        const appointments: Appointment [] = this.props.state.appointmentState.data;
        if (this.props.state.appointmentState.hasMore) {
            const loadingMore: boolean | undefined = this.props.state.appointmentState.loadingMore;
            const createdAt: string | undefined = appointments[appointments.length - 1].createdAt;
            if (!createdAt) {
                return undefined;
            }
            return <Button fluid basic
                onClick={() => { this.loadMore(createdAt); }}
                loading={loadingMore}
                disabled={loadingMore} >
                <Button.Content>
                    <FormattedMessage id="page.appointment.load_more" />
                </Button.Content>
            </Button>;
        } else {
            return undefined;
        }
    }

    private loadMore = (createdAt: string): void => {
        this.props.actions.getMoreAppointments(createdAt);
    }
}

export default connectAllProps(AppointmentList);