import Project from "../../../models/Project";
import React from "react";
import { Card } from "semantic-ui-react";
import connectAllProps from "../../../shared/connect";
import { Viewer } from "@toast-ui/react-editor";
import { ComponentProps } from "../../../shared/ComponentProps";
import { MessageDescriptor } from "react-intl";
import { PrimitiveType } from "intl-messageformat";

interface Props extends ComponentProps {
    project: Project;
}

interface States {}

class ProjectItem extends React.Component<Props, States> {
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.getString = this.props.intl.formatMessage;
    }
    render(): React.ReactElement<any> {
        const { project } = this.props;
        const createDate: Date = project.createdAt ? new Date(project.createdAt) : new Date(0);
        const componentCount: string = this.getString({ id: "page.project.component.count" }, { count: project.componentCount });
        return <Card key={createDate.getMilliseconds()} className="hollowSegment" onClick={() => this.props.history.push(`/project/${project._id}`)}>
                    <Card.Content textAlign="center" className="extraTopBottomPadding">
                        <Card.Header as="small">{project.title}</Card.Header>
                    <Viewer style={{ height: 3 }} initialValue={componentCount} />
                    </Card.Content>
                    <Card.Content extra textAlign="center">
                        { this.getString({ id: "component.button.open" }) }
                    </Card.Content>
                </Card>;
    }
}

export default connectAllProps(ProjectItem);