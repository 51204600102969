import React from "react";
import { Header, Container } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
interface Props {}

interface States {}
export default class Privacy extends React.Component<Props, States> {
    render() {
        return <Container text style={CONTAINER_STYLE}>

            <Header size="medium">Privacy Policy</Header>
            <Header sub>Last updated: 1st March 2024</Header>

            As a company we always try to avoid collecting data, though sometimes we do collect a small amount in order to offer you the services that we do. This page will outline the type of data we do collect, why we collect it, and how you can request a copy of it.

            <Header size="small">
                What data we collect
            </Header>
            The personal data we collect from you includes:<br></br>

            <ul>
                <li>questions, queries or feedback you leave, including your email address if you contact us</li>
                <li>your email address when you sign up</li>
                <li>OAuth access and refresh tokens from your Google account</li>
                <li>the names of the spreadsheets you add in the AirJam dashboard, and corresponding identifiers</li>
                <li>your Internet Protocol (IP) address, and details of which version of web browser you used</li>
                <li>a log of how many times your API has been accessed</li>
                <li>reference token for your payment methods provided by our payment processing partners</li>
            </ul>

            <Header size="small">
                What we do with your data
            </Header>
            The data we collect may be shared with our technology suppliers, for example our hosting provider.<br></br>

            We will not:<br></br>

            <ul>
                <li>sell or rent your data to third parties</li>
                <li>share your data with third parties for marketing purposes</li>
                <li>We will share your data if we are required to do so by law - for example, by court order, or to prevent fraud or other crime.</li>
            </ul>

            We use Google Analytics to collect information about how you use our websites. This includes IP addresses. The data is anonymised before being used for analytics processing.<br></br>
            Google Analytics processes anonymised information about:

            <ul>
                <li>the pages you visit on our websites</li>
                <li>how long you spend on each of our website pages</li>
                <li>how you got to the site</li>
                <li>what you click on while you're visiting the site</li>
            </ul>

            We will not identify you through analytics information, and we will not combine analytics information with other data sets in a way that would identify who you are.<br></br>

            We use Google Cloud as our service provider. Google will see:

            <ul>
                <li>the requests made to your API, including the POST/PUT body</li>
                <li>the response from AirJam</li>
                <li>the IP addresses of users accessing your API</li>
            </ul>


            <Header size="small">How long we keep your data</Header>
            We will only retain your personal data for as long as:

            <ul>
                <li>it is needed for the purposes set out in this document</li>
                <li>We will keep your email data until you unsubscribe.</li>
                <li>We will delete access log data after 120 days.</li>
            </ul>

            <Header size="small">Children's privacy protection</Header>

            Our services are not designed for, or intentionally targeted at, children 13 years of age or younger. We do not intentionally collect or maintain data about anyone under the age of 13.<br></br>

            Where your data is processed and stored We design, build and run our systems to make sure that your data is as safe as possible at all stages, both while it's processed and when it's stored.<br></br>

            <Header size="small">How we protect your data and keep it secure.</Header>

            We are committed to doing all that we can to keep your data secure. We have set up systems and processes to prevent unauthorised access or disclosure of your data - for example, we protect your data using varying levels of encryption.<br></br>

            We also make sure that any third parties that we deal with keep all personal data they process on our behalf secure.

            <Header size="small">Your rights</Header>
            You have the right to request:

            <ul>
                <li>information about how your personal data is processed</li>
                <li>a copy of that personal data</li>
                <li>that anything inaccurate in your personal data is corrected immediately You can also:</li>
                <li>raise an objection about how your personal data is processed</li>
                <li>request that your personal data is erased if there is no longer a justification for it</li>
                <li>ask that the processing of your personal data is restricted in certain circumstances</li>
                <li>If you have any of these requests, get in contact by emailing contact@airjam.co</li>
            </ul>

            Changes to this policy We may change this privacy policy. In that case, the 'last updated' date at the top of this page will also change. Any changes to this privacy policy will apply to you and your data immediately.<br></br>

            If these changes affect how your personal data is processed, Creative Construct, Inc will take reasonable steps to let you know.<br></br>

        </Container>;
    }
}