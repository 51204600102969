import { DropdownItemProps } from "semantic-ui-react";
import { SortBy } from "@airjam/types";
import { ComponentProps as Props } from "../../../shared/ComponentProps";

export default function sortByOptions(props: Props): DropdownItemProps[] {
    return Array.from(Object.keys(SortBy) as Array<keyof typeof SortBy>).map((key) => {
        return {
            key: key.toString(),
            text: props.intl.formatMessage({ id: "component.dropdown.sort_by." + SortBy[key].toString() }),
            value: SortBy[key].toString()
        } as DropdownItemProps;
    });
}
