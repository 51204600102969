export enum TemplateNames {
    OPT = "OPT",
    ReservationConfirmation = "reservation_confirmation",
    ReservationRejection = "reservation_rejection",
    ReservationPending = "reservation_pending",
    ReservationCanceled = "reservation_canceled",
    HostNewReservation = "host_new_reservation",
    HostNewReservationPending = "host_new_reservation_pending",
    HostGuestCanceled = "host_guest_canceled",
    HostYouCanceled = "host_you_canceled",
    ModerateTimeout = "moderate_timeout",
}
