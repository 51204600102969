import { AnyAction as Action } from "redux";
import CalendarState from "../models/client/CalendarState";
import { GET_CALENDAR_SUCCESS, SAVE_CALENDAR_SUCCESS, GET_CALENDAR_BEGIN, SAVE_CALENDAR_BEGIN, SAVE_CALENDAR_FAILED, GET_CALENDAR_FAILED, GET_MORE_CALENDAR_SUCCESS, GET_MORE_CALENDAR_BEGIN, GET_MORE_CALENDAR_FAILED, REMOVE_CALENDAR_BEGIN, REMOVE_CALENDAR_SUCCESS, REMOVE_CALENDAR_FAILED } from "../actions/calendar";
import { UPDATE_PROFILE_SUCCESS } from "../actions/user";
import { ADD_COMMENT_SUCCESS, REMOVE_COMMENT_SUCCESS } from "../actions/comment";
import PostType from "../models/PostType";

const initialState: CalendarState = {
    loading: false,
    valid: false,
    data: [],
    loadingMore: false,
    hasMore: false
};

const calendar = (state: CalendarState = initialState, action: Action): CalendarState => {
    switch (action.type) {
        case GET_CALENDAR_BEGIN:
        case SAVE_CALENDAR_BEGIN:
        case REMOVE_CALENDAR_BEGIN:
            return {...state, loading: true};
        case GET_CALENDAR_SUCCESS:
            return {
                ...state,
                data: action.calendars,
                valid: true,
                loading: false,
                hasMore: action.hasMore
            };
        case GET_MORE_CALENDAR_BEGIN:
            return {...state, loadingMore: true};
        case GET_MORE_CALENDAR_SUCCESS:
            return {
                ...state,
                data: [...state.data, ...action.calendars],
                loadingMore: false,
                hasMore: action.hasMore
            };
        case SAVE_CALENDAR_SUCCESS:
            // merge the added/updated calendar instantly, without waiting for the calendar list fetching
            return {...state, valid: false, loading: false, data: [...state.data, action.calendar]};
        case REMOVE_CALENDAR_SUCCESS:
        case UPDATE_PROFILE_SUCCESS:
            return {...state, valid: false, loading: false};
        case ADD_COMMENT_SUCCESS:
        case REMOVE_COMMENT_SUCCESS:
            if (action.targetType === PostType.CALENDAR) {
                return {...state, valid: false, loading: false};
            } else {
                return state;
            }
        case GET_CALENDAR_FAILED:
        case REMOVE_CALENDAR_FAILED:
        case SAVE_CALENDAR_FAILED:
            return {...state, loading: false};
        case GET_MORE_CALENDAR_FAILED:
            return {
                ...state,
                loadingMore: false,
                hasMore: false
            };
        default:
            return state;
    }
};

export default calendar;