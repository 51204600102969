import React from "react";
import connectAllProps from "../../../shared/connect";
import { Button } from "semantic-ui-react";
import { ComponentProps } from "../../../shared/ComponentProps";
import { TableViewViewType } from "@airjam/types";
import { MessageDescriptor } from "react-intl";
import { PrimitiveType } from "intl-messageformat";

interface States {
    selectedType?: string;
}

interface Props extends ComponentProps {
    componentId: string;
    initialType?: string;
    key: string;
    onChange: (componentId: string, newType: string) => void;
}

class ViewTypeSelect extends React.Component<Props, States> {
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;

    // [ViewType.Board.toString().toLowerCase()]: "fas fa-clipboard-list fa-2x",
    private iconLabels: {[id: string]: string} = {
        [TableViewViewType.List.toString().toLowerCase()]: "fas fa-stream fa-2x",
        [TableViewViewType.Gallery.toString().toLowerCase()]: "fas fa-images fa-2x",
        [TableViewViewType.Graph.toString().toLowerCase()]: "fas fa-chart-line fa-2x",
        [TableViewViewType.Table.toString().toLowerCase()]: "fas fa-table fa-2x",
        [TableViewViewType.Map.toString().toLowerCase()]: "fas fa-map-location-dot",
        [TableViewViewType.Nearby.toString().toLowerCase()]: "fa-solid fa-location-arrow",
        [TableViewViewType.Spotlight.toString().toLowerCase()]: "fas fa-search",
    };
    constructor(props: Props) {
        super(props);
        this.getString = this.props.intl.formatMessage;
        this.state = {
            selectedType: props.initialType
        };
    }

    render(): React.ReactElement<any> {
        return <div key={this.props.key}>
            {
                Object.keys(TableViewViewType).map((viewKey: string) => {
                    return this.viewTypeButton(viewKey);
                })
            }
        </div>;
    }

    // key is assumed to be in lowercase
    private viewTypeButton = (typeKey: string): React.ReactElement<any> => {
        const keyIdx = Object.keys(TableViewViewType).indexOf(typeKey);
        const enumValue = Array.from(Object.values(TableViewViewType))[keyIdx];
        return <Button icon className={"iconButton" + this.classNameIfSelected(typeKey)} onClick={() => this.viewTypeSelected(typeKey)} key={this.props.key + typeKey}>
                <i className={this.iconLabels[enumValue]}></i>
                <div>{this.getString({ id: ("component.view_type.label." + typeKey).toLowerCase() }).toLowerCase()}</div>
            </Button>;
    }

    private classNameIfSelected = (typeKey: string): string => {
        if (this.state.selectedType && (this.state.selectedType!.toLowerCase() === typeKey.toLowerCase())) {
            return " selected";
        }
        return "";
    }

    private viewTypeSelected = (newTypeKey: string) => {
        this.setState({
            selectedType: newTypeKey.toLowerCase()
        });
        if (this.props.onChange) this.props.onChange(this.props.componentId, newTypeKey);
        this.forceUpdate();
    }
}

export default connectAllProps(ViewTypeSelect);