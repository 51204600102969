import React, { Fragment } from "react";
// eslint-disable-next-line
import { Route, Switch, match } from "react-router";
import connectAllProps from "../../shared/connect";
import CalendarList from "../components/calendar/CalendarList";
import CreateCalendar from "../components/calendar/CreateCalendar";
import { ComponentProps as Props } from "../../shared/ComponentProps";
import EditCalendar from "../components/calendar/EditCalendar";
import CalendarDetail from "../components/calendar/CalendarDetail";

interface States { }

class Calendars extends React.Component<Props, States> {
    componentDidMount() {
        this.props.actions.getCalendars();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.state.blockState.valid && !this.props.state.blockState.valid) {
            this.props.actions.getCalendars();
        }
    }

    render(): any {
        const match: match<any> = this.props.match;
        return <Fragment>
            <Switch>
                <Route exact path={match.url} render={(props) => <CalendarList {...props} />} />
                <Route path={`${match.url}/create`} render={(props) => <CreateCalendar {...props} />} />
                <Route path={`${match.url}/edit/:calendarId`} render={(props) => <EditCalendar {...props} />} />
                <Route path={`${match.url}/:calendarId`} render={(props) => <CalendarDetail {...props} />} />
            </Switch>
        </Fragment>;
    }
}

export default connectAllProps(Calendars);
