import React from "react";
import connectAllProps from "../../../shared/connect";
import fetch from "../../../shared/fetch";
import { ComponentProps } from "../../../shared/ComponentProps";
import { Button, Dropdown, Grid, Menu } from "semantic-ui-react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import dataSourceFieldTypes from "./DataSourceFieldTypes";
import { DataSourceField, formattedField, inferDataSourceFieldType } from "@airjam/types";

interface Props extends ComponentProps {
    onChange: (componentId: string, key: string, mapping: {[id: string]: DataSourceField}, example: any) => void;
    componentId: string;
    sheetId: string;
    sheetKey: string;
    key: string;
    hideExample?: boolean;
    className: string;
    originalFieldMapping: {[id: string]: DataSourceField};
}

interface States {
    rows: any;
    exampleData: any;
}

class GoogleSheetSheetFieldConfiguration extends React.Component<Props, States> {
    private _isMounted: boolean = false;
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    private rawData: any[];
    private fieldMappings: {[id: string]: DataSourceField};
    constructor(props: Props) {
        super(props);
        this.getString = this.props.intl.formatMessage;
        this.rawData = [];
        this.fieldMappings = {};
        this.state = {
            rows: {},
            exampleData: {}
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getSheetData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps: Props) {
        if ((prevProps.sheetId !== this.props.sheetId) || (prevProps.sheetKey !== this.props.sheetKey)) {
            this.setState({
                rows: {},
                exampleData: {}
            });
            this.getSheetData();
        }
    }

    public static getDerivedStateFromProps(nextProps: Readonly<Props>, prevState: States): Partial<States> | null {
        return {};
    }

    render(): React.ReactElement<any> {
        return this.props.hideExample ? this.renderWithoutExample() : this.renderWithExample();
    }

        private renderWithoutExample(): React.ReactElement<any> {
        return <Grid columns={5} verticalAlign="middle" key={this.props.key} className={this.props.className}>
                    <Grid.Row className="dataMappingRow" >
                        <Grid.Column width={1} textAlign="left">
                            <FormattedMessage id="data.source.spreadsheet.configure.column_number" />
                        </Grid.Column>
                        <Grid.Column width={6} textAlign="left">
                            <FormattedMessage id="data.source.spreadsheet.configure.column_name" />
                        </Grid.Column>
                        <Grid.Column width={6} textAlign="left">
                            <FormattedMessage id="data.source.spreadsheet.configure.display_as" />
                        </Grid.Column>
                        <Grid.Column width={2} textAlign="left">
                            <FormattedMessage id="data.source.spreadsheet.configure.visible" />
                        </Grid.Column>
                    </Grid.Row>
                    {
                        Object.keys(this.state.rows).map((key, index) => {
                            if (!this.fieldMappings[key]) return <i key={index.toString()}></i>;
                            return <Grid.Row key={index.toString()} className={(this.fieldMappings[key] && this.fieldMappings[key].show) ? "dataMappingRow" : "dataMappingRow disabled"}>
                                <Grid.Column width={1} textAlign="left">
                                    {key}
                                </Grid.Column>
                                <Grid.Column width={6} textAlign="left" className="scrolling content">
                                    {this.state.rows[key] ? this.state.rows[key].toString().substring(0, 10) : "Column " + key}
                                </Grid.Column>
                                <Grid.Column width={6} textAlign="left">
                                    <Menu>
                                        <Dropdown
                                            placeholder="Select field type"
                                            options={dataSourceFieldTypes(this.props)}
                                            defaultValue={this.fieldMappings[key].displayAs.toLowerCase()}
                                            onChange={(e: any, value: any) => {
                                                if (this.fieldMappings[key] && value.value) this.fieldMappings[key].displayAs = value.value;
                                                this.forceUpdate();
                                                this.onChange();
                                            }}
                                            fluid
                                            selection
                                        />
                                    </Menu>
                                </Grid.Column>
                                <Grid.Column width={2} textAlign="left">
                                    <Button icon onClick={() => this.toggleShow(key)} active={this.fieldMappings[key] && !this.fieldMappings[key].show}>
                                        <i className={(this.fieldMappings[key] && this.fieldMappings[key].show) ? "fa-regular fa-eye" : "fa-regular fa-eye-slash" }></i>
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>;
                        })
                    }
                </Grid>;
    }

    private renderWithExample(): React.ReactElement<any> {
        return <Grid columns={5} verticalAlign="middle" key={this.props.key} className={this.props.className}>
                    <Grid.Row className="dataMappingRow" >
                        <Grid.Column width={1} textAlign="left">
                            <FormattedMessage id="data.source.spreadsheet.configure.column_number" />
                        </Grid.Column>
                        <Grid.Column width={4} textAlign="left">
                            <FormattedMessage id="data.source.spreadsheet.configure.column_name" />
                        </Grid.Column>
                        <Grid.Column width={5} textAlign="left">
                            <FormattedMessage id="data.source.spreadsheet.configure.display_as" />
                        </Grid.Column>
                        <Grid.Column width={4} textAlign="left">
                            <FormattedMessage id="data.source.spreadsheet.configure.example" />
                        </Grid.Column>
                        <Grid.Column width={2} textAlign="left">
                            <FormattedMessage id="data.source.spreadsheet.configure.visible" />
                        </Grid.Column>
                    </Grid.Row>
                    {
                        Object.keys(this.state.rows).map((key, index) => {
                            if (!this.fieldMappings[key]) return <i key={index.toString()}></i>;
                            return <Grid.Row key={index.toString()} className={(this.fieldMappings[key] && this.fieldMappings[key].show) ? "dataMappingRow" : "dataMappingRow disabled"}>
                                <Grid.Column width={1} textAlign="left">
                                    {key}
                                </Grid.Column>
                                <Grid.Column width={4} textAlign="left" className="scrolling content">
                                    {this.state.rows[key] ? this.state.rows[key].toString().substring(0, 10) : "Column " + key}
                                </Grid.Column>
                                <Grid.Column width={5} textAlign="left">
                                    <Menu>
                                        <Dropdown
                                            placeholder="Select field type"
                                            options={dataSourceFieldTypes(this.props)}
                                            defaultValue={this.fieldMappings[key].displayAs.toLowerCase()}
                                            onChange={(e: any, value: any) => {
                                                if (this.fieldMappings[key] && value.value) this.fieldMappings[key].displayAs = value.value;
                                                this.forceUpdate();
                                                this.onChange();
                                            }}
                                            fluid
                                            selection
                                        />
                                    </Menu>
                                </Grid.Column>
                                <Grid.Column width={4} textAlign="left">
                                    {this.state.exampleData[index] ? formattedField(this.state.exampleData[index], this.fieldMappings[key].displayAs).substring(0, 10) : "(empty)"}
                                </Grid.Column>
                                <Grid.Column width={2} textAlign="left">
                                    <Button icon onClick={() => this.toggleShow(key)} active={this.fieldMappings[key] && !this.fieldMappings[key].show}>
                                        <i className={(this.fieldMappings[key] && this.fieldMappings[key].show) ? "fa-regular fa-eye" : "fa-regular fa-eye-slash" }></i>
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>;
                        })
                    }
                </Grid>;
    }

    private getSheetData() {
        if (this.props.sheetId && this.props.sheetKey && !Object.keys(this.fieldMappings).length) {
            fetch(`/api/view/table/sheet/${this.props.sheetId}/${this.props.sheetKey}`, undefined, "GET", true)
            .then((rows: any[]) => {
                if (rows && this._isMounted) {
                    this.rawData = rows;
                    this.fieldMappings = {};
                    let exampleData: any[] = [];
                    if (this.rawData.length > 1) {
                        exampleData = this.rawData[1];
                        // For every rows that are empty in example data, search rows below
                        Array.from(this.rawData[0]).forEach((col: any, index: any) => {
                            if (!exampleData[index]) {
                                for (let i = 0; i < this.rawData.length; i++) {
                                    if (this.rawData[index][i]) {
                                        exampleData[index] = this.rawData[index][i];
                                        break;
                                    }
                                }
                            }
                        });
                    }
                    this.setState({
                        rows: this.rawData[0],
                        exampleData: this.rawData[1]
                    });
                    Object.keys(this.rawData[0]).forEach((key: string, index: number) => {
                        if (this.props.originalFieldMapping && this.props.originalFieldMapping[key]) {
                            this.fieldMappings[key] = this.props.originalFieldMapping[key];
                        } else {
                            this.fieldMappings[key] = {
                                displayAs: inferDataSourceFieldType(exampleData[index]),
                                show: true,
                                fieldName: this.rawData[0][key]
                            } as DataSourceField;
                        }
                    });
                    this.onChange();
                    this.forceUpdate();
                }
            }).catch((error: Error) => {
                const msg = error.message as any;
                if (msg && msg.name && msg.name === "oauth") {
                    alert("Your authentication with Google is expired, and you are now being logged out. Please log in again to re-authenticate with Google");
                    this.props.actions.logout();
                }
            });
        }
    }

    private onChange() {
        this.props.onChange(this.props.componentId, this.props.key, this.fieldMappings, this.state.exampleData);
    }

    private toggleShow(key: string) {
        if (this.fieldMappings[key]) {
            this.fieldMappings[key].show = !this.fieldMappings[key].show;
            this.onChange();
        }
        this.forceUpdate();
    }
}

export default connectAllProps(GoogleSheetSheetFieldConfiguration);