import { AnyAction as Action } from "redux";
import BlockViewState from "../models/client/BlockViewState";
import { GET_BLOCK_VIEW_BEGIN, SAVE_BLOCK_VIEW_BEGIN, REMOVE_BLOCK_VIEW_BEGIN, GET_BLOCK_VIEW_SUCCESS, GET_MORE_BLOCK_VIEW_BEGIN, GET_MORE_BLOCK_VIEW_SUCCESS, SAVE_BLOCK_VIEW_SUCCESS, REMOVE_BLOCK_VIEW_SUCCESS, GET_BLOCK_VIEW_FAILED, REMOVE_BLOCK_VIEW_FAILED, SAVE_BLOCK_VIEW_FAILED, GET_MORE_BLOCK_VIEW_FAILED } from "../actions/block";
import { UPDATE_PROFILE_SUCCESS } from "../actions/user";

const initialState: BlockViewState = {
    loading: false,
    loadingMore: false,
    hasMore: false,
    valid: false,
    data: []
};

const blockView = (state: BlockViewState = initialState, action: Action): BlockViewState => {
    switch (action.type) {
        case GET_BLOCK_VIEW_BEGIN:
        case SAVE_BLOCK_VIEW_BEGIN:
        case REMOVE_BLOCK_VIEW_BEGIN:
            return { ...state, loading: true };
        case GET_BLOCK_VIEW_SUCCESS:
            return {
                ...state,
                data: action.blockViews,
                valid: true,
                loading: false,
                hasMore: action.hasMore
            };
        case GET_MORE_BLOCK_VIEW_BEGIN:
            return { ...state, loadingMore: true };
        case GET_MORE_BLOCK_VIEW_SUCCESS:
            return {
                ...state,
                data: [...state.data, ...action.blockViews],
                loadingMore: false,
                hasMore: action.hasMore
            };
        case SAVE_BLOCK_VIEW_SUCCESS:
            // merge the added/updated block view instantly, without waiting for the block list fetching
            return { ...state, valid: false, loading: false, data: [...state.data, action.blockView] };
        case REMOVE_BLOCK_VIEW_SUCCESS:
        case UPDATE_PROFILE_SUCCESS:
            return { ...state, valid: false, loading: false };
        case GET_BLOCK_VIEW_FAILED:
        case REMOVE_BLOCK_VIEW_FAILED:
        case SAVE_BLOCK_VIEW_FAILED:
            return { ...state, loading: false };
        case GET_MORE_BLOCK_VIEW_FAILED:
            return {
                ...state,
                loadingMore: false,
                hasMore: false
            };
        default:
            return state;
    }
};

export default blockView;