import React, { RefObject, createRef } from "react";
import { Route, Switch } from "react-router-dom";
import NavBarLayout from "./components/NavBarLayout";
import Home from "./pages/Home";
import LogIn from "./pages/LogIn";
import SignUp from "./pages/SignUp";
import ErrorPage from "./pages/ErrorPage";
import Consent from "./pages/Consent";
import Profile from "./pages/Profile";
import About from "./pages/About";
import Boards from "./pages/static/Boards";
import TableView from "./pages/static/TableView";
import Footer from "./components/Footer";
import { WRAPPER_VIEW_STYLE } from "../shared/styles";
import Preferences from "./pages/Preferences";
import Articles from "./pages/Articles";
import Projects from "./pages/Projects";
import Blocks from "./pages/Blocks";
import Uploader from "./pages/static/Uploader";
import Uploaders from "./pages/Uploaders";
import Notifications from "./pages/Notifications";
import Security from "./pages/Security";
import ForgetPassword from "./pages/ForgetPassword";
import Threads from "./pages/Threads";
import { ComponentProps as Props } from "../shared/ComponentProps";
import connectAllProps from "../shared/connect";
import { SHOW_UNDER_SCROLL_HEIGHT } from "./components/constants";
import FabAction from "../models/client/FabAction";
import TableViews from "./pages/TableViews";
import Privacy from "./pages/static/Privacy";
import Terms from "./pages/static/Terms";
import ReactGA from "react-ga4";
import StoreLocator from "./pages/static/StoreLocator";
import CreateNewProject from "./pages/static/blogs/CreateNewProject";
import GoogleConsent from "./pages/static/GoogleConsent";
import SelfGuide from "./pages/static/SelfGuide";
import Restaurants from "./pages/static/Restaurants";
import NoCode from "./pages/static/NoCode";
import Workspace from "./pages/Workspace";
import Calendars from "./pages/Calendars";
import Calendar from "./pages/static/Calendar";
import AppointmentReservationCancellation from "./components/appointment/AppointmentReservationCancellation";
import Appointment from "./pages/static/Appointment";
import Appointments from "./pages/Appointments";
import AppointmentBubble from "./pages/static/AppointmentBubble";
import AppointmentHow from "./pages/static/AppointmentHow";

interface States {}

class App extends React.Component<Props, States> {
    private contextRef: RefObject<any>;
    readonly scrollUpAction: FabAction = {
        text: this.props.intl.formatMessage({id: "component.button.scroll_up"}),
        icon: "arrow up",
        onClick: () => { window.scrollTo({
                left: 0,
                top: 0,
                behavior: "smooth"
            });
        },
    };
    constructor(props: Props) {
        super(props);
        this.contextRef = createRef();
    }
    render(): React.ReactElement<any> {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.pathname + window.location.search });
        return (
            <div ref={this.contextRef} style={WRAPPER_VIEW_STYLE}>
                <Switch>
                    <Route exact path="/reservations/cancel/:reservationId/:moderationKey" render={(props) => <AppointmentReservationCancellation {...props} />} />
                    <Route render={ (props) =>
                        <NavBarLayout {...props} containerRef={this.contextRef}>
                            <main style={WRAPPER_VIEW_STYLE} >
                                <Switch>
                                    <Route exact path="/" render={ (props) => <Home {...props} /> } />
                                    <Route path="/login/2" render={ (props) => <LogIn {...props} /> } />
                                    <Route path="/login/r/:redirectPath" render={ (props) => <LogIn {...props} /> } />
                                    <Route path="/login" render={ (props) => <LogIn {...props} /> } />
                                    <Route path="/signup/r/:redirectPath" render={ (props) => <SignUp {...props} /> } />
                                    <Route path="/signup" render={ (props) => <SignUp {...props} /> } />
                                    <Route path="/join/r/:redirectPath" render={ (props) => <SignUp {...props} /> } />
                                    <Route path="/join" render={ (props) => <SignUp {...props} /> } />
                                    <Route path="/consent" render={ (props) => <Consent {...props} /> } />
                                    <Route path="/profile" render={ (props) => <Profile {...props} /> } />
                                    <Route path="/preferences" render={ (props) => <Preferences {...props} /> } />
                                    <Route path="/article" render={(props) => <Articles {...props} />} />
                                    <Route path="/uploader" render={(props) => <Uploader {...props} />} />
                                    <Route path="/uploaders" render={(props) => <Uploaders {...props} />} />
                                    <Route path="/calendars" render={(props) => <Calendars {...props} />} />
                                    <Route path="/calendar" render={(props) => <Calendar {...props} />} />
                                    <Route path="/appointments" render={(props) => <Appointments {...props} />} />
                                    <Route path="/appointment/bubble" render={(props) => <AppointmentBubble {...props} />} />
                                    <Route path="/appointment/how" render={(props) => <AppointmentHow {...props} />} />
                                    <Route path="/appointment" render={(props) => <Appointment {...props} />} />
                                    <Route path="/project" render={(props) => <Projects {...props} />} />
                                    <Route path="/view/table" render={(props) => <TableViews {...props} />} />
                                    <Route path="/block" render={(props) => <Blocks {...props} />} />
                                    <Route path="/thread" render={ (props) => <Threads {...props} /> } />
                                    <Route path="/notifications" render={ (props) => <Notifications {...props} /> } />
                                    <Route path="/security" render={ (props) => <Security {...props} /> } />
                                    <Route path="/forgetpassword" render={ (props) => <ForgetPassword {...props} /> } />
                                    <Route path="/about" component={About} />
                                    <Route path="/privacy" component={Privacy} />
                                    <Route path="/google_api_disclosure" component={GoogleConsent} />
                                    <Route path="/terms" component={Terms} />
                                    <Route path="/board" component={Boards} />
                                    <Route path="/tableview" render={(props) => <TableView {...props} />} />
                                    <Route path="/nocode" component={NoCode} />
                                    <Route path="/console" component={Workspace} />
                                    <Route path="/view/storelocator" component={StoreLocator} />
                                    <Route path="/view/self_guided_tour" component={SelfGuide} />
                                    <Route path="/view/restaurant" component={Restaurants} />
                                    <Route path="/blog/create_new_project" component={CreateNewProject} />
                                    {/* add more routes here, the path should keep the same as PostType if necessary */}
                                    <Route render={ (props) => <ErrorPage {...props} error={{
                                                name: "404 Not Found",
                                                message: `not found for ${window.location.href} `
                                            }}
                                        />}
                                    />
                                </Switch>
                            </main>
                            <Footer />
                        </NavBarLayout>
                    } />
                </Switch>
            </div>
        );
    }
    setGA = () => {
        ReactGA.initialize("G-KX4MXR1M0M");
    };

    componentDidMount() {
        this.setGA();
        this.handleScroll();
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }
    handleScroll = () => {
        if (window.pageYOffset > SHOW_UNDER_SCROLL_HEIGHT) {
            if (this.props.state.fabActions.findIndex((action: FabAction) => action.icon === "arrow up") < 0) {
                this.props.actions.addFabAction(this.scrollUpAction);
            }
        } else {
            if (this.props.state.fabActions.findIndex((action: FabAction) => action.icon === "arrow up") >= 0) {
                this.props.actions.removeFabAction(this.scrollUpAction.icon);
            }
        }
    }
}

export default connectAllProps(App);