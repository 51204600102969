import React from "react";
import { Label, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "semantic-ui-react";
import { Appointment } from "../../../../models/Appointment";

interface Props {
    appointment?: Appointment;
    idName?: string;
}
interface States {}
export default class AppointmentComponentAttributes extends React.Component<Props, States> {
    render(): React.ReactElement<any> {
        return <Table color={"blue"}>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell>Attribute name</TableHeaderCell>
                    <TableHeaderCell>Description</TableHeaderCell>
                    <TableHeaderCell>Default value</TableHeaderCell>
                    <TableHeaderCell>Example values</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                <TableRow>
                    <TableCell><Label ribbon>Required</Label>{this.props.idName ? this.props.idName : "id"}</TableCell>
                    <TableCell><strong>String</strong>: Component ID of the appointment component you wish to render. You can find the IDs from the component's admin console</TableCell>
                    <TableCell></TableCell>
                    <TableCell>{this.props.appointment ? this.props.appointment.calendarComponentId : ""}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>viewAs</TableCell>
                    <TableCell><strong>String</strong>: Type of appointment interface to display</TableCell>
                    <TableCell><code>CALENDAR_BOOK</code></TableCell>
                    <TableCell><code>"CALENDAR_BOOK" or "DAY_BOOK" or "LIST_BOOK"</code></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>showDate</TableCell>
                    <TableCell><strong>Date</strong>: Start timestamp, represented in UTC, to display available booking times. If provided, the component will only show bookable slots that are equal to or later than the specified timestamp</TableCell>
                    <TableCell><code>Date.Now()</code></TableCell>
                    <TableCell><code>new Date(2024,1,1)</code></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>showEndDate</TableCell>
                    <TableCell><strong>Date</strong>: End timestamp, represented in UTC, to display available booking times. If specified, the component will only show bookable slots that are equal to or earlier than the specified timestamp</TableCell>
                    <TableCell><code>Date.Now().addDays(1)</code></TableCell>
                    <TableCell><code>new Date(2024,1,2)</code></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>locale</TableCell>
                    <TableCell><strong>String</strong>: Manually specify the locale for displaying messages. If translations for the specified locale are not found in the admin console for the corresponding component, messages will default to the messages for the default locale</TableCell>
                    <TableCell>en-US</TableCell>
                    <TableCell>fr-FR</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>paymentProcessorPublicKey</TableCell>
                    <TableCell><strong>String</strong>: Public key provided by the selected payment processor. For Stripe, this refers to the publishable key.</TableCell>
                    <TableCell>none</TableCell>
                    <TableCell><code>pk_#######</code></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>bookingDefaultName</TableCell>
                    <TableCell><strong>String</strong>: Default name to populate in the Name field on the booking dialog</TableCell>
                    <TableCell>none</TableCell>
                    <TableCell><code>John Doe</code></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>bookingDefaultEmail</TableCell>
                    <TableCell><strong>String</strong>: Default email address to populate in the Email field on the booking dialog</TableCell>
                    <TableCell>none</TableCell>
                    <TableCell><code>john.doe@emailclient.com</code></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>authToken</TableCell>
                    <TableCell><strong>String</strong>: For components that require authentication to view and/or to book, the <code>authToken</code> is the client's authentication token provided by the authentication provider. For Clerk, this is the <code>jwtKey</code>, and for Firebase, it is the <code>idToken</code>. This field is ignored if the corresponding component does not require authentication to view and/or to book</TableCell>
                    <TableCell>none</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableBody>
        </Table>;
    }
}