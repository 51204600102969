import React, { Fragment } from "react";
// eslint-disable-next-line
import { Route, Switch, match } from "react-router";
import UploaderDetail from "../components/uploader/UploaderDetail";
import UploaderList from "../components/uploader/UploaderList";
import CreateUploader from "../components/uploader/CreateUploader";
import EditUploader from "../components/uploader/EditUploader";
import connectAllProps from "../../shared/connect";
import { ComponentProps as Props } from "../../shared/ComponentProps";

interface States {}

class Uploaders extends React.Component<Props, States> {
    componentDidMount() {
        if (!this.props.state.uploaderState.valid) {
            this.props.actions.getUploaders();
        }
    }
    componentDidUpdate(prevProps: Props) {
        if (prevProps.state.uploaderState.valid && !this.props.state.uploaderState.valid) {
            this.props.actions.getUploaders();
        }
    }
    render(): any {
        const match: match<any> = this.props.match;
        return <Fragment>
            <Switch>
                <Route exact path={match.url} render={(props) => <UploaderList {...props} />} />
                <Route path={`${match.url}/create`} render={(props) => <CreateUploader {...props} />} />
                <Route path={`${match.url}/edit/:uploaderId`} render={(props) => <EditUploader {...props} />} />
                <Route path={`${match.url}/:uploaderId`} render={(props) => <UploaderDetail {...props} />} />
            </Switch>
        </Fragment>;
    }
}

export default connectAllProps(Uploaders);