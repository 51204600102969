import React from "react";
import connectAllProps from "../../../shared/connect";
import { Redirect } from "react-router-dom";
import { Container, Header } from "semantic-ui-react";
import ProjectEditor from "./ProjectEditor";
import { CONTAINER_STYLE } from "../../../shared/styles";
import { FormattedMessage } from "react-intl";
import { isMobile } from "../dimension";
import { pendingRedirect } from "../../../shared/redirect";
import { ComponentProps as Props } from "../../../shared/ComponentProps";
import Project from "../../../models/Project";

interface States {}
class CreateProject extends React.Component<Props, States> {
    render(): React.ReactElement<any> {
        if (pendingRedirect(this.props)) {
            return <Redirect to={this.props.state.redirectTask.to} />;
        } else if (this.props.state.userState.currentUser) {
            const loading: boolean | undefined = this.props.state.projectState.loading;
            const containerStyle: any = isMobile() ? CONTAINER_STYLE :
                {...CONTAINER_STYLE, paddingLeft: 20, paddingRight: 20};
            return (
                <Container style={containerStyle}>
                    <Header size={"medium"}>
                        <FormattedMessage id="page.project.add" />
                    </Header>
                    <ProjectEditor
                        onSubmit={this.createProject}
                        submitTextId="component.button.submit"
                        loading={loading}/>
                </Container>
            );
        } else {
            return <Redirect to="/project" />;
        }
    }

    private createProject = (title: string, description: string): void => {
        if (this.props.state.userState.currentUser) {
            this.props.actions.addProject({
                title: title,
                description: description
            } as Project);
        }
    }
}

export default connectAllProps(CreateProject);