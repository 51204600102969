import React from "react";
import fetch from "../../../shared/fetch";
import moment from "moment";
import connectAllProps from "../../../shared/connect";
import { ComponentProps } from "../../../shared/ComponentProps";
import { MessageDescriptor } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import { CallPage, CallWindow, populateTsSliceDataDates, ProjectTsSliceData } from "../../../models/CallType";
import { Line } from "react-chartjs-2";

interface States {
    chartData: any;
}

interface Props extends ComponentProps {
    key: string;
    showReload?: boolean;
    id: string;
    dataLabel: string;
    actionType: string;
    window: CallWindow;
    pageType: CallPage;
}

interface ProjectCallResponse {
    data: ProjectTsSliceData[];
}


class ViewCountView extends React.Component<Props, States> {
    private _isMounted: boolean = false;
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;

    constructor(props: Props) {
        super(props);
        this.getString = this.props.intl.formatMessage;
        const initialDates = this.getDayArrayByHour();
        this.state = {
            chartData:  {
                "labels": initialDates,
                "datasets": [{
                    "label": this.props.dataLabel,
                    "data": this.zeroArraySameSize(initialDates),
                    "fill": false,
                    "borderColor": "rgba(0, 0, 0, 0.99)",
                    "lineTension": 0
                }]
            }
        };
    }

    private getDayArrayByHour(): string[] {
        const dayArray: string[] = [];
        let dateObj = moment(new Date()).startOf("hour").subtract(1, "day");
        dayArray.push(dateObj.format("LT"));
        for (let i = 0; i < 24; i++) {
            dateObj = dateObj.add(1, "hour");
            dayArray.push(dateObj.format("LT"));
        }
        return dayArray;
    }

    private zeroArraySameSize(arr: any[]): number[] {
        return arr.map(() => 0);
    }
    componentDidMount() {
        this._isMounted = true;
        this.loadCalls();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps: Props) {
        if (prevProps.id !== this.props.id ||
            prevProps.window !== this.props.window)
        this.loadCalls();
    }

    render(): React.ReactElement<any> {
        return <div key={this.props.key}>
            <Line data={this.state.chartData} height={100} options={{
                maintainAspectRatio: true,
                responsive: true,
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [{
                        display: false
                    }]
                }
            }} />
        </div>;
    }

    private formatDateByCallWindow(date: Date, window: CallWindow): string {
        switch (window) {
            case CallWindow.MONTH:
                return moment(date).format("MM/DD");
            case CallWindow.WEEK:
                return moment(date).format("MM/DD hA");
            case CallWindow.TODAY:
            default:
                return moment(date).format("LT");
        }
    }

    private populateProjectCallResponseDates(response: ProjectCallResponse) {
        response.data = response.data.map((slice: ProjectTsSliceData) => {
            return populateTsSliceDataDates(slice) as ProjectTsSliceData;
        });
        return response;
    }

    private loadCalls() {
        fetch(`/api/uploader/calls`, {
            "id": this.props.id,
            "page": this.props.pageType,
            "actionType": this.props.actionType,
            "window": this.props.window
        }, "POST", true).then((result: ProjectCallResponse) => {
            if (this._isMounted && this.state.chartData && this.state.chartData.datasets && this.state.chartData.datasets.length > 0) {
                const response = this.populateProjectCallResponseDates(result);
                const labels = response.data.map((slice: ProjectTsSliceData) => {
                    return this.formatDateByCallWindow(slice.time, this.props.window);
                });
                const data = response.data.map((slice: ProjectTsSliceData) => {
                    return slice._value ? parseInt(slice._value) : 0;
                });
                if (labels.length > 0 && data.length > 0) {
                    const newChartData = this.state.chartData;
                    newChartData.labels = labels;
                    newChartData.datasets[0].data = data;
                    this.setState({ chartData: { ...newChartData } });
                    this.forceUpdate();
                }
            }
        }).catch((error: Error) => {
            console.log("unable to load call data. show some message");
            console.log(error);
        });
    }
}

export default connectAllProps(ViewCountView);