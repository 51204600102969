import { AnyAction as Action } from "redux";
import ProjectState from "../models/client/ProjectState";
import { GET_PROJECT_SUCCESS, SAVE_PROJECT_SUCCESS, GET_PROJECT_BEGIN, SAVE_PROJECT_BEGIN, SAVE_PROJECT_FAILED, GET_PROJECT_FAILED, GET_MORE_PROJECT_SUCCESS, GET_MORE_PROJECT_BEGIN, GET_MORE_PROJECT_FAILED, REMOVE_PROJECT_BEGIN, REMOVE_PROJECT_SUCCESS, REMOVE_PROJECT_FAILED } from "../actions/project";
import { UPDATE_PROFILE_SUCCESS } from "../actions/user";
import { REMOVE_TABLE_VIEW_SUCCESS, SAVE_TABLE_VIEW_SUCCESS } from "../actions/table_view";
import { TableView } from "../models/TableView";

const initialState: ProjectState = {
    loading: false,
    valid: false,
    data: [],
    detailedProjects: {},
    loadingMore: false,
    hasMore: false
};

const project = (state: ProjectState = initialState, action: Action): ProjectState => {
    switch (action.type) {
        case GET_PROJECT_BEGIN:
        case SAVE_PROJECT_BEGIN:
        case REMOVE_PROJECT_BEGIN:
            return {...state, loading: true};
        case GET_PROJECT_SUCCESS:
            const projectDetails = state.detailedProjects;
            let projects = state.data;
            if (action.project) {
                projectDetails[action.project._id] = action.project;
            }
            if (action.projects) {
                projects = action.projects;
            }
            return {
                ...state,
                data: projects,
                detailedProjects: projectDetails,
                valid: true,
                loading: false,
                hasMore: action.hasMore
            };
        case GET_MORE_PROJECT_BEGIN:
            return {...state, loadingMore: true};
        case GET_MORE_PROJECT_SUCCESS:
            return {
                ...state,
                data: [...state.data, ...action.projects],
                loadingMore: false,
                hasMore: action.hasMore
            };
        case SAVE_PROJECT_SUCCESS:
            // merge the added/updated project instantly, without waiting for the project list fetching
            return {...state, valid: false, loading: false, data: [...state.data, action.project]};
        case REMOVE_PROJECT_SUCCESS:
            const detailedProjects = state.detailedProjects;
            if (action.projectId) {
                delete detailedProjects[action.projectId];
            }
            // remove from detailed project
            return {...state, valid: false, loading: false, detailedProjects: detailedProjects};
        case UPDATE_PROFILE_SUCCESS:
            return {...state, valid: false, loading: false};
        case SAVE_TABLE_VIEW_SUCCESS:
            if (action.projectId) {
                const project = state.detailedProjects[action.projectId];
                if (project) {
                    const projectDetails = state.detailedProjects;
                    const components: TableView[] = [];
                    let componentFound: boolean = false;
                    project.tableViewComponents.forEach((component) => {
                        if (component._id === action.tableView._id) {
                            componentFound = true;
                            components.push(action.tableView);
                        } else {
                            components.push(component);
                        }
                    });
                    if (!componentFound) {
                        components.push(action.tableView);
                        project.componentCount = components.length;
                    }
                    project.tableViewComponents = components;
                    projectDetails[action.projectId] = project;
                    return {...state, detailedProjects: projectDetails};
                }
            }
            return {...state};
        case REMOVE_TABLE_VIEW_SUCCESS:
            const updatedProjectDetails = state.detailedProjects;
            const project = state.detailedProjects[action.projectId];
            if (project) {
                const newComponents = project.tableViewComponents.filter((view: TableView) => view._id !== action.id );
                project.tableViewComponents = newComponents;
                project.componentCount = newComponents.length;
                updatedProjectDetails[action.projectId] = project;
            }
            return {...state, detailedProjects: updatedProjectDetails};
        case GET_PROJECT_FAILED:
        case REMOVE_PROJECT_FAILED:
        case SAVE_PROJECT_FAILED:
            return {...state, loading: false};
        case GET_MORE_PROJECT_FAILED:
            return {
                ...state,
                loadingMore: false,
                hasMore: false
            };
        default:
            return state;
    }
};

export default project;