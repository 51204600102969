import React, { Fragment, useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { ComponentProps } from "../../../shared/ComponentProps";
import { Button } from "semantic-ui-react";

interface Props extends ComponentProps {
    onClose: () => void;
}

const StripePaymentElement = (props: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(undefined);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const {error} = await stripe.confirmSetup({
      elements: elements as any,
      confirmParams: {
        return_url: "https://airjam.co/preferences",
      },
      redirect: "if_required"
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      console.log(error);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      props.actions.loadStripeIntent(); // reset stripe intent now that previous intent is used up
      props.actions.getPaymentMethods(); // refresh payment methods.
      if (props.onClose) props.onClose();
    }
  };

    return (
        <Fragment>
            <form onSubmit={handleSubmit}>
                <PaymentElement />
                <p></p>
                <Button disabled={!stripe} color="orange">Add a card</Button>
                {/* Show error message to your customers */}
                {errorMessage && <div>{errorMessage}</div>}
            </form>
        </Fragment>
    );
};

export default StripePaymentElement;
