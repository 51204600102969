import { DropdownItemProps } from "semantic-ui-react";
import SharingType from "../../../models/SharingType";
import { ComponentProps as Props } from "../../../shared/ComponentProps";

export default function sharingTypes(props: Props): DropdownItemProps[] {
    return Array.from(Object.keys(SharingType) as Array<keyof typeof SharingType>).map((key) => {
        return {
            key: key.toString(),
            text: props.intl.formatMessage({ id: "component.dropdown.sharing_type." + SharingType[key].toString() }),
            value: SharingType[key].toString()
        } as DropdownItemProps;
    });
}
