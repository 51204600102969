import React, { Fragment, RefObject } from "react";
import connectAllProps from "../../../shared/connect";
import { ComponentProps } from "../../../shared/ComponentProps";
import { MessageDescriptor } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import { Dropdown } from "semantic-ui-react";
import { ComponentTemplate, DataSourceField, TemplateField, template_cache } from "@airjam/types";

interface States {
    templateFieldMapping: {[id: string]: string};
}

interface Props extends ComponentProps {
    componentId: string;
    initialTemplateFieldMapping: {[id: string]: string};
    key: string;
    fieldMapping: {[id: string]: DataSourceField};
    selectedTemplateId: string;
    onChange: (componentId: string, fieldMapping: {[id: string]: string}) => void;
}

class TemplateFieldConfiguration extends React.Component<Props, States> {
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    private fieldRefs: {[id: string]: RefObject<HTMLInputElement>};
    private mappingKey: {[id: string]: string} = {};
    private template: ComponentTemplate | undefined;

    constructor(props: Props) {
        super(props);
        this.getString = this.props.intl.formatMessage;
        this.fieldRefs = {};
        this.state = {
            templateFieldMapping: props.initialTemplateFieldMapping
        };
        this.loadTemplate();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.selectedTemplateId !== this.props.selectedTemplateId) {
            this.loadTemplate();
            this.forceUpdate();
        }
    }

    render(): React.ReactElement<any> {
        if (!this.template) return <span key={this.props.key}></span>;
        return <div key={this.props.key}>
            {
                Object.entries(this.template.templateFields).map((entry: [string, TemplateField]) => {
                    return this.renderField(entry[0], entry[1]);
                })
            }
        </div>;
    }

    private getTextForOptionKey = (options: any[], key: string): string => {
        let text: string = "";
        options.forEach((option: any) => { if (option.key === key) text = option.text; });
        return text;
    }

    private renderField = (fieldId: string, field: TemplateField): React.ReactElement<any> => {
        if (!fieldId || !field) return <Fragment></Fragment>;
        const options = this.fieldOptions(fieldId);
        let initialMappingKey = "";
        if (this.props.initialTemplateFieldMapping[fieldId]) initialMappingKey = this.props.initialTemplateFieldMapping[fieldId];
        const initialMappingText = this.getTextForOptionKey(options, initialMappingKey);
        return <span className={"templateFieldConfiguration"} key={this.props.key + fieldId}>
            {field.name}<br />
            <Dropdown
                key={this.props.key + fieldId + "_options"}
                fluid
                selection
                text={this.mappingKey[fieldId] ? this.getTextForOptionKey(options, this.mappingKey[fieldId]) : initialMappingText}
                value={this.mappingKey[fieldId] ? this.mappingKey[fieldId] : initialMappingKey}
                onChange={(e: any, value: any) => {
                    if (this.fieldRefs[fieldId] && this.fieldRefs[fieldId].current) this.fieldRefs[fieldId].current!.value = value.value;
                    this.mappingKey[fieldId] = value.value;
                    this.fieldChanged(fieldId, value.value);
                    this.forceUpdate();
                }}
                options={options}
            />
            <input type="hidden" ref={this.fieldRefs[fieldId]} />
            </span>;
    }

    private fieldChanged = (fieldName: string, newMapping: string) => {
        const updatedMapping = this.state.templateFieldMapping;
        updatedMapping[fieldName] = newMapping;
        this.setState({
            templateFieldMapping: updatedMapping
        });
        if (this.props.onChange) this.props.onChange(this.props.componentId, this.state.templateFieldMapping);
        this.forceUpdate();
    }

    private loadTemplate() {
        // todo(minjae) also get from the server
        const components = Object.entries(template_cache).filter((value: [string, ComponentTemplate]) => {
            return (value[0] === this.props.selectedTemplateId);
        }).map((value: [string, ComponentTemplate]) => value[1]);
        if (components && components.length > 0) this.template = components[0];
        if (this.template) {
            Object.keys(this.template.templateFields).forEach((fieldName: string) => {
                if (!this.fieldRefs[fieldName]) this.fieldRefs[fieldName] = React.createRef();
            });
        }
    }

    private fieldOptions = (fieldId: string): any[] => {
        if (!this.template || !this.template.templateFields[fieldId]) {
            return [];
        }
        const field = this.template.templateFields[fieldId];
        const options: any[] = [];
        Object.entries(this.props.fieldMapping).forEach((value: [string, DataSourceField]) => {
            if (value && value[1]) {
                if ((field.compatibleTypes.length > 1)) {
                    let matchFound: boolean = false;
                    for (let i = 0; i < field.compatibleTypes.length; i++) {
                        if (field.compatibleTypes[i].toLowerCase() === value[1].displayAs.toLowerCase()) {
                            matchFound = true;
                        }
                    }
                    if (!matchFound) return;
                }
                options.push({
                    key: value[0],
                    text: value[1].fieldName,
                    value: value[0],
                });
            }
        });
        return options;
    }
}

export default connectAllProps(TemplateFieldConfiguration);