import { AnyAction as Action } from "redux";
import BlockState from "../models/client/BlockState";
import { GET_BLOCK_BEGIN, SAVE_BLOCK_BEGIN, REMOVE_BLOCK_BEGIN, GET_BLOCK_SUCCESS, GET_MORE_BLOCK_BEGIN, GET_MORE_BLOCK_SUCCESS, SAVE_BLOCK_SUCCESS, REMOVE_BLOCK_SUCCESS, GET_BLOCK_FAILED, REMOVE_BLOCK_FAILED, SAVE_BLOCK_FAILED, GET_MORE_BLOCK_FAILED } from "../actions/block";
import { UPDATE_PROFILE_SUCCESS } from "../actions/user";

const initialState: BlockState = {
    loading: false,
    loadingMore: false,
    hasMore: false,
    valid: false,
    data: []
};

const block = (state: BlockState = initialState, action: Action): BlockState => {
    switch (action.type) {
        case GET_BLOCK_BEGIN:
        case SAVE_BLOCK_BEGIN:
        case REMOVE_BLOCK_BEGIN:
            return { ...state, loading: true };
        case GET_BLOCK_SUCCESS:
            return {
                ...state,
                data: action.blocks,
                valid: true,
                loading: false,
                hasMore: action.hasMore
            };
        case GET_MORE_BLOCK_BEGIN:
            return { ...state, loadingMore: true };
        case GET_MORE_BLOCK_SUCCESS:
            return {
                ...state,
                data: [...state.data, ...action.blocks],
                loadingMore: false,
                hasMore: action.hasMore
            };
        case SAVE_BLOCK_SUCCESS:
            // merge the added/updated block instantly, without waiting for the block list fetching
            return { ...state, valid: false, loading: false, data: [...state.data, action.block] };
        case REMOVE_BLOCK_SUCCESS:
        case UPDATE_PROFILE_SUCCESS:
            return { ...state, valid: false, loading: false };
        case GET_BLOCK_FAILED:
        case REMOVE_BLOCK_FAILED:
        case SAVE_BLOCK_FAILED:
            return { ...state, loading: false };
        case GET_MORE_BLOCK_FAILED:
            return {
                ...state,
                loadingMore: false,
                hasMore: false
            };
        default:
            return state;
    }
};

export default block;