import React, { Fragment } from "react";
// eslint-disable-next-line
import { Route, Switch, match } from "react-router";
import connectAllProps from "../../shared/connect";
import BlockList from "../components/block/BlockList";
import CreateBlock from "../components/block/CreateBlock";
import { ComponentProps as Props } from "../../shared/ComponentProps";
import EditBlock from "../components/block/EditBlock";
import BlockDetail from "../components/block/BlockDetail";

interface States { }

class Blocks extends React.Component<Props, States> {
    componentDidMount() {
        this.props.actions.getBlocks();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.state.blockState.valid && !this.props.state.blockState.valid) {
            this.props.actions.getBlocks();
        }
    }

    render(): any {
        const match: match<any> = this.props.match;
        return <Fragment>
            <Switch>
                <Route exact path={match.url} render={(props) => <BlockList {...props} />} />
                <Route path={`${match.url}/create`} render={(props) => <CreateBlock {...props} />} />
                <Route path={`${match.url}/edit/:blockId`} render={(props) => <EditBlock {...props} />} />
                <Route path={`${match.url}/:blockId`} render={(props) => <BlockDetail {...props} />} />
            </Switch>
        </Fragment>;
    }
}

export default connectAllProps(Blocks);
