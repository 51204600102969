import { Calendar } from "../../../models/Calendar";
import React from "react";
import { Card } from "semantic-ui-react";
import connectAllProps from "../../../shared/connect";
import { MessageDescriptor } from "react-intl";
import { ComponentProps } from "../../../shared/ComponentProps";
import moment from "moment";
import { Viewer } from "@toast-ui/react-editor";
import { PrimitiveType } from "intl-messageformat";

interface Props extends ComponentProps {
    calendar: Calendar;
}

interface States {}

class CalendarItem extends React.Component<Props, States> {
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.getString = this.props.intl.formatMessage;
    }
    render(): React.ReactElement<any> {
        const { calendar } = this.props;
        const createDate: Date = calendar.createdAt ? new Date(calendar.createdAt) : new Date(0);
        return <Card key={createDate.getMilliseconds()} className="hollowSegment" onClick={() => this.props.history.push(`/calendars/${calendar._id}`)}>
                    <Card.Content textAlign="center" className="extraTopBottomPadding">
                        <Card.Header as="small">{calendar.name}</Card.Header>
                    <Viewer style={{ height: 3 }} initialValue={"Created " + moment(createDate).fromNow()} />
                    </Card.Content>
                    <Card.Content extra textAlign="center">
                        { this.getString({ id: "component.button.open" }) }
                    </Card.Content>
                </Card>;
    }
}

export default connectAllProps(CalendarItem);