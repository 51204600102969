import React from "react";
import connectAllProps from "../../../shared/connect";
import { ComponentProps } from "../../../shared/ComponentProps";
import { MessageDescriptor } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import { Container, Header } from "semantic-ui-react";
import { style_cache, TemplateStyle } from "@airjam/types";
import PreviewColors from "./PreviewColors";

interface States {
    selectedStyle?: string;
}

interface Props extends ComponentProps {
    componentId: string;
    initialStyle?: string;
    key: string;
    selectedTemplateId: string;
    onChange: (componentId: string, newStyleId: string, styleVersion: number) => void;
}

class StyleSelect extends React.Component<Props, States> {
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;

    constructor(props: Props) {
        super(props);
        this.getString = this.props.intl.formatMessage;
        this.state = {
            selectedStyle: props.initialStyle
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.selectedTemplateId !== this.props.selectedTemplateId) {
            this.forceUpdate();
        }
    }

    render(): React.ReactElement<any> {
        const styles: TemplateStyle[] = this.getEligibleStyles();
        return <div key={this.props.key}>
        {
            styles.map((style: TemplateStyle) => this.renderStyle(style))
        }
        </div>;
    }

    private renderStyle = (style: TemplateStyle): React.ReactElement<any> => {
        return <Container className={"previewColorsContainer" + this.classNameIfSelected(style.shortId)} onClick={() => this.styleSelected(style.shortId, style.version)} key={this.props.key + style.name}>
                <PreviewColors colors={style.colorTheme}></PreviewColors>
                <Header size="tiny">{style.name}</Header>
            </Container>;
    }

    private classNameIfSelected = (styleId: string): string => {
        if (this.state.selectedStyle && (this.state.selectedStyle!.toLowerCase() === styleId.toLowerCase())) {
            return " selected";
        }
        return "";
    }

    private styleSelected = (newStyleId: string, newStyleVersion: number) => {
        this.setState({
            selectedStyle: newStyleId.toLowerCase()
        });
        if (this.props.onChange) this.props.onChange(this.props.componentId, newStyleId, newStyleVersion);
        this.forceUpdate();
    }

    private getEligibleStyles(): TemplateStyle[] {
        // todo(minjae) also get from the server
        const compatibleStyles: TemplateStyle[] = Object.entries(style_cache).filter((value: [string, TemplateStyle]) => {
            for (let i = 0; i < value[1].compatibleWith.length; i++) {
                if (value[1].compatibleWith[i].toLowerCase() === this.props.selectedTemplateId) {
                    return true;
                }
            }
            return false;
        }).map((value: [string, TemplateStyle]) => value[1]);
        return compatibleStyles;
    }

}

export default connectAllProps(StyleSelect);