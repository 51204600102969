import React, { RefObject } from "react";
import connectAllProps from "../../shared/connect";
import { Redirect } from "react-router";
import { Container, Form, Button, Icon, Image, Placeholder, Header } from "semantic-ui-react";
import { CONTAINER_STYLE, AVATAR_PREFERABLE_SIZE } from "../../shared/styles";
import ResponsiveFormField from "../components/shared/ResponsiveFormField";
import User from "../../models/User";
import AvatarCropDialog from "../components/user/AvatarCropDialog";
import FileSelectButton from "../components/shared/FileSelectButton";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import { ComponentProps as Props } from "../../shared/ComponentProps";
import LinkGoogleAccount from "../components/auth/LinkGoogleAccount";

interface States {
    selectedAvatar: string;
    cropAvatarDialogOpen: boolean;
    textEditing: boolean;
}

class Profile extends React.Component<Props, States> {
    message: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    nameRef: RefObject<HTMLInputElement>;
    constructor(props: Props) {
        super(props);
        this.message = this.props.intl.formatMessage;
        this.nameRef = React.createRef();
        this.state = {
            selectedAvatar: "",
            cropAvatarDialogOpen: false,
            textEditing: false
        };
    }

    componentDidMount() {
        this.props.actions.resetAvatar();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.state.userState.loading && !this.props.state.userState.loading) {
            this.setState({
                textEditing: false
            });
        }
    }

    render(): React.ReactElement<any> {
        if (this.props.state.userState.currentUser) {
            const user: User = this.props.state.userState.currentUser;
            const loading: boolean = this.props.state.userState.loading;
            const displayedAvatarUrl: string = this.props.state.userState.uploadedAvatarUrl
                ? this.props.state.userState.uploadedAvatarUrl
                : user.avatarUrl
                ? user.avatarUrl
                : "/images/avatar.png";
            const avatarStyle: any = {height: AVATAR_PREFERABLE_SIZE, width: AVATAR_PREFERABLE_SIZE};
            return (<Container text style={CONTAINER_STYLE}>
                <Header size={"medium"}>
                    <FormattedMessage id="page.me.profile"/>
                </Header>
                <Form>
                    <ResponsiveFormField>
                        {
                            this.props.state.userState.uploadingAvatar ?
                                <Placeholder style={avatarStyle}>
                                    <Placeholder.Image/>
                                </Placeholder>
                            :
                                <Image style={avatarStyle}
                                    rounded
                                    bordered
                                    src={displayedAvatarUrl}
                                    alt="Avatar"/>
                        }
                    </ResponsiveFormField>
                    <Form.Group inline>
                        <label>
                            <FormattedMessage id="user.photo"/>
                        </label>
                        <FileSelectButton onChange={this.onAvatarSelected}/>
                        <AvatarCropDialog
                            open={this.state.cropAvatarDialogOpen}
                            avatarSource={this.state.selectedAvatar as string}
                            onCancel={this.closeAvatarCropDialog}
                            onConfirm={this.updateAvatarUrl} />
                    </Form.Group>
                    <ResponsiveFormField>
                        <label>
                            <FormattedMessage id="user.email"/>
                        </label>
                        <input value={user.email} disabled />
                    </ResponsiveFormField>
                    <ResponsiveFormField>
                        <label>
                            <FormattedMessage id="user.name"/>
                        </label>
                        <input defaultValue={user.name} ref={this.nameRef} onChange={this.startTextEditing}/>
                    </ResponsiveFormField>
                    <ResponsiveFormField>
                    <Button color="orange" fluid type="submit" onClick={ this.update }
                        loading={loading} disabled={loading || !this.isEditing()}>
                        <Icon name="check circle outline" />
                        <FormattedMessage id="component.button.submit"/>
                    </Button>
                    </ResponsiveFormField>
                    <ResponsiveFormField>
                    <LinkGoogleAccount {...this.props} manualScope=" " googleLoginHook={""}/>
                    </ResponsiveFormField>
                </Form>
            </Container>);
        } else {
            return <Redirect to="/login" />;
        }
    }
    private isEditing = (): boolean => {
        return this.state.textEditing;
    }
    private onAvatarSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                    this.setState({
                        selectedAvatar: reader.result as string,
                        cropAvatarDialogOpen: true
                    });
                }
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    private update = (): void => {
        if  (this.props.state.userState.currentUser) {
            const user: User = this.props.state.userState.currentUser;
            const email: any = user.email;
            const name: any = this.nameRef.current && this.nameRef.current.value;
            const avatarUrl: string = this.props.state.userState.uploadedAvatarUrl
                ? this.props.state.userState.uploadedAvatarUrl
                : user.avatarUrl;
            this.props.actions.updateProfile({_id: user._id, email, name, avatarUrl} as User);
        }
    }
    private closeAvatarCropDialog = (): void => {
        this.setState({cropAvatarDialogOpen: false});
    }
    private updateAvatarUrl = (avatarData: Blob | null): void => {
        this.closeAvatarCropDialog();
        if (avatarData) {
            this.props.actions.uploadAvatar(avatarData);
            this.setState({
                textEditing: true
            });
        }
    }
    private startTextEditing = () => {
        if (this.props.state.userState) {
            const user: User = this.props.state.userState.currentUser as User;
            if (user.name === (this.nameRef.current && this.nameRef.current.value)
                && user.avatarUrl === this.props.state.userState.uploadedAvatarUrl) {
                this.setState({
                    textEditing: false
                });
            } else {
                this.setState({
                    textEditing: true
                });
            }
        }
    }
}

export default connectAllProps(Profile);