import React from "react";
import { Container, Grid, Image } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../shared/styles";
import { FormattedMessage } from "react-intl";
import { isIE } from "../../shared/platform";
import { Link } from "react-router-dom";

interface Props {}

interface States {}
export default class Footer extends React.Component<Props, States> {
    render(): React.ReactElement<any> {
        if (isIE()) {
            return <p/>; // TODO: Fix the footer issue on IE
        }
        return (
            <Container style={CONTAINER_STYLE}>
                <Grid>
                    <Grid.Row style={{ minHeight: 30 }}></Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={15} textAlign="left" verticalAlign="bottom">

                            <div style={{display: "inline-flex", verticalAlign: "bottom"}}>
                                <div style={{display: "block", width: "50px"}}>
                                    <Image src="/favicon.png" size="mini" href="/" />
                                </div>
                                <div style={{display: "block", width: "150px", marginRight: "20px"}}>
                                    <Link to="/privacy" style={{ color: "#252525" }} >
                                        <FormattedMessage id="page.privacy" />
                                    </Link>
                                    <br />
                                    <Link to="/terms" style={{ color: "#252525" }} >
                                        <FormattedMessage id="page.terms" />
                                    </Link>
                                    <br />
                                    <Link to="/google_api_disclosure" style={{ color: "#252525" }} >
                                        Google API Disclosure
                                    </Link>
                                </div>
                                <div style={{display: "block", marginRight: "20px"}}>
                                    <a href="https://airjam-demo.netlify.app/" style={{ color: "#252525" }} >
                                        Contact Us
                                    </a>
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={15} verticalAlign="bottom">
                            <p style={{ marginLeft: "50px"}}><FormattedMessage id="app.footer" /></p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ minHeight: 30 }}></Grid.Row>
                </Grid>
            </Container>
        );
    }
}