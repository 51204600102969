import { AnyAction as Action } from "redux";
import AppointmentState from "../models/client/AppointmentState";
import { GET_APPOINTMENT_SUCCESS, SAVE_APPOINTMENT_SUCCESS, GET_APPOINTMENT_BEGIN, SAVE_APPOINTMENT_BEGIN, SAVE_APPOINTMENT_FAILED, GET_APPOINTMENT_FAILED, GET_MORE_APPOINTMENT_SUCCESS, GET_MORE_APPOINTMENT_BEGIN, GET_MORE_APPOINTMENT_FAILED, REMOVE_APPOINTMENT_BEGIN, REMOVE_APPOINTMENT_SUCCESS, REMOVE_APPOINTMENT_FAILED } from "../actions/appointment";

const initialState: AppointmentState = {
    loading: false,
    valid: false,
    data: [],
    loadingMore: false,
    hasMore: false
};

const appointment = (state: AppointmentState = initialState, action: Action): AppointmentState => {
    switch (action.type) {
        case GET_APPOINTMENT_BEGIN:
        case SAVE_APPOINTMENT_BEGIN:
        case REMOVE_APPOINTMENT_BEGIN:
            return {...state, loading: true};
        case GET_APPOINTMENT_SUCCESS:
            return {
                ...state,
                data: action.appointments,
                valid: true,
                loading: false,
                hasMore: action.hasMore
            };
        case GET_MORE_APPOINTMENT_BEGIN:
            return {...state, loadingMore: true};
        case GET_MORE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                data: [...state.data, ...action.appointments],
                loadingMore: false,
                hasMore: action.hasMore
            };
        case SAVE_APPOINTMENT_SUCCESS:
            // merge the added/updated appointment instantly, without waiting for the appointment list fetching
            return {...state, valid: false, loading: false, data: [...state.data, action.appointment]};
        case REMOVE_APPOINTMENT_SUCCESS:
            return {...state, valid: false, loading: false};
        case GET_APPOINTMENT_FAILED:
        case REMOVE_APPOINTMENT_FAILED:
        case SAVE_APPOINTMENT_FAILED:
            return {...state, loading: false};
        case GET_MORE_APPOINTMENT_FAILED:
            return {
                ...state,
                loadingMore: false,
                hasMore: false
            };
        default:
            return state;
    }
};

export default appointment;