import BlockActionCreator from "../models/client/BlockActionCreator";
import { Dispatch } from "redux";
import fetch from "../shared/fetch";
import actions from "./common";
import Block from "../models/Block";
import BlockType from "../models/BlockType";
import DataSource from "../models/DataSource";
import BlockPreferences from "../models/BlockPreferences";
import SharingType from "../models/SharingType";
import GetBlocksResponse from "../models/response/GetBlocksResponse.d";
import { getToast as toast } from "../shared/toast";
import { TableViewViewType, PaginationStyle } from "@airjam/types";
import BlockContentType from "../models/BlockContentType";
import BlockView from "../models/BlockView";
import GetBlockViewsResponse from "../models/response/GetBlockViewsResponse";
import GetBlockViewResponse from "../models/response/GetBlockViewResponse";

export const REMOVE_BLOCK_BEGIN: string = "REMOVE_BLOCK_BEGIN";
export const REMOVE_BLOCK_SUCCESS: string = "REMOVE_BLOCK_SUCCESS";
export const REMOVE_BLOCK_FAILED: string = "REMOVE_BLOCK_FAILED";
export const GET_BLOCK_BEGIN: string = "GET_BLOCK_BEGIN";
export const GET_BLOCK_SUCCESS: string = "GET_BLOCK_SUCCESS";
export const GET_BLOCK_FAILED: string = "GET_BLOCK_FAILED";
export const GET_MORE_BLOCK_BEGIN: string = "GET_MORE_BLOCK_BEGIN";
export const GET_MORE_BLOCK_SUCCESS: string = "GET_MORE_BLOCK_SUCCESS";
export const GET_MORE_BLOCK_FAILED: string = "GET_MORE_BLOCK_FAILED";
export const SAVE_BLOCK_BEGIN: string = "SAVE_BLOCK_BEGIN";
export const SAVE_BLOCK_SUCCESS: string = "SAVE_BLOCK_SUCCESS";
export const SAVE_BLOCK_FAILED: string = "SAVE_BLOCK_FAILED";

// BLOCK VIEWS
export const REMOVE_BLOCK_VIEW_BEGIN: string = "REMOVE_BLOCK_VIEW_BEGIN";
export const REMOVE_BLOCK_VIEW_SUCCESS: string = "REMOVE_BLOCK_VIEW_SUCCESS";
export const REMOVE_BLOCK_VIEW_FAILED: string = "REMOVE_BLOCK_VIEW_FAILED";
export const GET_BLOCK_VIEW_BEGIN: string = "GET_BLOCK_VIEW_BEGIN";
export const GET_BLOCK_VIEW_SUCCESS: string = "GET_BLOCK_VIEW_SUCCESS";
export const GET_BLOCK_VIEW_FAILED: string = "GET_BLOCK_VIEW_FAILED";
export const GET_MORE_BLOCK_VIEW_BEGIN: string = "GET_MORE_BLOCK_VIEW_BEGIN";
export const GET_MORE_BLOCK_VIEW_SUCCESS: string = "GET_MORE_BLOCK_VIEW_SUCCESS";
export const GET_MORE_BLOCK_VIEW_FAILED: string = "GET_MORE_BLOCK_VIEW_FAILED";
export const SAVE_BLOCK_VIEW_BEGIN: string = "SAVE_BLOCK_VIEW_BEGIN";
export const SAVE_BLOCK_VIEW_SUCCESS: string = "SAVE_BLOCK_VIEW_SUCCESS";
export const SAVE_BLOCK_VIEW_FAILED: string = "SAVE_BLOCK_VIEW_FAILED";

const blockActionCreator: BlockActionCreator = {
    getBlocks(): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({ type: GET_BLOCK_BEGIN });
            fetch("/api/block", undefined, "GET")
                .then((json: GetBlocksResponse) => {
                    if (json && json.blocks) {
                        dispatch({
                            type: GET_BLOCK_SUCCESS,
                            blocks: json.blocks,
                            hasMore: json.hasMore
                        });
                    } else {
                        return Promise.reject({ name: "500 Internal Server Error", message: "" });
                    }
                })
                .catch((error: Error) => {
                    dispatch(actions.handleFetchError(GET_BLOCK_FAILED, error));
                });
        };
    },
    getMoreBlocks(earlierThan: string): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({ type: GET_MORE_BLOCK_BEGIN });
            fetch(`/api/block?latest=${earlierThan}`, undefined, "GET")
                .then((json: GetBlocksResponse) => {
                    if (json && json.blocks) {
                        dispatch({
                            type: GET_MORE_BLOCK_SUCCESS,
                            blocks: json.blocks,
                            hasMore: json.hasMore
                        });
                    } else {
                        return Promise.reject({ name: "500 Internal Server Error", message: "" });
                    }
                })
                .catch((error: Error) => {
                    dispatch(actions.handleFetchError(GET_MORE_BLOCK_FAILED, error));
                });
        };
    },
    addBlock(title: String, blockType: BlockType, dataSource: DataSource, preferences: BlockPreferences, sharingType: SharingType): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: SAVE_BLOCK_BEGIN});
            fetch("/api/block/create", { title, blockType, dataSource, preferences, sharingType }, "POST", /*withToken*/ true)
            .then((added: Block) => {
                if (!added) {
                    return Promise.reject({ name: "500 Internal Server Error", message: "Broken data." });
                }
                toast().success("toast.block.save_successfully");
                dispatch({
                    type: SAVE_BLOCK_SUCCESS,
                    block: added,
                    redirectTask: {
                        redirected: false,
                        to: `/block/${added._id}`
                    }
                });
            });
        };
    },
    editBlock(block: Block): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({ type: SAVE_BLOCK_BEGIN });
            fetch("/api/block/edit", block, "POST", /*withToken*/ true)
                .then((updated: Block) => {
                    toast().success("toast.block.save_successfully");
                    dispatch({
                        type: SAVE_BLOCK_SUCCESS,
                        block: updated,
                        redirectTask: {
                            redirected: false,
                            to: `/block/${updated._id}`
                        }
                    });
                })
                .catch((error: Error) => {
                    dispatch(actions.handleFetchError(SAVE_BLOCK_FAILED, error));
                });
        };
    },
    removeBlock(id: string): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({ type: REMOVE_BLOCK_BEGIN });
            fetch(`/api/block/remove/${id}`, undefined, "GET", /*withToken*/ true)
                .then((json: any) => {
                    toast().success("toast.block.delete_successfully");
                    dispatch({
                        type: REMOVE_BLOCK_SUCCESS,
                        redirectTask: {
                            redirected: false,
                            to: "/block"
                        }
                    });
                })
                .catch((error: Error) => {
                    dispatch(actions.handleFetchError(REMOVE_BLOCK_FAILED, error));
                });
        };
    },
    addBlockView(viewType: TableViewViewType, paginationStyle: PaginationStyle, contentType: BlockContentType, commentsEnabled: boolean, reviewsEnabled: boolean, block: Block | undefined): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({ type: SAVE_BLOCK_BEGIN });
            fetch("/api/view/block/create", { viewType, paginationStyle, contentType, commentsEnabled, reviewsEnabled }, "POST", /*withToken*/ true)
                .then((added: BlockView) => {
                    if (!added) {
                        return Promise.reject({ name: "500 Internal Server Error", message: "Broken data." });
                    }
                    toast().success("toast.block.view.save_successfully");
                    // todo (minjae) -- call addBlock to add block as well
                    dispatch({
                        type: SAVE_BLOCK_VIEW_SUCCESS,
                        block: added,
                        redirectTask: {
                            redirected: false,
                            to: `/view/block/${added._id}`
                        }
                    });
                });
        };
    },
    getBlockViews(): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({ type: GET_BLOCK_VIEW_BEGIN });
            fetch("/api/view/block", undefined, "GET")
                .then((json: GetBlockViewsResponse) => {
                    if (json && json.blockViews) {
                        dispatch({
                            type: GET_BLOCK_VIEW_SUCCESS,
                            blockViews: json.blockViews,
                            hasMore: json.hasMore
                        });
                    } else {
                        return Promise.reject({ name: "500 Internal Server Error", message: "" });
                    }
                })
                .catch((error: Error) => {
                    dispatch(actions.handleFetchError(GET_BLOCK_VIEW_FAILED, error));
                });
        };
    },
    getMoreBlockViews(earlierThan: string): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({ type: GET_MORE_BLOCK_VIEW_BEGIN });
            fetch(`/api/view/block?latest=${earlierThan}`, undefined, "GET")
                .then((json: GetBlockViewsResponse) => {
                    if (json && json.blockViews) {
                        dispatch({
                            type: GET_MORE_BLOCK_VIEW_SUCCESS,
                            blockViews: json.blockViews,
                            hasMore: json.hasMore
                        });
                    } else {
                        return Promise.reject({ name: "500 Internal Server Error", message: "" });
                    }
                })
                .catch((error: Error) => {
                    dispatch(actions.handleFetchError(GET_MORE_BLOCK_VIEW_FAILED, error));
                });
        };
    },
    getBlock(id: string): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({ type: GET_BLOCK_VIEW_BEGIN });
            fetch(`/api/view/block/${id}`, undefined, "GET")
                .then((json: GetBlockViewResponse) => {
                    if (json && json.blockView) {
                        dispatch({
                            type: GET_BLOCK_VIEW_SUCCESS,
                            blockView: json.blockView,
                            block: json.block
                        });
                    } else {
                        return Promise.reject({ name: "500 Internal Server Error", message: "" });
                    }
                })
                .catch((error: Error) => {
                    dispatch(actions.handleFetchError(GET_BLOCK_VIEW_FAILED, error));
                });
        };
    },
    editBlockView(blockView: BlockView): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({ type: SAVE_BLOCK_VIEW_BEGIN });
            fetch("/api/view/block/edit", blockView, "POST", /*withToken*/ true)
                .then((updated: Block) => {
                    toast().success("toast.block.view.save_successfully");
                    dispatch({
                        type: SAVE_BLOCK_VIEW_SUCCESS,
                        block: updated,
                        redirectTask: {
                            redirected: false,
                            to: `/view/block/${updated._id}`
                        }
                    });
                })
                .catch((error: Error) => {
                    dispatch(actions.handleFetchError(SAVE_BLOCK_VIEW_FAILED, error));
                });
        };
    },
    removeBlockView(id: string): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({ type: REMOVE_BLOCK_VIEW_BEGIN });
            fetch(`/api/view/block/remove/${id}`, undefined, "GET", /*withToken*/ true)
                .then((json: any) => {
                    toast().success("toast.block.view.delete_successfully");
                    dispatch({
                        type: REMOVE_BLOCK_VIEW_SUCCESS,
                        redirectTask: {
                            redirected: false,
                            to: "/view/block"
                        }
                    });
                })
                .catch((error: Error) => {
                    dispatch(actions.handleFetchError(REMOVE_BLOCK_VIEW_FAILED, error));
                });
        };
    }
};

export default blockActionCreator;