import React from "react";
import { Header, Icon, Image, Grid, Button, Container, Label, Table, List } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import User from "../../../models/User";
import { ComponentProps } from "../../../shared/ComponentProps";
import connectAllProps from "../../../shared/connect";
interface States {}
class TableView extends React.Component<ComponentProps, States> {
    render() {
        const user: User | undefined = this.props.state.userState.currentUser;
        return <Container style={CONTAINER_STYLE}>
            <Grid stackable>
                <Grid.Row style={{ minHeight: 30 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={10} verticalAlign="bottom">
                        <Header textAlign="center" size="huge">
                            <div>
                                <Image.Group size="mini">
                                    <Image src="/images/languages/js.png" alt="Javascript"/>
                                    <Image src="/images/languages/ts.png" alt="Typescript"/>
                                    <Image src="/images/languages/react.png" alt="React.JS"/>
                                </Image.Group>
                            </div>
                            <span className="punchLine"><FormattedMessage id="page.view.table.punch_line" /></span>
                            <Header.Subheader className="punchLineSubHeader">
                                <FormattedMessage id="page.view.table.punch_line_sub" />
                            </Header.Subheader>
                            <Link to={user ? "/project/" : "/login/r/project"}>
                                <Button animated="vertical" style={{ marginTop: 15, marginBottom: 20, borderRadius: 30, padding: "15px 20px 15px 20px" }} color="black">
                                    <Button.Content visible>Go to console</Button.Content>
                                    <Button.Content hidden>
                                        <Icon name="terminal" />
                                    </Button.Content>
                                </Button>
                            </Link>
                            <Link to={user ? "/project/" : "/join/"}>
                                <Button animated="vertical" style={{ marginTop: 15, marginBottom: 20, borderRadius: 30, padding: "15px 20px 15px 20px" }} color="orange">
                                    <Button.Content visible><FormattedMessage id="get_started" /></Button.Content>
                                    <Button.Content hidden>
                                        <Icon name="arrow right" />
                                    </Button.Content>
                                </Button>
                            </Link>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={12}>
                        <img src="/images/view_table_punch.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/view_table_spreadsheets.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={7} verticalAlign="bottom">
                        <Header as="h1" textAlign="left" size="large" style={{ width: "100%" }}>
                            <FormattedMessage id="page.view.table.spreadsheet" />
                            <Header.Subheader className="subheader_more_margin_top">
                                <FormattedMessage id="page.view.table.spreadsheet_sub" />
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={7} verticalAlign="bottom">
                        <Header as="h1" textAlign="right" size="large" style={{ width: "100%" }}>
                            <FormattedMessage id="page.view.table.customizable" />
                            <Header.Subheader className="subheader_more_margin_top">
                                <FormattedMessage id="page.view.table.customizable_sub" />
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/view_table_stickers.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/view_table_privacy.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={7} verticalAlign="bottom">
                        <Header as="h1" textAlign="left" size="large" style={{ width: "100%" }}>
                            <FormattedMessage id="page.view.table.privacy" />
                            <Header.Subheader className="subheader_more_margin_top">
                                <FormattedMessage id="page.view.table.privacy_sub" />
                            </Header.Subheader>
                            <br /><br />
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={7} verticalAlign="bottom">
                        <Header as="h1" textAlign="right" size="large" style={{ width: "100%" }}>
                            <FormattedMessage id="page.view.table.no_payment" />
                            <Header.Subheader className="subheader_more_margin_top">
                                <FormattedMessage id="page.view.table.no_payment_sub" />
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/view_table_credit_card.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={4}></Grid.Column>
                    <Grid.Column width={8}>
                        <Header as="h1" textAlign="center">
                            <FormattedMessage id="page.view.table.pricing" />
                            <Header.Subheader className="subheader_more_margin_top">
                                It's FREE to get started with AirJam's TableView component! Premium features are available as add ons to your component instances.
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={4}></Grid.Column>
                </Grid.Row>
            </Grid>


        </Container>;
    }
}

export default connectAllProps(TableView);