import React, { Fragment } from "react";
// eslint-disable-next-line
import { Route, Switch, match } from "react-router";
import connectAllProps from "../../shared/connect";
import AppointmentList from "../components/appointment/AppointmentList";
import CreateAppointment from "../components/appointment/CreateAppointment";
import { ComponentProps as Props } from "../../shared/ComponentProps";
import EditAppointment from "../components/appointment/EditAppointment";
import AppointmentDetail from "../components/appointment/AppointmentDetail";

interface States { }

class Appointments extends React.Component<Props, States> {
    componentDidMount() {
        this.props.actions.getAppointments();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.state.blockState.valid && !this.props.state.blockState.valid) {
            this.props.actions.getAppointments();
        }
    }

    render(): any {
        const match: match<any> = this.props.match;
        return <Fragment>
            <Switch>
                <Route exact path={match.url} render={(props) => <AppointmentList {...props} />} />
                <Route path={`${match.url}/create`} render={(props) => <CreateAppointment {...props} />} />
                <Route path={`${match.url}/edit/:appointmentId`} render={(props) => <EditAppointment {...props} />} />
                <Route path={`${match.url}/:appointmentId`} render={(props) => <AppointmentDetail {...props} />} />
            </Switch>
        </Fragment>;
    }
}

export default connectAllProps(Appointments);
