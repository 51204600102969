import React from "react";
import connectAllProps from "../../../shared/connect";
import { Redirect } from "react-router-dom";
import Project from "../../../models/Project";
import ErrorPage from "../../pages/ErrorPage";
import { Container, Header } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
import ProjectEditor from "./ProjectEditor";
import { FormattedMessage } from "react-intl";
import { isMobile } from "../dimension";
import { pendingRedirect } from "../../../shared/redirect";
import { ComponentProps as Props } from "../../../shared/ComponentProps";

interface States {}
class EditProject extends React.Component<Props, States> {
    private projectId: string = "";
    render(): React.ReactElement<any> {
        if (pendingRedirect(this.props)) {
            return <Redirect to={this.props.state.redirectTask.to} />;
        }
        const notFoundError: Error = {
            name: "404 Not Found",
            message: `not found for ${window.location.href} `
        };
        this.projectId = this.props.match && this.props.match.params && this.props.match.params.projectId;
        if (!this.projectId) {
            return <ErrorPage error={notFoundError} />;
        }
        const project: Project | undefined = this.props.state.projectState.data.find(
            (value: Project): boolean => value._id === this.projectId
        );
        if (!project) {
            return <ErrorPage error={notFoundError} />;
        }
        if (this.props.state.userState.currentUser) {
            const containerStyle: any = isMobile() ? CONTAINER_STYLE :
                {...CONTAINER_STYLE, paddingLeft: 20, paddingRight: 20};
            return (
                <Container style={containerStyle}>
                    <Header size={"medium"}>
                        <FormattedMessage id="page.project.edit" />
                    </Header>
                    <ProjectEditor project={project}
                        submitTextId="component.button.update"
                        onSubmit={this.editProject}
                        loading={this.props.state.projectState.loading} />
                </Container>
            );
        } else {
            return <Redirect to="/project" />;
        }
    }

    private editProject = (title: string, description: string): void => {
        if (this.props.state.userState.currentUser) {
            this.props.actions.editProject({
                title: title,
                description: description,
                _id: this.projectId
            } as Project);
        }
    }
}

export default connectAllProps(EditProject);