import ProjectActionCreator from "../models/client/ProjectActionCreator";
import { Dispatch } from "redux";
import fetch from "../shared/fetch";
import actions from "./common";
import Project from "../models/Project";
import GetProjectsResponse from "../models/response/GetProjectsResponse";
import { getToast as toast } from "../shared/toast";
import GetProjectResponse from "../models/response/GetProjectResponse";
import ActionCreator from "../models/client/ActionCreator";
import { TableView } from "../models/TableView";

export const SAVE_PROJECT_BEGIN: string = "SAVE_PROJECT_BEGIN";
export const SAVE_PROJECT_SUCCESS: string = "SAVE_PROJECT_SUCCESS";
export const SAVE_PROJECT_FAILED: string = "SAVE_PROJECT_FAILED";
export const REMOVE_PROJECT_BEGIN: string = "REMOVE_PROJECT_BEGIN";
export const REMOVE_PROJECT_SUCCESS: string = "REMOVE_PROJECT_SUCCESS";
export const REMOVE_PROJECT_FAILED: string = "REMOVE_PROJECT_FAILED";
export const GET_PROJECT_BEGIN: string = "GET_PROJECT_BEGIN";
export const GET_PROJECT_SUCCESS: string = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAILED: string = "GET_PROJECT_FAILED";
export const GET_MORE_PROJECT_BEGIN: string = "GET_MORE_PROJECT_BEGIN";
export const GET_MORE_PROJECT_SUCCESS: string = "GET_MORE_PROJECT_SUCCESS";
export const GET_MORE_PROJECT_FAILED: string = "GET_MORE_PROJECT_FAILED";
export const INSERT_IMAGE_BEGIN: string = "INSERT_IMAGE_BEGIN";
export const INSERT_IMAGE_SUCCESS: string = "INSERT_IMAGE_SUCCESS";
export const INSERT_IMAGE_FAILED: string = "INSERT_IMAGE_FAILED";

const projectActionCreator: ProjectActionCreator = {
    getProjects(): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: GET_PROJECT_BEGIN});
            fetch("/api/project", undefined, "GET", true)
            .then((json: GetProjectsResponse) => {
                if (json && json.data) {
                    dispatch({
                        type: GET_PROJECT_SUCCESS,
                        projects: json.data,
                        hasMore: json.hasMore
                    });
                } else {
                    return Promise.reject({ name: "500 Internal Server Error", message: "" });
                }
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(GET_PROJECT_FAILED, error));
            });
        };
    },
    getProject(id: string, action: ActionCreator): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: GET_PROJECT_BEGIN});
            fetch(`/api/project/${id}`, undefined, "GET", true)
            .then((json: GetProjectResponse) => {
                if (json && json.project) {
                    dispatch({
                        type: GET_PROJECT_SUCCESS,
                        project: json.project,
                    });
                    json.project.tableViewComponents.forEach((view: TableView) => {
                        action.checkIfChangesUnpublished(view._id);
                    });
                } else {
                    return Promise.reject({ name: "500 Internal Server Error", message: "" });
                }
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(GET_PROJECT_FAILED, error));
            });
        };
    },
    getMoreProjects(earlierThan: string): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: GET_MORE_PROJECT_BEGIN});
            fetch(`/api/project?latest=${earlierThan}`, undefined, "GET", true)
            .then((json: GetProjectsResponse) => {
                if (json && json.data) {
                    dispatch({
                        type: GET_MORE_PROJECT_SUCCESS,
                        projects: json.data,
                        hasMore: json.hasMore
                    });
                } else {
                    return Promise.reject({ name: "500 Internal Server Error", message: "" });
                }
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(GET_MORE_PROJECT_FAILED, error));
            });
        };
    },
    addProject(project: Project): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: SAVE_PROJECT_BEGIN});
            const title = project.title;
            const description = project.description;
            const componentCount = project.componentCount;
            fetch("/api/project/create", { title: title, description: description, componentCount: componentCount }, "POST", /*withToken*/ true)
            .then((newProject: Project) => {
                if (newProject) {
                    toast().success("toast.project.save_successfully");
                    // add all the components here, too.
                    const promises = project.tableViewComponents.map(component => {
                        return fetch("/api/view/table/create", {
                            projectId: newProject._id,
                            sheetDocumentId: component.sheetDocumentId,
                            sheetId: component.sheetId,
                            title: component.title,
                            type: component.type,
                            sortBy: component.sortBy,
                            version: component.version,
                            paginationStyle: component.paginationStyle,
                            fieldMapping: component.fieldMapping,
                            templateId: component.templateId,
                            templateVersion: component.templateVersion,
                            styleId: component.styleId,
                            styleVersion: component.styleVersion,
                            cssOverride: component.cssOverride,
                            templateFields: component.templateFields,
                            templateProperties: component.templateProperties,
                            styleProperties: component.styleProperties
                        }, "POST", /*withToken*/ true)
                        .then(response => {
                            return response;
                        });
                    });

                    Promise.all(promises).then(results => {
                        newProject.tableViewComponents = results;
                        dispatch({
                            type: SAVE_PROJECT_SUCCESS,
                            project: newProject,
                            redirectTask: {
                                redirected: false,
                                to: `/project/${newProject._id}`
                            }
                        });
                    });
                } else {
                    return Promise.reject({ name: "500 Internal Server Error", message: "Broken data." });
                }
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(SAVE_PROJECT_FAILED, error));
            });
        };
    },
    editProject(project: Project): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: SAVE_PROJECT_BEGIN});
            fetch("/api/project/edit", project, "POST", /*withToken*/ true)
            .then((updated: Project) => {
                toast().success("toast.project.save_successfully");
                dispatch({
                    type: SAVE_PROJECT_SUCCESS,
                    project: updated,
                    redirectTask: {
                        redirected: false,
                        to: `/project/${updated._id}`
                    }
                });
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(SAVE_PROJECT_FAILED, error));
            });
        };
    },
    removeProject(id: string): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: REMOVE_PROJECT_BEGIN});
            fetch(`/api/project/remove/${id}`, undefined, "GET", /*withToken*/ true)
            .then((json: any) => {
                toast().success("toast.project.delete_successfully");
                dispatch({
                    type: REMOVE_PROJECT_SUCCESS,
                    redirectTask: {
                        redirected: true,
                        to: "/",
                        projectId: id
                    }
                });
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(REMOVE_PROJECT_FAILED, error));
            });
        };
    }
};

export default projectActionCreator;