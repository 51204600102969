import React from "react";
import connectAllProps from "../../../shared/connect";
import { Container, Grid } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
import { ComponentProps } from "../../../shared/ComponentProps";

interface States {
    sanitizedColors: string[];
}

interface Props extends ComponentProps {
    colors: string[];
}

class PreviewColors extends React.Component<Props, States> {
    constructor(props: Props) {
        super(props);
        this.state = {
            sanitizedColors: this.sanitizeColors(this.props.colors),
        };
    }
    componentDidUpdate(prevProps: Props) {
        const updatedColors = this.sanitizeColors(this.props.colors);
        if (this.state.sanitizedColors.sort().join(",") !== updatedColors.sort().join(",")) {
            this.setState({
                sanitizedColors: updatedColors
            });
        }
    }

    render(): React.ReactElement<any> {
        let grid: React.ReactElement<any> = <span></span>;
        switch (this.state.sanitizedColors.length) {
            case 0:
                break;
            case 1:
                grid = this.renderGridColCount1();
                break;
            case 2:
                grid = this.renderGridColCount2();
                break;
            case 3:
                grid = this.renderGridColCount3();
                break;
            case 4:
                grid = this.renderGridColCount4();
                break;
            default:
                grid = this.renderGridColCount5();
                break;
        }
        return <Container className={"colorPreview"}>{grid}</Container>;
    }

    private renderGridColCount1 = (): React.ReactElement<any> => {
        return <Grid columns={1}>
                <Grid.Row style={{height: "100%"}}>
                    <Grid.Column style={{ backgroundColor: this.state.sanitizedColors[0] }}>
                    &nbsp;</Grid.Column>
                </Grid.Row>
            </Grid>;
    }

    private renderGridColCount2 = (): React.ReactElement<any> => {
        return <Grid columns={2}>
                <Grid.Row style={{height: "100%"}}>
                    <Grid.Column style={{ backgroundColor: this.state.sanitizedColors[0] }}>
                    &nbsp;</Grid.Column>
                    <Grid.Column style={{ backgroundColor: this.state.sanitizedColors[1] }}>
                    &nbsp;</Grid.Column>
                </Grid.Row>
            </Grid>;
    }

    private renderGridColCount3 = (): React.ReactElement<any> => {
        return <Grid columns={3}>
                <Grid.Row style={{height: "100%"}}>
                    <Grid.Column style={{ backgroundColor: this.state.sanitizedColors[0] }}>
                    &nbsp;</Grid.Column>
                    <Grid.Column style={{ backgroundColor: this.state.sanitizedColors[1] }}>
                    &nbsp;</Grid.Column>
                    <Grid.Column style={{ backgroundColor: this.state.sanitizedColors[2] }}>
                    &nbsp;</Grid.Column>
                </Grid.Row>
            </Grid>;
    }

    private renderGridColCount4 = (): React.ReactElement<any> => {
        return <Grid columns={2}>
                <Grid.Row style={{height: "50%"}}>
                    <Grid.Column style={{ backgroundColor: this.state.sanitizedColors[0] }}>
                    &nbsp;</Grid.Column>
                    <Grid.Column style={{ backgroundColor: this.state.sanitizedColors[1] }}>
                    &nbsp;</Grid.Column>
                </Grid.Row>
                <Grid.Row style={{height: "50%"}}>
                    <Grid.Column style={{ backgroundColor: this.state.sanitizedColors[2] }}>
                    &nbsp;</Grid.Column>
                    <Grid.Column style={{ backgroundColor: this.state.sanitizedColors[3] }}>
                    &nbsp;</Grid.Column>
                </Grid.Row>
            </Grid>;
    }

    private renderGridColCount5 = (): React.ReactElement<any> => {
        return <Grid>
                <Grid.Row columns={3} style={{height: "50%"}}>
                    <Grid.Column style={{ backgroundColor: this.state.sanitizedColors[0] }}>
                    &nbsp;</Grid.Column>
                    <Grid.Column style={{ backgroundColor: this.state.sanitizedColors[1] }}>
                    &nbsp;</Grid.Column>
                    <Grid.Column style={{ backgroundColor: this.state.sanitizedColors[2] }}>
                    &nbsp;</Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2} style={{height: "50%"}}>
                    <Grid.Column style={{ backgroundColor: this.state.sanitizedColors[3] }}>
                    &nbsp;</Grid.Column>
                    <Grid.Column style={{ backgroundColor: this.state.sanitizedColors[4] }}>
                    &nbsp;</Grid.Column>
                </Grid.Row>
            </Grid>;
    }

    private sanitizeColors(colors: string[]): string[] {
        if (!colors || colors.length === 0) return [];
        const sanitizedColors: string[] = colors.map((color: string) => {
            return this.formatValidColorString(color);
        });
        return sanitizedColors.filter((color: string) => color.length > 0);
    }

    private formatValidColorString(color: string): string {
        const s = new Option().style;
        s.color = color;
        if (s.color !== "") return color;
        if (!color.startsWith("#")) {
            const newStr = "#" + color;
            s.color = newStr;
            if (s.color !== "") return newStr;
        }
        return "";
    }

}

export default connectAllProps(PreviewColors);