import { DropdownItemProps } from "semantic-ui-react";
import { PaginationStyle } from "@airjam/types";
import { ComponentProps as Props } from "../../../shared/ComponentProps";

export default function paginationStyles(props: Props): DropdownItemProps[] {
    return Array.from(Object.keys(PaginationStyle) as Array<keyof typeof PaginationStyle>).map((key) => {
        return {
            key: key.toString(),
            text: props.intl.formatMessage({ id: "component.dropdown.pagination_style." + PaginationStyle[key].toString() }),
            value: PaginationStyle[key].toString()
        } as DropdownItemProps;
    });
}
