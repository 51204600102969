import React, { Fragment } from "react";
import connectAllProps from "../../../shared/connect";
import { Redirect } from "react-router-dom";
import { pendingRedirect } from "../../../shared/redirect";
import { TableView } from "../../../models/TableView";
import ErrorPage from "../../pages/ErrorPage";
import { Container, Header } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
import "react-tiny-fab/dist/styles.css";
import { MessageDescriptor } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import WarningModal from "../shared/WarningModal";
import FabAction from "../../../models/client/FabAction";
import { ComponentProps as Props } from "../../../shared/ComponentProps";

interface States {
    openDeleteWarning: boolean;
}

class TableViewDetail extends React.Component<Props, States> {
    private tableViewId: string = "";
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.tableViewId = this.props.match && this.props.match.params && this.props.match.params.tableViewId;
        this.getString = this.props.intl.formatMessage;
        this.state = {
            openDeleteWarning: false
        };
    }
    componentDidMount() {
        if (this.tableViewId) {
            this.addFabActions();
        }
        this.props.actions.resetRedirectTask();
        window.scrollTo(0, 0);
    }
    componentDidUpdate(prevProps: Props) {
        if ((prevProps.state.tableViewState.loading
            && !this.props.state.tableViewState.loading) ||
            (!prevProps.state.userState.currentUser
                && this.props.state.userState.currentUser)) {
            this.addFabActions();
        }
    }
    componentWillUnmount() {
        this.props.actions.setFabActions([]);
    }
    render(): React.ReactElement<any> {
        if (pendingRedirect(this.props)) {
            return <Redirect to={this.props.state.redirectTask.to} />;
        }
        const notFoundError: Error = {
            name: "404 Not Found",
            message: `not found for ${window.location.href} `
        };
        if (!this.tableViewId) {
            return <ErrorPage error={notFoundError} />;
        }
        const tableView: TableView | undefined = this.props.state.tableViewState.data.find(
            (value: TableView): boolean => value._id === this.tableViewId
        );
        if (!tableView) {
            return <ErrorPage error={notFoundError} />;
        }
        return (
            <Fragment>
                <div style={{ paddingTop: 20 }} >
                    <Container text textAlign="center">
                        <Header size={"medium"}>
                            {tableView.type + "-" + tableView.type}
                        </Header>
                    </Container>
                    <Container text style={CONTAINER_STYLE}>
                        <p>{tableView.type} -- todo</p>
                    </Container>
                </div>
                {
                    this.renderDeleteWarningModal(tableView)
                }
            </Fragment>
        );
    }
    private isOwnerOf = (tableView: TableView): boolean => {
        return tableView.ownerId === (
            this.props.state.userState.currentUser &&
            this.props.state.userState.currentUser._id);
    }
    private addFabActions = (): void => {
        const tableView: TableView | undefined = this.props.state.tableViewState.data.find(
            (value: TableView): boolean => value._id === this.tableViewId
        );
        if (!tableView) {
            return;
        }
        if (this.isOwnerOf(tableView)) {
            const actions: FabAction[] = [{
                text: this.getString({ id: "component.button.delete" }),
                icon: "trash alternate",
                onClick: () => { this.setState({ openDeleteWarning: true }); },
            }, {
                text: this.getString({ id: "component.button.edit" }),
                icon: "edit",
                onClick: () => {
                    const target: string = this.props.match.url.replace(/^(.+)(\/[0-9a-z]+$)/, "$1/edit$2");
                    this.props.history.push(target, this.props.location.state);
                },
            }];
            this.props.actions.setFabActions(actions);
        }
    }
    private renderDeleteWarningModal = (tableView: TableView): React.ReactElement<any> | undefined => {
        return this.isOwnerOf(tableView) ?
            <WarningModal
                descriptionIcon="delete" open={this.state.openDeleteWarning}
                descriptionText={this.getString({ id: "page.table.view.delete" }, { title: tableView.type })}
                warningText={this.getString({ id: "page.table.view.delete_confirmation" })}
                onConfirm={() => this.removeTableView(tableView)}
                onCancel={() => { this.setState({ openDeleteWarning: false }); }} />
            : undefined;
    }
    private removeTableView = (tableView: TableView): void => {
        this.props.actions.removeTableView(tableView.projectId, this.tableViewId);
    }
}

export default connectAllProps(TableViewDetail);