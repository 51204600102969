import React, { RefObject } from "react";
import connectAllProps from "../../../shared/connect";
import { Button, Dropdown, Form, Header, Modal } from "semantic-ui-react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { ComponentProps } from "../../../shared/ComponentProps";
import { refToTableView, TableView, TableViewRef, vendTableViewRef } from "../../../models/TableView";
import { PrimitiveType } from "intl-messageformat";
import GoogleSheetDocumentSelector from "../components/GoogleSheetDocumentSelector";
import GoogleSheetSheetSelector from "../components/GoogleSheetSheetSelector";
import GoogleSheetSheetFieldConfiguration from "../components/GoogleSheetSheetFieldConfiguration";
import paginationStyles from "../components/PaginationStyles";
import sortByOptions from "../components/SortByOptions";
import ViewTypeSelect from "../components/ViewTypeSelect";
import TemplateSelect from "../components/TemplateSelect";
import StyleSelect from "../components/StyleSelect";
import TemplateFieldConfiguration from "../components/TemplateFieldConfiguration";
import { DataSourceField, TableViewViewType } from "@airjam/types";

enum WizardStep {
    CONFIG = "config",
    UI = "ui"
}

const customizeStepFlow: WizardStep[] = [WizardStep.CONFIG, WizardStep.UI];

interface Props extends ComponentProps {
    visible: boolean;
    projectId: string;
    defaultComponentName: string;
    closeModal: () => void;
}

interface States {
    dirty: boolean;
    currentStepIdx: number;
}

class CreateComponentWizard extends React.Component<Props, States> {
    private componentRef: TableViewRef;
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.componentRef = vendTableViewRef();
        this.componentRef.projectId = this.props.projectId;
        this.getString = this.props.intl.formatMessage;
        this.state = {
            dirty: false,
            currentStepIdx: 0
        };
    }
    render(): React.ReactElement<any> {
        if (this.props.state.userState.currentUser) {
            return (
                <Modal
                    dimmer="inverted"
                    open={this.props.visible}>
                    <Modal.Content>
                        <Modal.Description style={{ minHeight: 300 }}>
                            { this.configureComponentStep() }
                            { this.configureLookAndFeelStep() }
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.goBackToPreviousStep()} disabled={this.isFirstStep()}>
                            <FormattedMessage id="component.button.previous" />
                        </Button>
                        <Button onClick={() => this.moveToNextStep()} disabled={this.isLastStep() || this.isCurrentStepIncomplete()}>
                            <FormattedMessage id="component.button.next" />
                        </Button>
                        <Button onClick={() => this.confirmAndDismissModal()}>
                            <FormattedMessage id={this.isLastStep() && this.state.dirty ? "component.button.finish" : "component.button.close"} />
                        </Button>
                    </Modal.Actions>
                </Modal>
            );
        } else {
            return <i></i>;
        }
    }

    private configureComponentStep = (): React.ReactElement<any> => {
        return <div> {
                <div className={"wizardContent " + this.hideClassIfNotCurrentComponent(WizardStep.CONFIG)}>
                <Header size="large">{this.getString({ id: "page.project.wizard.configure.title" }, { title: this.refValueOrDefault(this.componentRef.title, this.props.defaultComponentName) })}</Header>
                <span>{this.getString({ id: "page.project.wizard.configure.description" }, { title: this.refValueOrDefault(this.componentRef.title, this.props.defaultComponentName) })}</span>
                <p></p>
                <Form>
                    <label>
                        <FormattedMessage id="data.source.spreadsheet.component.title" />
                    </label>
                        <input autoFocus={true} defaultValue={ this.props.defaultComponentName } onChange={this.onEditing} ref={this.componentRef.title} />
                    <p></p>
                    <label>
                        <FormattedMessage id="data.source.spreadsheet.select" />
                    </label>
                    <GoogleSheetDocumentSelector {...this.props} onChange={this.sheetSelectionChanged} componentId={this.componentRef.tableViewId} initialKey={this.componentRef.currentSheetKey} key={"doc.selector"} />
                    <label className={this.hideClassIfEmpty(this.componentRef.currentSheetKey)}>
                        <FormattedMessage id="data.source.spreadsheet.select.sheet" />
                    </label>
                    <GoogleSheetSheetSelector {...this.props} componentId={this.componentRef.tableViewId} sheetId={this.componentRef.currentSheetKey} onChange={this.sheetSheetSelectionChanged} initialSheetSelection={this.componentRef.currentSheetSelection} key={"sheet.selector"} className={this.hideClassIfEmpty(this.componentRef.currentSheetKey)} />
                    <label className={this.hideClassIfEmpty(this.componentRef.currentSheetSelection)}>
                        <FormattedMessage id="data.source.spreadsheet.configure" />
                    </label>
                    <GoogleSheetSheetFieldConfiguration {...this.props} componentId={this.componentRef.tableViewId} sheetId={this.componentRef.currentSheetKey} onChange={this.fieldMappingChanged} sheetKey={this.componentRef.currentSheetSelection} key={"field.selector"} className={this.hideClassIfEmpty(this.componentRef.currentSheetSelection)} />
                </Form>
                </div>
            }
        </div>;
    }

    private configureLookAndFeelStep = (): React.ReactElement<any> => {
        return <div> {
            <div className={"wizardContent " + this.hideClassIfNotCurrentComponent(WizardStep.UI)}>
                <Header size="large">{this.getString({ id: "page.project.wizard.look_and_feel.title" }, { title: this.refValueOrDefault(this.componentRef.title, this.props.defaultComponentName) })}</Header>
                <span><FormattedMessage id="page.project.wizard.look_and_feel.description" /></span>

                <p></p>
                <label>
                    <FormattedMessage id="component.view.type.title" />
                </label>
                <ViewTypeSelect key={"view_type"} componentId={this.componentRef.tableViewId} onChange={this.viewTypeSelectionChanged}></ViewTypeSelect>

                <p></p>
                <label className={this.componentRef.type ? "" : "hideImportant"}>
                    <FormattedMessage id="page.project.wizard.look_and_feel.theme" />
                </label>
                <TemplateSelect key={"template_select"} componentId={this.componentRef.tableViewId} selectedViewType={this.componentRef.type} onChange={this.templateSelectionChanged}></TemplateSelect>

                <p></p>
                <label className={this.componentRef.type && this.componentRef.templateId ? "" : "hideImportant"}>
                    <FormattedMessage id="page.project.wizard.look_and_feel.style" />
                </label>
                <StyleSelect key={"style_select"} componentId={this.componentRef.tableViewId} selectedTemplateId={this.componentRef.templateId} onChange={this.styleSelectionChanged}></StyleSelect>

                <p></p>
                <label className={this.componentRef.type && this.componentRef.templateId ? "" : "hideImportant"}>
                    <FormattedMessage id="page.project.wizard.look_and_feel.template_field_mapping" />
                </label>
                <TemplateFieldConfiguration key={"template_field_config"} componentId={this.componentRef.tableViewId} selectedTemplateId={this.componentRef.templateId} fieldMapping={this.componentRef.fieldMappingResult} initialTemplateFieldMapping={this.componentRef.templateFieldMapping} onChange={this.templateMappingChanged}></TemplateFieldConfiguration>

                <span className={(this.componentRef.type && this.paginationStyleApplicable(this.componentRef)) ? "" : "hideImportant"}>
                <p></p>
                <label>
                    <FormattedMessage id="page.project.wizard.look_and_feel.pagination" />
                </label>
                <Dropdown
                    placeholder={this.getString({ id: "component.table.view.pagination_style_select" })}
                    key={"pagination"}
                    fluid
                    selection
                    onChange={(e: any, value: any) => {
                        this.componentRef.currentPaginationStyle = value.value;
                        this.onEditing();
                    }}
                    options={paginationStyles(this.props)}
                />
                </span>

                <p></p>
                <label>
                    <FormattedMessage id="page.project.wizard.look_and_feel.sort_by" />
                </label>
                <Dropdown
                    placeholder={this.getString({ id: "component.dropdown.sort_by_select" })}
                    key={"sort_by"}
                    fluid
                    selection
                    onChange={(e: any, value: any) => {
                        this.componentRef.currentSortBy = value.value;
                        this.onEditing();
                    }}
                    options={sortByOptions(this.props)}
                />
            </div>
        }
        </div>;
    }
    private paginationStyleApplicable = (tableView: TableViewRef): boolean => {
        const viewType = TableViewViewType[tableView.type as keyof typeof TableViewViewType];
        switch (viewType) {
            case TableViewViewType.List:
            case TableViewViewType.Gallery:
                return true;
        }
        return false;
    }

    private hideClassIfNotCurrentComponent = (customizeStep: WizardStep): string => {
        return customizeStepFlow[this.state.currentStepIdx] === customizeStep ? "" : "hideImportant";
    }

    private hideClassIfEmpty = (componentValue: string): string => {
        if (!componentValue) return "hideImportant";
        return "";
    }

    private refValueOrDefault(ref: RefObject<HTMLInputElement>, defaultStr: string): string {
        if (ref && ref.current) return ref.current.value;
        if (defaultStr) return defaultStr;
        if (this.props.projectId && this.props.state.projectState.detailedProjects[this.props.projectId]) {
            const project = this.props.state.projectState.detailedProjects[this.props.projectId];
            return this.getString({ id: "page.project.wizard.configure.title.default_name" }, { index: project.tableViewComponents.length + 1 });
        }
        return "";
    }

    private isCurrentStepIncomplete = (): boolean => {
        // add restrictions here.
        return false;
    }

    private sheetSelectionChanged = (componentId: string, newKey: string) => {
        this.componentRef.currentSheetKey = newKey;
        this.componentRef.currentSheetSelection = ""; // reset sheet selection when doc changes
        this.componentRef.fieldMapping = React.createRef();
        this.onEditing();
    }

    private sheetSheetSelectionChanged = (componentId: string, newSheet: string) => {
        this.componentRef.currentSheetSelection = newSheet;
        this.onEditing();
    }

    private fieldMappingChanged = (componentId: string, key: string, newMapping: {[id: string]: DataSourceField}, example: any) => {
        this.componentRef.fieldMappingResult = newMapping;
        this.onEditing();
    }

    private viewTypeSelectionChanged = (componentId: string, newType: string) => {
        this.componentRef.type = newType;
        this.onEditing();
    }

    private templateSelectionChanged = (componentId: string, newTemplate: string, newTemplateVersion: number) => {
        this.componentRef.templateId = newTemplate;
        this.componentRef.templateVersion = newTemplateVersion;
        this.onEditing();
    }

    private styleSelectionChanged = (componentId: string, newStyle: string, newVersion: number) => {
        this.componentRef.styleId = newStyle;
        this.componentRef.styleVersion = newVersion;
        this.onEditing();
    }

    private templateMappingChanged = (componentId: string, templateMapping: {[id: string]: string}) => {
        this.componentRef.templateFieldMapping = templateMapping;
        this.onEditing();
    }

    private onEditing = () => {
        this.setState({
            dirty: true
        });
    }

    private moveToNextStep = () => {
        if (this.state.currentStepIdx < customizeStepFlow.length - 1) {
            this.setState({ currentStepIdx: this.state.currentStepIdx + 1 });
            this.forceUpdate();
        }
    }

    private goBackToPreviousStep = () => {
        if (this.state.currentStepIdx > 0) {
            this.setState({ currentStepIdx: this.state.currentStepIdx - 1 });
            this.forceUpdate();
        }
    }

    private isFirstStep = (): boolean => {
        return this.state.currentStepIdx === 0;
    }
    private isLastStep = (): boolean => {
        return this.state.currentStepIdx === customizeStepFlow.length - 1;
    }

    private confirmAndDismissModal = () => {
        if (this.state.dirty) {
            if (this.isLastStep()) {
                const tableView: TableView = refToTableView(this.componentRef);
                this.props.actions.addTableView(tableView);
                this.setState({
                    dirty: false
                });
                this.props.closeModal();
            } else {
                if (window.confirm(this.getString({ id: "page.project.wizard.close.confirm" }))) {
                    this.props.closeModal();
                }
            }
        } else {
            this.props.closeModal();
        }
    }
}

export default connectAllProps(CreateComponentWizard);