import React, { Fragment } from "react";
import connectAllProps from "../../../shared/connect";
import { Link, Redirect } from "react-router-dom";
import { pendingRedirect } from "../../../shared/redirect";
import ErrorPage from "../../pages/ErrorPage";
import { Button, Grid, Header, Icon, Label, List, Tab } from "semantic-ui-react";
import "react-tiny-fab/dist/styles.css";
import { MessageDescriptor, FormattedMessage } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import WarningModal from "../shared/WarningModal";
import UserLabel from "../user/UserLabel";
import FabAction from "../../../models/client/FabAction";
import moment from "moment";
import { ComponentProps as Props } from "../../../shared/ComponentProps";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ViewCountComponent from "./ViewCountComponent";
import CalendarEditor from "../calendar/CalendarEditor";
import { CallPage, PageActionType } from "../../../models/CallType";
import { getToast as toast } from "../../../shared/toast";
import { Appointment } from "../../../models/Appointment";
import CalendarReservationType from "../../../models/CalendarReservationType";
import CalendarManagementMode from "../../../models/CalendarManagementMode";
import { CalendarViewType, EventReservation, EventReservationStatus, PaymentProcessor } from "@airjam/types";
import AppointmentComponentAttributes from "./static/AppointmentComponentAttributes";
import BubbleInstructions from "./static/BubbleInstructions";
import { Calendar } from "@airjam/react-calendar";
import { getHostUrl } from "../../../shared/fetch";

const CENTURY = 100;

interface States {
    openDeleteWarning: boolean;
    authToken?: string;
    selectedReservationPanel: ReservationPanel;
}

enum ReservationPanel {
    PresentReservation = "present",
    PastReservation = "past",
    ReservationRequest = "request"
}

class AppointmentDetail extends React.Component<Props, States> {
    private appointmentId: string = "";
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.appointmentId = this.props.match && this.props.match.params && this.props.match.params.appointmentId;
        this.getString = this.props.intl.formatMessage;
        if (props && props.actions) {
            props.actions.getAuthToken((t) => this.handleAuthTokenUpdated(t));
        }
        this.state = {
            openDeleteWarning: false,
            selectedReservationPanel: ReservationPanel.PresentReservation,
        };
    }
    private handleAuthTokenUpdated = (token: string): void => {
        if (token) {
            this.setState({authToken: token});
            this.forceUpdate();
        }
    }

    componentDidMount() {
        if (this.appointmentId) {
            this.addFabActions();
        }
        this.props.actions.resetRedirectTask();
        window.scrollTo(0, 0);
    }
    componentDidUpdate(prevProps: Props) {
        if ((prevProps.state.appointmentState.loading
            && !this.props.state.appointmentState.loading) ||
            (!prevProps.state.userState.currentUser
            && this.props.state.userState.currentUser)) {
            this.addFabActions();
        }
    }
    componentWillUnmount() {
        this.props.actions.setFabActions([]);
    }
    render(): React.ReactElement<any> {
        if (pendingRedirect(this.props)) {
            return <Redirect to={this.props.state.redirectTask.to} />;
        }
        const notFoundError: Error = {
            name: "404 Not Found",
            message: `not found for ${window.location.href} `
        };
        if (!this.appointmentId) {
            return <ErrorPage error={notFoundError} />;
        }
        const appointment: Appointment | undefined = this.props.state.appointmentState.data.find(
            (value: Appointment): boolean => value._id === this.appointmentId
        );
        if (!appointment) {
            return <ErrorPage error={notFoundError} />;
        }
        const createDate: Date = appointment.createdAt ? new Date(appointment.createdAt) : new Date(0);
        const labelStyle: any = {
            color: "grey",
            marginTop: 2,
            marginBottom: 2
        };
        const panes = [
            { menuItem: "Settings", render: () => <Tab.Pane>
                { this.renderSettingsPane(appointment) }
            </Tab.Pane> },
            { menuItem: "Monitoring", render: () => <Tab.Pane>
                <ViewCountComponent pageType={CallPage.Calendar} title="Views" dataLabel="views" actionType={PageActionType.ViewCount} key={this.appointmentId + "-viewcount"} showReload={true} id={appointment.calendarComponentId} ></ViewCountComponent>
            </Tab.Pane> },
            { menuItem: "Reservations", render: () => <Tab.Pane>
                <Grid columns={2} divided>
                    <Grid.Row stretched className="fullHeight">
                        <Grid.Column width={3} className="leftPanel">
                            {this.renderReservationsLhs(appointment)}
                        </Grid.Column>
                        <Grid.Column width={12}>
                            {this.renderReservationsPanel(appointment)}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </Tab.Pane> },
            { menuItem: "Set-up / Installation", render: () => <Tab.Pane>
                <Header as="h3" id="setup">Setup</Header>
                <div><strong>Update:</strong> The feature to sync with external calendar services, such as Google Calendar, is currently available in closed-beta program. Contact us at contact@airjam.co with your request if you'd like to be added to the closed-beta program.</div>
                <br />
                <Tab panes={[
                    {menuItem: "React.JS", render: () => <Tab.Pane>
                        <h3>How to add your component to a React project</h3>
                        <br />
                        <a href="https://www.npmjs.com/package/@airjam/react-appointment"><img width="60" src="https://upload.wikimedia.org/wikipedia/commons/d/db/Npm-logo.svg" /></a><br/>
                        Click <a href="https://www.npmjs.com/package/@airjam/react-appointment">here</a>, or visit <a href="https://www.npmjs.com/package/@airjam/react-appointment">https://www.npmjs.com/package/@airjam/react-appointment</a> for instructions on how to use the react component
                    </Tab.Pane>},
                    {menuItem: "React Native", render: () => <Tab.Pane>
                        The React Native version of the Appointment component is currently available in an invite-only closed beta. To request access, please contact us at contact@airjam.co.
                    </Tab.Pane>},
                    {menuItem: "Squarespace", render: () => <Tab.Pane>
                        <h3>How to add your component to a Squarespace website</h3>
                        You can add AirJam components to your Squarespace site as <code>Embedded Scripts</code>. Follow this step-by-step guide to integrate AirJam components into your Squarespace website.
                        <ol>
                            <li>If you haven't done so already, start by creating a new website project on Squarespace. <a href="https://www.squarespace.com/how-to/build-a-website"><b>Here</b>'s</a> a quick guide on how to get started.</li>
                            <li>Navigate to the <b>Pages</b> in the <b>Webpage</b> section, select the page where you'd like to add the component, and click <b>Edit</b> to open Squarespace's editor for that page.</li>
                            <li>Locate the section in the page where you'd like the component to appear and click the <b>Add Block</b> button. A dialog box will open, displaying various block options. Select <b>Code</b> from the list.
                            <br /><br />
                            <img src="/docs/appointment/add_block.png" width={"70%"} />
                            </li>
                            <li>Double-click the newly added code block to open the code editor. Then, select the <b>HTML</b> option to designate the content as HTML code.
                            <br /><br />
                            <img src="/docs/appointment/code_block.png" width={"30%"} />
                            </li>
                            <li>Replace the existing content in the code block with the code provided below. You can customize the component using the attributes in the next section.
                                <SyntaxHighlighter language="javascript" style={vs2015}>
                                    { "<div id=\"airjam_appointment\" componentId=\"" + appointment.calendarComponentId + "\" " + (((appointment.paymentProcessor !== PaymentProcessor.None) && appointment.paymentProcessorToken) ? "paymentProcessorPublicKey=\"<get public key from your payment processor>\"" : "") + " /> \n" +
                                    "<script src=\"https://airjam.co/dist/react-appointment@1.1.0.js\" />" }
                                </SyntaxHighlighter>
                                The &lt;div&gt; tag is where your AirJam component will be embedded. AirJam uses this tag's id to locate the placement for the component. Please note only one instance of each AirJam component can be added per page. You can also control the component's behavior by adding attributes to this tag — see the list of available attributes in the section below.
                            <br /><br />
                            <img src="/docs/appointment/code.png" width={"70%"} />
                            </li>
                            <li>Next, let's add AirJam's preset styles for this component. Exit the page editor by clicking the <b>Exit</b> button at the top. Then, go to the <b>Website Tools</b> section under <b>Utilities</b> and select <b>Custom CSS</b> to open the CSS editor for your website.</li>
                            <li>Add following line to the CSS editor.
                                <SyntaxHighlighter language="javascript" style={vs2015}>
                                    { "@import \"https://airjam.co/dist/react-appointment@1.1.0.css\";" }
                                </SyntaxHighlighter>
                            <br /><br />
                            <img src="/docs/appointment/css.png" width={"70%"} />
                            </li>
                        </ol>
                        That's it! You should now be able to see your component on your website!
                        <h3>Attributes</h3>
                        Add following attributes to the &lt;div&gt; tag to modify the behavior of your component.
                        <AppointmentComponentAttributes appointment={appointment} />
                    </Tab.Pane>},
                    {menuItem: "Wordpress", render: () => <Tab.Pane>
                        <h3>How to add your component to a Wordpress website</h3>
                        You can embed AirJam components on WordPress sites that allow external scripts. If your WordPress site is hosted on a service like WordPress.com, you may need to subscribe to a specific plan to enable external scripts (e.g., WordPress.com requires a Business plan or higher). If you're running a self-hosted WordPress instance, external scripts should be enabled by default. Follow this step-by-step guide to integrate AirJam components into your WordPress website. This guide assumes you are using a recent version of WordPress with block editors. If you are using an older version, please refer to the relevant instructions for adding external scripts and CSS files for your version.
                        <ol>
                            <li>Navigate to the page where you want to add the AirJam component, and click <b>Edit</b> to open the page editor.</li>
                            <li>Next, locate the section of the page where you want the component to appear, and click the <b>Add Block</b> button (usually a plus icon). A dialog box will open, showing various block options. Select <b>Shortcode</b> from the list.
                            <br /><br />
                            <img src="/docs/appointment/wp/blocks.png" width={"30%"} />
                            </li>
                            <li>Paste the code provided below into the newly added Shortcode block. Please note you can customize your component by adjusting the attributes listed in the next section.
                                <SyntaxHighlighter language="javascript" style={vs2015}>
                                    { "<div id=\"airjam_appointment\" componentId=\"" + appointment.calendarComponentId + "\" " + (((appointment.paymentProcessor !== PaymentProcessor.None) && appointment.paymentProcessorToken) ? "paymentProcessorPublicKey=\"<get public key from your payment processor>\"" : "") + " /> \n" +
                                    "<script src=\"https://airjam.co/dist/react-appointment@1.1.0.js\" />" }
                                </SyntaxHighlighter>
                                <br /><br />
                                <img src="/docs/appointment/wp/shortcode.png" width={"50%"} />
                                <br /><br />
                                The <code>&lt;div&gt;</code> tag is where your AirJam component will be embedded, and AirJam uses this tag's id to determine its placement on the page. Note that only one instance of each AirJam component can be added per page. Again, you can also control the component's behavior by adding attributes to this tag — see the list of available attributes in the section below.</li>
                            <li>After saving your changes, you should immediately see your component displayed on the page. Next, let's add AirJam's preset CSS styles for this component. To include external CSS files on your WordPress site, there are multiple approaches, and <a href="https://elements.envato.com/learn/how-to-add-custom-css-to-your-wordpress-site">here's an <b>excellent guide</b></a> covering various options. For this tutorial, we'll use one of the most versatile options: a third-party plugin.</li>
                            <li>From your WordPress admin page, go to <b>Plugins &gt; Add New</b>, then search for <b>"custom CSS"</b> to see a selection of plugins. For this guide, we'll use <b>Simple Custom CSS</b> and JS by SilkyPress.com.
                            <br /><br />
                            <img src="/docs/appointment/wp/css_plugin.png" width={"30%"} />
                            </li>
                            <li>You should now see the <b>Custom CSS &amp; JS</b> menu in the admin console. Select <b>Add Custom CSS</b> from this menu, paste the following lines into the editor, and click Publish to save your changes.
                                <SyntaxHighlighter language="javascript" style={vs2015}>
                                    { "@import \"https://airjam.co/dist/react-appointment@1.1.0.css\";" }
                                </SyntaxHighlighter>
                                <br /><br />
                                <img src="/docs/appointment/wp/custom_css.png" width={"70%"} />
                            </li>
                            That's it! You should now be able to see your component on your page with AirJam's default styles!
                        </ol>
                        <h3>Attributes</h3>
                        Add following attributes to the &lt;div&gt; tag to modify the behavior of your component.
                        <AppointmentComponentAttributes appointment={appointment} />
                    </Tab.Pane>},
                    {menuItem: "Static Webpage", render: () => <Tab.Pane>
                        <h3>How to add your component to a webpage</h3>
                        AirJam components can be embedded into any website with JavaScript enabled. To get started, first add the following <code>&lt;div&gt;</code> block inside your <code>&lt;body&gt;</code> tag where you'd like your AirJam component to appear:
                        <SyntaxHighlighter language="javascript" style={vs2015}>
                            { "<div id=\"airjam_appointment\" componentId=\"" + appointment.calendarComponentId + "\" " + (((appointment.paymentProcessor !== PaymentProcessor.None) && appointment.paymentProcessorToken) ? "paymentProcessorPublicKey=\"<get public key from your payment processor>\"" : "") + " />"}
                        </SyntaxHighlighter>
                        Next, add the following tags to near the end of your <code>&lt;body&gt;</code> tag to instantiate the component and apply its default styles.
                        <SyntaxHighlighter language="javascript" style={vs2015}>
                            { "<script src=\"https://airjam.co/dist/react-appointment@1.1.0.js\" />\n" +
                             "<link rel=\"stylesheet\" type=\"text/css\" href=\"https://airjam.co/dist/react-appointment@1.1.0.css\" />"}
                        </SyntaxHighlighter>
                        And that's it! You should now be able to see your component on your page with AirJam's default styles!
                        <h3>Attributes</h3>
                        Add following attributes to the &lt;div&gt; tag to modify the behavior of your component. Please note that we highly discourage the use of <code>authToken</code> with a static authentication token, as this practice poses significant security risks for your component. Instead, configure and treat your components as publicly available resources (without authentication) and avoid processing any privileged information through them.
                        <AppointmentComponentAttributes appointment={appointment} />
                    </Tab.Pane>},
                    {menuItem: "Bubble.io", render: () => <Tab.Pane>
                        <h3>How to add your component as a component to your Bubble project</h3>
                        <BubbleInstructions appointment={appointment} />
                    </Tab.Pane>},
                    ]} menu={{ inverted: false, attached: true, tabular: true }} />
            </Tab.Pane> },
        ];
        return (
            <Fragment>
                <div style={{padding: 20}} >
                    <Header size={"medium"}>
                        <Link to="/appointments"><Icon name="home" /></Link>
                        {appointment.name}
                    </Header>
                    <Label style={labelStyle} color="black">
                        <FormattedMessage id="post.created_at" />
                        {moment(createDate).fromNow()}
                    </Label>
                    <Label style={labelStyle} color="black">
                        id: { appointment.calendarComponentId } &nbsp; <Icon style={{cursor: "pointer"}} name="copy" onClick={() => {
                            navigator.clipboard.writeText(appointment.calendarComponentId);
                            toast().success("toast.text.copied");
                        }}/>
                    </Label>
                    <div>&nbsp; </div>
                    <Tab menu={{ inverted: false, attached: true, tabular: true }} panes={panes} />
                </div>
                {
                    this.renderDeleteWarningModal(appointment)
                }
            </Fragment>
        );
    }

    private renderReservationsLhs = (cal: Appointment): any => {
        return <List relaxed>
            <List.Item>
                <List.Icon name="clock outline" />
                <List.Content>
                    <List.Header>
                        <span className={this.state.selectedReservationPanel === ReservationPanel.PresentReservation ? "navigationLabel current" : "navigationLabel"} onClick={() => {this.setState({selectedReservationPanel: ReservationPanel.PresentReservation }); }}>Upcoming Reservations</span>
                    </List.Header>
                </List.Content>
            </List.Item>
            <List.Item>
                <List.Icon name="history" />
                <List.Content>
                    <List.Header>
                        <span className={this.state.selectedReservationPanel === ReservationPanel.PastReservation ? "navigationLabel current" : "navigationLabel"} onClick={() => {this.setState({selectedReservationPanel: ReservationPanel.PastReservation }); }}>Past Reservations</span>
                    </List.Header>
                </List.Content>
            </List.Item>
            <List.Item>
                <List.Icon name="envelope outline" />
                <List.Content>
                    <List.Header>
                        <span className={this.state.selectedReservationPanel === ReservationPanel.ReservationRequest ? "navigationLabel current" : "navigationLabel"} onClick={() => {this.setState({selectedReservationPanel: ReservationPanel.ReservationRequest }); }}>Pending Reservation Requests</span>
                    </List.Header>
                </List.Content>
            </List.Item>
        </List>;
    }

    private renderReservationsPanel = (appointment: Appointment): any => {
        // This view is intentionally stateless, so react would reload data whenever panel selection changes.
        const filterStartDate = new Date();
        const filterEndDate = new Date();

        if (this.state.selectedReservationPanel === ReservationPanel.PastReservation) {
            filterStartDate.setFullYear(filterStartDate.getFullYear() - CENTURY);
        } else {
            filterEndDate.setFullYear(filterEndDate.getFullYear() + CENTURY);
        }

        const head = <div className="my-reservations-row thead">
            <div className="my-reservations-column">Name</div>
            <div className="my-reservations-column">Created</div>
            <div className="my-reservations-column">Reserved Time</div>
            <div className="my-reservations-column">Reserved Time End</div>
            <div className="my-reservations-column">Status</div>
            <div className="my-reservations-column">Price</div>
            <div className="my-reservations-column">Contact</div>
            <div className="my-reservations-column">Resource</div>
            <div className="my-reservations-column">Notes</div>
            <div className="my-reservations-column">Actions</div>
        </div>;
        return <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <div style={{marginTop: "20px", marginLeft: "10px"}}>
                        <h4>
                            {(() => {
                            switch (this.state.selectedReservationPanel) {
                                case ReservationPanel.ReservationRequest:
                                    return "Reservation Request";
                                case ReservationPanel.PastReservation:
                                    return "Past Reservations";
                                case ReservationPanel.PresentReservation:
                                    return "Upcoming Reservations";
                                default:
                                    return "Unregistered Panel";
                            }
                            })()}
                        </h4>

                        <div className="my-reservations-table ui celled table">
                            {this.state.selectedReservationPanel === ReservationPanel.ReservationRequest ? <Calendar id={appointment.calendarComponentId} viewAs={CalendarViewType.MyReservationRequestsList} host={getHostUrl()} authToken={this.state.authToken} showDate={filterStartDate} showEndDate={filterEndDate} myReservationsHead={head} renderEventReservationFunc={this.renderEventReservationRequest} /> :
                            <Calendar id={appointment.calendarComponentId} viewAs={CalendarViewType.MyReservationsList} host={getHostUrl()} authToken={this.state.authToken} showDate={filterStartDate} showEndDate={filterEndDate} renderMyReservationFunc={this.renderEventReservation} myReservationsHead={head} /> }
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>;
    }

    private renderEventReservation = (reservation: EventReservation, index: number, cancelButton: any): any => {
        const eventStartTime = new Date(reservation.startTimeUtc);
        const isPast = eventStartTime < new Date();
        const createdAt = reservation.createdAt ? new Date(reservation.createdAt) : new Date();
        const startTime = new Date(reservation.startTimeUtc);
        const endTimeUtc = new Date(reservation.endTimeUtc);
        return <div className={reservation.status === EventReservationStatus.Canceled ? "my-reservations-row canceled" : "my-reservations-row"} id={"my-reservations-row-" + index} key={"my-reservations-row-" + index}>
            <div className="my-reservations-column">{reservation.ownerName}</div>
            <div className="my-reservations-column">{createdAt.toString()}</div>
            <div className="my-reservations-column">{startTime.toString()}</div>
            <div className="my-reservations-column">{endTimeUtc.toString()}</div>
            <div className="my-reservations-column status">{reservation.status}</div>
            <div className="my-reservations-column">{reservation.total ? reservation.total : "FREE"}</div>
            <div className="my-reservations-column">{reservation.ownerEmail}</div>
            <div className="my-reservations-column">{reservation.resourceName}</div>
            <div className="my-reservations-column">{reservation.notes}</div>
            <div className="my-reservations-column">{isPast || reservation.status === EventReservationStatus.Canceled ? "" : cancelButton}</div>
        </div>;
    }

    private renderEventReservationRequest = (reservation: EventReservation, index: number, acceptButton: any, rejectButton: any, cancelButton: any): any => {
        const eventStartTime = new Date(reservation.startTimeUtc);
        const isPast = eventStartTime < new Date();
        const createdAt = reservation.createdAt ? new Date(reservation.createdAt) : new Date();
        const startTime = new Date(reservation.startTimeUtc);
        const endTimeUtc = new Date(reservation.endTimeUtc);

        return <div className={reservation.status === EventReservationStatus.Canceled ? "my-reservations-row canceled" : "my-reservations-row"} id={"my-reservations-row-" + index} key={"my-reservations-row-" + index}>
            <div className="my-reservations-column">{reservation.ownerName}</div>
            <div className="my-reservations-column">{createdAt.toString()}</div>
            <div className="my-reservations-column">{startTime.toString()}</div>
            <div className="my-reservations-column">{endTimeUtc.toString()}</div>
            <div className="my-reservations-column status">{reservation.status}</div>
            <div className="my-reservations-column">{reservation.total ? reservation.total : "FREE"}</div>
            <div className="my-reservations-column">{reservation.ownerEmail}</div>
            <div className="my-reservations-column">{reservation.resourceName}</div>
            <div className="my-reservations-column">{reservation.notes}</div>
            <div className="my-reservations-column">
                {acceptButton}
                {rejectButton}
                {isPast || reservation.status === EventReservationStatus.Canceled ? "" : cancelButton}
            </div>
        </div>;
    }

    private renderSettingsPane = (appointment: Appointment): React.ReactElement<any> => {
        return <div>
            <CalendarEditor calendar={appointment}
                submitTextId="component.button.update"
                fixedCalendarReservationType={CalendarReservationType.Reservable}
                fixedResourceManagedBy={CalendarManagementMode.Singleton}
                onSubmit={this.editAppointment}
                loading={this.props.state.uploaderState.loading} />
        </div>;
    }

    private editAppointment = (appointment: Appointment): void => {
        if (this.props.state.userState.currentUser) {
            this.props.actions.editAppointment(appointment);
        }
    }

    private isAuthorOf = (appointment: Appointment): boolean => {
        return appointment.ownerId === (
            this.props.state.userState.currentUser &&
            this.props.state.userState.currentUser._id);
    }
    private addFabActions = (): void => {
        const appointment: Appointment | undefined = this.props.state.appointmentState.data.find(
            (value: Appointment): boolean => value._id === this.appointmentId
        );
        if (!appointment) {
            return;
        }
        if (this.isAuthorOf(appointment)) {
            const actions: FabAction[] = [{
                text: this.getString({id: "component.button.delete"}),
                icon: "trash alternate",
                onClick: () => { this.setState({openDeleteWarning: true }); },
            }, {
                text: this.getString({id: "component.button.edit"}),
                icon: "edit",
                onClick: () => {
                    const target: string = this.props.match.url.replace(/^(.+)(\/[0-9a-z]+$)/, "$1/edit$2");
                    this.props.history.push(target, this.props.location.state);
                },
            }];
            this.props.actions.setFabActions(actions);
        }
    }
    private renderDeleteWarningModal = (appointment: Appointment): React.ReactElement<any> | undefined => {
        return this.isAuthorOf(appointment) ?
            <WarningModal
                descriptionIcon="delete" open={this.state.openDeleteWarning}
                descriptionText={this.getString({id: "page.appointment.delete"}, {title: appointment.name})}
                warningText={this.getString({id: "page.appointment.delete_confirmation"})}
                onConfirm={this.removeAppointment}
                onCancel={ () => {this.setState({openDeleteWarning: false}); }}/>
                : undefined;
    }

    private removeAppointment = (): void => {
        this.props.actions.removeAppointment(this.appointmentId);
    }
}

export default connectAllProps(AppointmentDetail);