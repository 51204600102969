import React from "react";
import connectAllProps from "../../shared/connect";
import { ComponentProps } from "../../shared/ComponentProps";
import { Button, Container, Grid, Header, Icon } from "semantic-ui-react";
import { Carousel } from "primereact/carousel";

import "primereact/resources/themes/tailwind-light/theme.css";

interface Props extends ComponentProps {}
interface States {
    rotateIdx: number;
    rotateIdx2: number;
}

interface UserGroupProps {
    punchImage: string;
    headline: string;
    description: string;
}

class Home extends React.Component<Props, States> {
    constructor(props: Props) {
        super(props);
        this.state = { rotateIdx: 0, rotateIdx2: 0 };
    }

    private interval: any = 0;
    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({ rotateIdx: (this.state.rotateIdx + 1) % 20, rotateIdx2: (this.state.rotateIdx2 + 1) % 12 }, () => this.forceUpdate());
        }, 4000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render(): React.ReactElement<any> {
        const userGroups: UserGroupProps[] = [
            { punchImage: "/images/landing/retailer-punch.png", headline: "Retail Brands", description: "Build retail apps as powerful as the Target app, at a fraction of the cost and speed"},
            { punchImage: "/images/landing/cafe-punch.png", headline: "Cafes", description: "Build custom apps with features akin to Starbucks apps in minutes"},
            { punchImage: "/images/landing/hotel-punch.png", headline: "Hotels and Accommodations", description: "Elevate your guests' experiences by providing custom branded apps with features comparable to Marriott Bonvoy"},
            { punchImage: "/images/landing/venue-punch.png", headline: "Venues", description: "Automate venue operations and increase venue rentals with your own booking system"},
            { punchImage: "/images/landing/festival-punch.png", headline: "Festivals and Exhibitions", description: "Elevate visitor experiences with a dedicated app at a fraction of the cost"},
            { punchImage: "/images/landing/food-punch.png", headline: "Food Services", description: "Elevate sales and capture customer loyalty by providing custom food ordering apps, complete with loyalty perks"},
            { punchImage: "/images/landing/community-punch.png", headline: "Community Centers", description: "Automate facility operations and drive community engagement with custom apps and services"},
            { punchImage: "/images/landing/museum-punch.png", headline: "Museums and Galleries", description: "Build self-guided audio guides and immersive experiences at a fraction of speed and cost"},
            { punchImage: "/images/landing/cities-punch.png", headline: "Cities and POIs", description: "Elevate visitor and tourist experiences with information kiosks and tour guide apps"},
            { punchImage: "/images/landing/coworking-punch.png", headline: "Co-working Spaces", description: "Build your own custom membership apps and services and drive higher membership engagement"},
            { punchImage: "/images/landing/hubs-punch.png", headline: "Malls and Hubs", description: "Build information kiosks and indoor navigation features without a need for hefty development cost"},
        ];

        const renderUserGroups = (item: UserGroupProps): React.ReactNode => {
            return <div className="carousel-showcase">
                <div className="carousel-showcase-container">
                    <div className="punch-image" style={{backgroundImage: "url('" + item.punchImage + "')"}}>
                    </div>
                    <br />
                    <br />
                    <br />
                    <Header as="h4">
                        {item.headline}
                        <br />
                        <Header.Subheader className="subheader_more_margin_top">
                            <br />
                            {item.description}
                        </Header.Subheader>
                    </Header>
                    <br />
                </div>
            </div>;
        };

        return <Container style={{width: "100%"}}>
            <Grid stackable>
                <Grid.Row style={{ minHeight: 30 }} className="topRow"></Grid.Row>
                <Grid.Row columns={4} className="topRow">
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={10}>
                        <Header textAlign="center" size="huge">
                            <div>
                                <Icon name="react"></Icon>
                                <Icon name="js"></Icon>
                                <Icon name="apple"></Icon>
                                <Icon name="android"></Icon>
                            </div>
                            <span className="punchLine">
                                Add powerful features to your app in seconds.
                            </span>
                            <Header.Subheader className="punchLineSubHeader">
                                Serverless React.JS / React Native / Next.JS / Swift / Flutter components that works out-of-the-box with 2 lines of code or more.
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 50 }} className="topRow" textAlign="center">
                    <Grid.Column width={16} textAlign="center">
                        <a href="https://airjam-demo.netlify.app/">
                            <Button animated="vertical" style={{ marginTop: 15, marginBottom: 20, borderRadius: 30, padding: "15px 20px 15px 20px" }} color="black">
                                <Button.Content visible>Tell me how</Button.Content>
                                <Button.Content hidden>
                                    <Icon name="question" />
                                </Button.Content>
                            </Button>
                        </a>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="topRow punchImage">
                        <img src="/images/punch.png" alt="" />
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>

                <Grid.Row columns={4}>
                    <Grid.Column width={4}></Grid.Column>
                    <Grid.Column width={8} verticalAlign="middle" textAlign="center">
                        <div className="header">
                            <i className={"fas fa-2x fa-clock"}></i>
                            <br />
                            <br />
                        </div>
                        <div className="rotating-description">
                            <span className="not-rotating">Build apps and services with features similar to </span>
                                <span className={this.state.rotateIdx === 0 ? "emphasis" : ""} > Airbnb </span>/
                                <span className={this.state.rotateIdx === 1 ? "emphasis" : ""} > Lime </span>/
                                <span className={this.state.rotateIdx === 2 ? "emphasis" : ""} > Starbucks </span>/
                                <span className={this.state.rotateIdx === 3 ? "emphasis" : ""} > Bonvoy </span>/
                                <span className={this.state.rotateIdx === 4 ? "emphasis" : ""} > TikTok </span>/
                                <span className={this.state.rotateIdx === 5 ? "emphasis" : ""} > Instagram </span>/
                                <span className={this.state.rotateIdx === 6 ? "emphasis" : ""} > WhatsApp </span>/
                                <span className={this.state.rotateIdx === 7 ? "emphasis" : ""} > ResortPass </span>/
                                <span className={this.state.rotateIdx === 8 ? "emphasis" : ""} > OfferUp </span>/
                                <span className={this.state.rotateIdx === 9 ? "emphasis" : ""} > Pinterest </span>/
                                <span className={this.state.rotateIdx === 10 ? "emphasis" : ""} > Redfin </span>/
                                <span className={this.state.rotateIdx === 11 ? "emphasis" : ""} > Turo </span>/
                                <span className={this.state.rotateIdx === 12 ? "emphasis" : ""} > Eater </span>/
                                <span className={this.state.rotateIdx === 13 ? "emphasis" : ""} > AllTrails </span>/
                                <span className={this.state.rotateIdx === 14 ? "emphasis" : ""} > TripAdvisor </span>/
                                <span className={this.state.rotateIdx === 15 ? "emphasis" : ""} > Viator </span>/
                                <span className={this.state.rotateIdx === 16 ? "emphasis" : ""} > Eventbrite </span>/
                                <span className={this.state.rotateIdx === 17 ? "emphasis" : ""} > Yelp </span>/
                                <span className={this.state.rotateIdx === 18 ? "emphasis" : ""} > GasBuddy </span>/
                                <span className={this.state.rotateIdx === 19 ? "emphasis" : ""} > OpenTable </span>
                            <span className="not-rotating"> in minutes!</span>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={4}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>


                <Grid.Row>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={12}>
                        <Carousel value={userGroups} numVisible={3} numScroll={3} circular itemTemplate={renderUserGroups} showNavigators={false} autoplayInterval={3500} />
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>


                <Grid.Row columns={4}>
                    <Grid.Column width={4}></Grid.Column>
                    <Grid.Column width={8} verticalAlign="middle" textAlign="center">
                        <img src="/images/fubu.png" width="360" alt="" />
                        <Header as="h1" textAlign="center" size="large" style={{ width: "100%" }}>
                            Turn frontend developers into superheroes
                            <Header.Subheader className="subheader_more_margin_top">
                                <br />
                                AirJam's components come feature-complete with servers, so you can launch apps with frontend code alone.
                                Use languages you are already familiar with, such as React, Next.JS, Flutter, or Swift, and add features with 2-3 lines of code.
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={4}></Grid.Column>
                </Grid.Row>


                <Grid.Row style={{ minHeight: 150 }}></Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <Header as="h3" textAlign="center" size="large" style={{ width: "100%" }}>
                            With just 2 lines of code or more, you can make
                        </Header>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={3}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={12}>
                        <Grid stackable>


                            <Grid.Row columns={2}>
                                <Grid.Column width={8} verticalAlign="top">
                                    <Grid doubling>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className="fas fa-search-dollar"></i>
                                                    <br />
                                                    <strong>P2P Marketplaces</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Build apps similar to Airbnb, OfferUp, Turo, and Bird in minutes
                                                </span>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className="fas fa-shopping-cart"></i>
                                                    <br />
                                                    <strong>Local Marketplaces</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Add retail features to your apps and sell items from your apps in minutes!
                                                </span>
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column width={8} verticalAlign="top">
                                    <Grid doubling>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className="fas fa-map-pin"></i>
                                                    <br />
                                                    <strong>Hyper-local Discovery</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Add indoor / outdoor navigation and discovery to your apps
                                                </span>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className="fas fa-users"></i>
                                                    <br />
                                                    <strong>Social Networking</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Build social networking apps and services, like TikTok and instagram, in minutes!
                                                </span>
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>



                            <Grid.Row columns={2}>
                                <Grid.Column width={8} verticalAlign="top">
                                    <Grid doubling>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className="fas fa-tram"></i>
                                                    <br />
                                                    <strong>Transportation Apps</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Add features to let your users book vehicles and rides to your app
                                                </span>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className="fas fa-shopping-cart"></i>
                                                    <br />
                                                    <strong>In-App Retail</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Add retail features to your apps and sell items from your apps in minutes!
                                                </span>
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column width={8} verticalAlign="top">
                                    <Grid doubling>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className="fas fa-hotel"></i>
                                                    <br />
                                                    <strong>Hotel Reservation Apps</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Add hotel-style search and book features to any apps or web services
                                                </span>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className="fa-solid fa-sign-hanging"></i>
                                                    <br />
                                                    <strong>Real estate / Management Apps</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Build custom apps for real estate and property management
                                                </span>
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>


                            <Grid.Row columns={2}>
                                <Grid.Column width={8} verticalAlign="top">
                                    <Grid doubling>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className={"fas fa-xl fa-map"}></i>
                                                    <br />
                                                    <strong>Store Locator</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Show and update multiple locations on maps using your own Google spreadsheet document
                                                </span>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className={"fas fa-calendar-alt"}></i>
                                                    <br />
                                                    <strong>Events Calendar</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Show community or festive events as a calendar synced with your Google Calendar                            </span>
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column width={8} verticalAlign="top">
                                    <Grid doubling>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className={"fas fa-business-time"}></i>
                                                    <br />
                                                    <strong>Meeting Booking System</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Build interfaces to book rooms, people, or any resources and sync with your calendar for availabilities
                                                </span>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className={"fas fa-ticket"}></i>
                                                    <br />
                                                    <strong>Event Ticketing</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Create and sell tickets for your events and manage admissions with QR codes
                                                </span>
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} verticalAlign="top">
                                    <Grid doubling>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className={"fas fa-utensils"}></i>
                                                    <br />
                                                    <strong>Restaurant Menu</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Publish menus on your website or on an app and 86 a dish or update prices instantly using Google spreadsheet
                                                </span>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className={"fas fa-question"}></i>
                                                    <br />
                                                    <strong>FAQs</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Manage and update FAQs on your web pages and apps real time using Google spreadsheet
                                                </span>
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column width={8} verticalAlign="top">
                                    <Grid doubling>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className={"fas fa-hamburger"}></i>
                                                    <br />
                                                    <strong>Food Ordering Apps</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Build food ordering apps for cafes and restaurants
                                                </span>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className={"fas fa-headset"}></i>
                                                    <br />
                                                    <strong>Museum Audio Guides</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Build audio guides with nothing but Google spreadsheets! AirJam supports BLE and QR code scan modes, too
                                                </span>
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} verticalAlign="top">
                                    <Grid doubling>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className="fas fa-medkit"></i>
                                                    <br />
                                                    <strong>Healthcare Apps</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Build HIPAA-compliant patient scheduling, management, and communication apps
                                                </span>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <i className={"fas fa-sign"}></i>
                                                    <br />
                                                    <strong>Digital Signage</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Build digital menu displays and signage for storefronts and manage it using Google spreadsheet in real time
                                                </span>
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column width={8} verticalAlign="top">
                                    <Grid doubling>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <Icon name="cloud upload"></Icon>
                                                    <br />
                                                    <strong>File Uploader</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Add file upload capabilities to any apps or web services within minutes
                                                </span>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <Icon name="tablet alternate"></Icon>
                                                    <br />
                                                    <strong>Information Kiosk Apps</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    Build kiosks for public spaces and for informational stations in minutes with AirJam components
                                                </span>
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} verticalAlign="top">
                                    <Grid doubling>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <Icon name="react"></Icon>
                                                    <br />
                                                    <strong>React / React Native Apps</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    AirJam components can be added as React or React Native projects and can be extended to build your own component
                                                </span>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <Icon name="apple"></Icon>
                                                    <br />
                                                    <strong>iPhone / iPad Apps</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    AirJam components can be added to iOS projects using languages such as React Native or Flutter
                                                </span>
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column width={8} verticalAlign="top">
                                    <Grid doubling>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <Icon name="android"></Icon>
                                                    <br />
                                                    <strong>Android Apps</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    AirJam components can be published as Android Apps using languages such as React Native or Flutter
                                                </span>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={8} verticalAlign="top" textAlign="left">
                                            <div className="landingDescBubble">
                                                <div className="header">
                                                    <Icon name="globe"></Icon>
                                                    <br />
                                                    <strong>Web Applications</strong>
                                                <br />
                                                </div>
                                                <span className="description">
                                                    AirJam components can be added to any websites or website builders that support either Javascript or React
                                                </span>
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>

                <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <Header as="h3" textAlign="center" size="medium" style={{ width: "100%" }}>
                            and more!
                        </Header>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ minHeight: 150 }}></Grid.Row>

                <Grid.Row columns={4}>
                    <Grid.Column width={4}></Grid.Column>
                    <Grid.Column width={8} verticalAlign="middle" textAlign="center">
                        <div className="header">
                            <i className="fas fa-2x fa-code"></i>
                            <br />
                            <br />
                        </div>
                        <div className="rotating-description">
                            <span className="not-rotating">Add and customize features such as </span>
                                <span className={this.state.rotateIdx2 === 0 ? "emphasis" : ""} > chats </span>/
                                <span className={this.state.rotateIdx2 === 1 ? "emphasis" : ""} > direct messaging </span>/
                                <span className={this.state.rotateIdx2 === 2 ? "emphasis" : ""} > comments </span>/
                                <span className={this.state.rotateIdx2 === 3 ? "emphasis" : ""} > video conferencing </span>/
                                <span className={this.state.rotateIdx2 === 4 ? "emphasis" : ""} > reviews </span>/
                                <span className={this.state.rotateIdx2 === 5 ? "emphasis" : ""} > events calendar </span>/
                                <span className={this.state.rotateIdx2 === 6 ? "emphasis" : ""} > custom booking and ticketing </span>/
                                <span className={this.state.rotateIdx2 === 7 ? "emphasis" : ""} > store locator </span>/
                                <span className={this.state.rotateIdx2 === 8 ? "emphasis" : ""} > digital menus </span>/
                                <span className={this.state.rotateIdx2 === 9 ? "emphasis" : ""} > digital signage </span>/
                                <span className={this.state.rotateIdx2 === 10 ? "emphasis" : ""} > digital kiosks </span>/
                                <span className={this.state.rotateIdx2 === 11 ? "emphasis" : ""} > audio tour guides </span>
                             using languages such as <span className="not-rotating"> Javascript, Typescript, React, React native, Next.js, Flutter, and Swift, all without writing any server code!</span>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={4}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>


                <Grid.Row columns={4}>
                    <Grid.Column width={4}></Grid.Column>
                    <Grid.Column width={8} verticalAlign="middle" textAlign="center">
                        <img src="/images/thunder.png" width="40" alt="" />
                        <Header as="h1" textAlign="center" size="large" style={{ width: "100%" }}>
                            Idea to product in hours, not months
                            <Header.Subheader className="subheader_more_margin_top">
                                <br />
                                AirJam provides profoundly different breed of low-code solutions that shave up-to 90% of time and cost in developing apps and software tools. Build products at record speed with AirJam.
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={4}></Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ minHeight: 250 }} className="landingBottom">
                    <Grid.Column width={4}></Grid.Column>
                    <Grid.Column width={12} verticalAlign="bottom" textAlign="right">
                        <img src="/images/bottom-thing.png" width="340" alt="" style={{ position: "absolute", bottom: 0, right: "calc(50% - 340px)"}} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 20 }}>
                </Grid.Row>
            </Grid>
        </Container>;
    }
}

export default connectAllProps(Home);