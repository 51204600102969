import React from "react";
import { Header, Container } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
interface Props {}

interface States {}
export default class GoogleConsent extends React.Component<Props, States> {
    render() {
        return <Container text style={CONTAINER_STYLE}>

            <Header size="medium">Google API Disclosure for AirJam</Header>

            AirJam uses Google APIs when you use your Google account to sign in and use AirJam apps and services.<br />
            <p></p>
            AirJam's use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data Policy</a>, including the Limited Use requirements.<br />
            <p></p>
            The AirJam app does not transfer information received from Google APIs to any other app, except when user explicitly uses the "share" functionality on Android or iOS to download or share an attachment.<br />
            <p></p>
            You can also see our privacy policy <a href="/privacy">here</a>.<br />
            <p></p>
            If you have any questions, clarifications or concerns, please contact us at <a href="mailto:contact@airjam.co">contact@airjam.co</a>.

        </Container>;
    }
}