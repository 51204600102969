import { Uploader } from "../../../models/Uploader";
import fetch from "../../../shared/fetch";
import { Form, Button, FormGroup, Dropdown, Grid, Popup, Icon, Radio } from "semantic-ui-react";
import { RefObject } from "react";
import React from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import connectAllProps from "../../../shared/connect";
import { DEFAULT_PREFERENCES } from "../../../shared/preferences";
import { PrimitiveType } from "intl-messageformat";
import WarningModal from "../shared/WarningModal";
import { ComponentProps } from "../../../shared/ComponentProps";
import authProviderType from "../components/AuthProviderTypes";
import compressionOptionType from "../components/CompressionOptionTypes";
import { ComponentTemplate } from "@airjam/types";

interface Props extends ComponentProps {
    uploader?: Uploader;
    submitTextId: string;
    onSubmit: (uploader: Uploader) => void;
    loading?: boolean;
}

interface States {
    editing: boolean;
    openClearEditWarning: boolean;
    templates: {[id: string]: ComponentTemplate};
    authBypass: boolean;
}

const authBypassStubPlaceholder = `{ "id": "12", "name": "John Appleseed", "email" : "john@appleseed.com", "emailVerified" : true, "provider" : "NONE" }`;

class UploaderEditor extends React.Component<Props, States> {
    private _isMounted: boolean = false;
    private nameRef: RefObject<HTMLInputElement>;
    private fileCountPerUploadRef: RefObject<HTMLInputElement>;
    private uploadLimitKbRef: RefObject<HTMLInputElement>;
    // private storageProviderRef: RefObject<HTMLInputElement>;
    // private storageProviderTokenRef: RefObject<HTMLInputElement>;
    private authProviderRef: RefObject<HTMLInputElement>;
    private compressionOptionRef: RefObject<HTMLInputElement>;
    private authConfigRef: RefObject<HTMLInputElement>;
    // private readPermissionRef: RefObject<HTMLInputElement>;
    private authCustomEndpointRef: RefObject<HTMLInputElement>;
    private authBypassStubRef: RefObject<HTMLTextAreaElement>;
    private templateId?: string;
    private templateVersion?: number;
    // private styleIdRef: RefObject<HTMLInputElement>;
    // private styleVersionRef: RefObject<HTMLInputElement>;
    // private cssOverrideRef: RefObject<HTMLInputElement>;

    private originalName: string = "";
    private originalFileCountPerUpload: string = "";
    private originalUploadLimitKb: string = "";
    private originalAuthProvider: string = "";
    private originalCompressionOption: string = "";
    private originalAuthConfig: string = "";
    private originalAuthCustomEndpoint: string = "";
    private originalAuthBypass: boolean = false;
    private originalAuthBypassStub: string = "";
    private originalTemplateId: string = "";
    private originalTemplateVersion: number = 0;
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.nameRef = React.createRef();
        this.fileCountPerUploadRef = React.createRef();
        this.uploadLimitKbRef = React.createRef();
        this.authProviderRef = React.createRef();
        this.compressionOptionRef = React.createRef();
        this.authConfigRef = React.createRef();
        this.authCustomEndpointRef = React.createRef();
        this.authBypassStubRef = React.createRef();
        this.getString = this.props.intl.formatMessage;
        this.state = {
            editing: false,
            openClearEditWarning: false,
            templates: {},
            authBypass: this.props.uploader ? this.props.uploader.authBypass : false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getTemplates();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render(): React.ReactElement<any> {
        const getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string
            = this.props.intl.formatMessage;
        if (this.props.uploader) {
            this.originalName = this.props.uploader.name;
            this.originalFileCountPerUpload = String(this.props.uploader.fileCountPerUpload);
            this.originalUploadLimitKb = String(this.props.uploader.uploadLimitKb);
            this.originalAuthProvider = this.props.uploader.authProvider;
            this.originalCompressionOption = this.props.uploader.compressionOption;
            this.originalAuthConfig = this.props.uploader.authConfig;
            this.originalAuthCustomEndpoint = this.props.uploader.authCustomEndpoint;
            this.originalAuthBypass = this.props.uploader.authBypass;
            this.originalAuthBypassStub = this.props.uploader.authBypassStub;
            this.originalTemplateId = this.props.uploader.templateId;
            this.originalTemplateVersion = this.props.uploader.templateVersion;
        }
        let editorType: string;
        if (this.props.state.userState.currentUser &&
            this.props.state.userState.currentUser.preferences &&
            this.props.state.userState.currentUser.preferences.editorType) {
            editorType = this.props.state.userState.currentUser.preferences.editorType;
        } else {
            editorType = DEFAULT_PREFERENCES.editorType;
        }
        return (
            <Form>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            <FormattedMessage id="uploader.name" />&nbsp;
                            <Popup content="Please provide a distinct and easily identifiable name for your storage bucket" />
                        </span>
                        <label className="section label subtext">
                            Name of the upload bucket
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <input ref={this.nameRef} autoFocus={true}
                            defaultValue={this.originalName}
                            onChange={this.onEditing}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            <FormattedMessage id="uploader.file_count" />&nbsp;
                            <Popup content="Please specify how many files the uploader component will allow for each upload session" trigger={<Icon name="question circle" size="small" />} />
                        </span>
                        <label className="section label subtext">
                            Maximum number of files allowed for each upload
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <input ref={this.fileCountPerUploadRef} autoFocus={true}
                            pattern="[0-9]*"
                            defaultValue={this.originalFileCountPerUpload}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }}
                            onChange={this.onEditing}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            <FormattedMessage id="uploader.general_compress" />&nbsp;
                            <Popup content="Check if you'd like to auto-compress all files being uploaded" trigger={<Icon name="question circle" size="small" />} />
                        </span>
                        <label className="section label subtext">
                            If selected, uploader will compress all files being uploaded
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <Dropdown
                            fluid
                            selection
                            defaultValue={this.originalCompressionOption}
                            onChange={(e: any, value: any) => {
                                if (this.compressionOptionRef.current) {
                                    console.log(this.compressionOptionRef.current.value);
                                    console.log(value.value);
                                    this.compressionOptionRef.current.value = value.value;
                                }
                                this.onEditing();
                            }}
                            options={compressionOptionType(this.props)}
                        />
                        <input style={{ display: "none" }} ref={this.compressionOptionRef}
                            defaultValue={this.originalCompressionOption}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            <FormattedMessage id="uploader.upload_limit" />&nbsp;
                            <Popup content="Please specify a file size limit per each file upload, in Kilobytes. Defaults to 10MB if unspecified." trigger={<Icon name="question circle" size="small" />} />
                        </span>
                        <label className="section label subtext">
                            Size limit for each file, in Kilobytes (KBs)
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <input ref={this.uploadLimitKbRef} autoFocus={true}
                            defaultValue={this.originalUploadLimitKb}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }}
                            onChange={this.onEditing}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            <FormattedMessage id="uploader.auth_provider" />&nbsp;
                            <Popup content="Please specify how uploader component authenticate uploads from the server side. Please select [No Authentication] from the dropdown list if you'd like AirJam to bypass the authentication process" trigger={<Icon name="question circle" size="small" />} />
                        </span>
                        <label className="section label subtext">
                            Choose how you'd like to authenticate your users for uploads
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <Dropdown
                            fluid
                            selection
                            defaultValue={this.originalAuthProvider}
                            onChange={(e: any, value: any) => {
                                console.log(value.value);
                                if (this.authProviderRef.current) {
                                    console.log(this.authProviderRef.current.value);
                                    this.authProviderRef.current.value = value.value;
                                }
                                this.onEditing();
                            }}
                            options={authProviderType(this.props)}
                        />
                        <input style={{ display: "none" }} ref={this.authProviderRef}
                            defaultValue={this.originalAuthProvider} />
                        { this.renderAuthenticationString() }
                    </Grid.Column>
                </Grid.Row>
            <Grid.Row>
                <Grid.Column width={5}>
                    <span className="section label">
                        Authentication Bypass&nbsp;
                        <Popup content="Authentication bypass allows the bucket to bypass the authentication settings and returns settings specified below. If settings are unspecified, the bypass will return a static authentication result." trigger={<Icon name="lock open" size="small" />} />
                    </span>
                    <label className="section label subtext">
                        If enabled, AirJam will bypass authentication checks and return the payload below.
                    </label>
                </Grid.Column>
                <Grid.Column width={11}>
                    Enable authentication bypass: <Radio toggle checked={this.state.authBypass}
                        onChange={(e, data) => this.setState({ authBypass: data.checked === true })} />
                    <br /><br />
                    Stubbed payload to return to authentication calls. If left empty, a default static payload will be returned. <br />
                    <textarea ref={this.authBypassStubRef} placeholder={authBypassStubPlaceholder} defaultValue={this.originalAuthBypassStub} onChange={this.onEditing} />
                </Grid.Column>
            </Grid.Row>
                {/* <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            Template&nbsp;
                            <Popup content="Please choose a display template for your component." trigger={<Icon name="question circle" size="small" />} />
                        </span>
                        <label className="section label subtext">
                            Choose a display template for your component
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <TemplateSelect componentId={"template-select"} templates={this.state.templates} selectedViewType={"Spotlight"}
                            onChange={(componentId: string, newTemplateKey: string, newTemplateVersion: number) => {
                                this.templateId = newTemplateKey;
                                this.templateVersion = newTemplateVersion;
                                this.onEditing();
                        }}></TemplateSelect>
                    </Grid.Column>
                </Grid.Row> */}
                <Grid.Row>
                    <Grid.Column width={5}></Grid.Column>
                    <Grid.Column width={11}>
                        <FormGroup inline>
                            <Form.Field control={Button} onClick={this.onSubmit} color="orange"
                                loading={this.props.loading}
                                disabled={this.props.loading || !this.state.editing}>
                                <FormattedMessage id={this.props.submitTextId} />
                            </Form.Field>
                            <Form.Field control={Button} onClick={() => this.setState({openClearEditWarning: true})}
                                loading={this.props.loading}
                                disabled={this.props.loading || !this.state.editing}>
                                <FormattedMessage id="component.button.clear_edit" />
                            </Form.Field>
                        </FormGroup>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
                {this.renderClearEditWarningModal()}
            </Form>
        );
    }

    private renderAuthenticationString = (): React.ReactElement<any> => {
        let className: string = "";
        let sectionHeader: any = "";
        let sectionDesc: any = "";
        if (!this.authProviderRef || !this.authProviderRef.current || this.authProviderRef.current.value === "NONE")
            className = "hideImportant";

        if (this.authProviderRef.current && this.authProviderRef.current.value === "FIREBASE") {
            sectionHeader = "Firebase service account key";
            sectionDesc = <span>For Firebase, copy the contents of service account JSON file in the box below. Please make sure your users are already logged in to Firebase using the same service account, and pass their authentication key to the component to verify their authentication. If you don't have a service account file, please refer to <a href="https://firebase.google.com/docs/admin/setup#:~:text=To%20generate%20a%20private%20key,confirm%20by%20clicking%20Generate%20Key.">this document</a> on creating a new service file.</span>;
        }
        if (this.authProviderRef.current && this.authProviderRef.current.value === "CLERK") {
            sectionHeader = "Clerk secret key";
            sectionDesc = <span>For Clerk, paste the secret key in the box below. You can find your secret key from <a href="https://dashboard.clerk.dev/last-active?path=api-keys">API keys</a>. Please make sure your users are already logged in through Clerk using the corresponding public publishable key, and pass their client token and session id to the uploader component to verify their authentication.</span>;
        }
        if (this.authProviderRef.current && this.authProviderRef.current.value === "CUSTOM") {
            sectionHeader = "Custom authentication payload";
            sectionDesc = <span>Please provide secret payload your custom authenticator accepts for its authentication flow below.</span>;
        }
        return <div className={className}>
            <br />
            <label className="section label">
                {sectionHeader}
            </label>
            <label className="section label subtext">
                {sectionDesc}
            </label>
            <br />
            <input
                ref={this.authConfigRef}
                defaultValue={this.originalAuthConfig}
                onChange={this.onEditing} />
            {this.authProviderRef.current && this.authProviderRef.current.value === "CUSTOM" ?
                <span><br /><br />Please provide the REST endpoint to validate authentications with. The endpoint must return HTTP code 200 for successful authentication with permission settings as described in our API documentation.</span> : ""}
            <input
                ref={this.authCustomEndpointRef}
                defaultValue={this.originalAuthCustomEndpoint}
                onChange={this.onEditing} />
        </div>;
    }

    private onSubmit = (): void => {
        const newUploader: Uploader = this.props.uploader || {} as Uploader;
        const authBypassStub: string | null = this.authBypassStubRef.current && this.authBypassStubRef.current.value;
        newUploader.name = this.nameRef.current ? this.nameRef.current.value : this.originalName;
        newUploader.fileCountPerUpload = this.fileCountPerUploadRef.current ? Number(this.fileCountPerUploadRef.current.value) : Number(this.originalFileCountPerUpload);
        newUploader.uploadLimitKb = this.uploadLimitKbRef.current ? Number(this.uploadLimitKbRef.current.value) : Number(this.originalUploadLimitKb);
        newUploader.compressionOption = this.compressionOptionRef.current ? this.compressionOptionRef.current.value : this.originalCompressionOption;
        newUploader.authProvider = this.authProviderRef.current ? this.authProviderRef.current.value : this.originalAuthProvider;
        newUploader.authConfig = this.authConfigRef.current ? this.authConfigRef.current.value : this.originalAuthConfig;
        newUploader.authCustomEndpoint = this.authCustomEndpointRef.current ? this.authCustomEndpointRef.current.value : this.originalAuthCustomEndpoint;
        newUploader.templateId = this.templateId ? this.templateId : this.originalTemplateId;
        newUploader.templateVersion = this.templateVersion ? this.templateVersion : this.originalTemplateVersion;
        newUploader.authBypass = this.state.authBypass;
        newUploader.authBypassStub = authBypassStub ? authBypassStub : "";
        this.props.onSubmit(newUploader);
    }

    private onEditing = () => {
        if (!this.nameRef.current ||
            !this.fileCountPerUploadRef.current ||
            !this.uploadLimitKbRef.current ||
            !this.authProviderRef.current ||
            !this.compressionOptionRef.current ||
            !this.authConfigRef.current ||
            !this.authCustomEndpointRef.current) {
            return;
        }
        const instanceName: string = this.nameRef.current.value;
        const instanceFileCountPerUpload: string = this.fileCountPerUploadRef.current.value;
        const instanceUploadLimitKb: string = this.uploadLimitKbRef.current.value;
        const instanceCompressionOption: string = this.compressionOptionRef.current.value;
        const instanceAuthProvider: string = this.authProviderRef.current.value;
        const instanceAuthConfig: string = this.authConfigRef.current.value;
        const instanceAuthCustomEndpoint: string = this.authCustomEndpointRef.current.value;
        const instanceAuthBypassStub: string = this.authBypassStubRef.current ? this.authBypassStubRef.current.value : this.originalAuthBypassStub;
        if (this.originalName === instanceName &&
            this.originalFileCountPerUpload === instanceFileCountPerUpload &&
            this.originalUploadLimitKb === instanceUploadLimitKb &&
            this.originalAuthProvider === instanceAuthProvider &&
            this.originalCompressionOption === instanceCompressionOption &&
            this.originalAuthConfig === instanceAuthConfig &&
            this.originalAuthCustomEndpoint === instanceAuthCustomEndpoint &&
            this.originalAuthBypass === this.state.authBypass &&
            this.originalAuthBypassStub === instanceAuthBypassStub &&
            (!this.templateId || this.originalTemplateId === this.templateId) &&
            (!this.templateVersion || this.originalTemplateVersion === this.templateVersion)) {
            this.setState({
                editing: false
            });
        } else {
            this.setState({
                editing: true
            });
        }
    }
    private clearEditing = () => {
        if (this.nameRef.current) {
            this.nameRef.current.value = this.originalName;
        }
        if (this.fileCountPerUploadRef.current) {
            this.fileCountPerUploadRef.current.value = this.originalFileCountPerUpload;
        }
        if (this.uploadLimitKbRef.current) {
            this.uploadLimitKbRef.current.value = this.originalUploadLimitKb;
        }
        if (this.authProviderRef.current) {
            this.authProviderRef.current.value = this.originalAuthProvider;
        }
        if (this.compressionOptionRef.current) {
            this.compressionOptionRef.current.value = this.originalCompressionOption;
        }
        if (this.authConfigRef.current) {
            this.authConfigRef.current.value = this.originalAuthConfig;
        }
        if (this.authCustomEndpointRef.current) {
            this.authCustomEndpointRef.current.value = this.originalAuthCustomEndpoint;
        }
        if (this.authBypassStubRef.current) {
            this.authBypassStubRef.current.value = this.originalAuthBypassStub;
        }
        if (this.templateVersion) {
            this.templateVersion = this.originalTemplateVersion;
        }
        if (this.templateId) {
            this.templateId = this.originalTemplateId;
        }
        this.setState({
            editing: false,
            authBypass: this.originalAuthBypass,
            openClearEditWarning: false
        });
    }
    private renderClearEditWarningModal = (): React.ReactElement<any> | undefined => {
        return <WarningModal
                descriptionIcon="close" open={this.state.openClearEditWarning}
                descriptionText={this.getString({id: "page.uploader.clear_edit"})}
                warningText={this.getString({id: "page.uploader.clear_edit_confirmation"})}
                onConfirm={this.clearEditing}
                onCancel={() => {this.setState({openClearEditWarning: false}); }}/>;
    }

    private getTemplates() {
        fetch("/api/uploader/templates", {}, "GET", true).then((result: any) => {
            if (this._isMounted && result && result.templates) {
                const templates: {[id: string]: ComponentTemplate} = {};
                result.templates.forEach((template: ComponentTemplate) => {
                    templates[template.shortId] = template;
                });
                this.setState({
                    templates: templates
                });
            }
        }).catch((error: Error) => {
            console.log(error);
        });
    }
}

export default connectAllProps(UploaderEditor);