import React, { Fragment } from "react";
import connectAllProps from "../../../shared/connect";
import { Link, Redirect } from "react-router-dom";
import { pendingRedirect } from "../../../shared/redirect";
import { Calendar } from "../../../models/Calendar";
import ErrorPage from "../../pages/ErrorPage";
import { Container, Header, Icon, Label, Tab } from "semantic-ui-react";
import "react-tiny-fab/dist/styles.css";
import { MessageDescriptor, FormattedMessage } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import WarningModal from "../shared/WarningModal";
import UserLabel from "../user/UserLabel";
import FabAction from "../../../models/client/FabAction";
import moment from "moment";
import { ComponentProps as Props } from "../../../shared/ComponentProps";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ViewCountComponent from "./ViewCountComponent";
import CalendarEditor from "./CalendarEditor";
import { CallPage, PageActionType } from "../../../models/CallType";
import { getToast as toast } from "../../../shared/toast";

interface States {
    openDeleteWarning: boolean;
}

class CalendarDetail extends React.Component<Props, States> {
    private calendarId: string = "";
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.calendarId = this.props.match && this.props.match.params && this.props.match.params.calendarId;
        this.getString = this.props.intl.formatMessage;
        this.state = {
            openDeleteWarning: false
        };
    }
    componentDidMount() {
        if (this.calendarId) {
            this.addFabActions();
        }
        this.props.actions.resetRedirectTask();
        window.scrollTo(0, 0);
    }
    componentDidUpdate(prevProps: Props) {
        if ((prevProps.state.calendarState.loading
            && !this.props.state.calendarState.loading) ||
            (!prevProps.state.userState.currentUser
            && this.props.state.userState.currentUser)) {
            this.addFabActions();
        }
    }
    componentWillUnmount() {
        this.props.actions.setFabActions([]);
    }
    render(): React.ReactElement<any> {
        if (pendingRedirect(this.props)) {
            return <Redirect to={this.props.state.redirectTask.to} />;
        }
        const notFoundError: Error = {
            name: "404 Not Found",
            message: `not found for ${window.location.href} `
        };
        if (!this.calendarId) {
            return <ErrorPage error={notFoundError} />;
        }
        const calendar: Calendar | undefined = this.props.state.calendarState.data.find(
            (value: Calendar): boolean => value._id === this.calendarId
        );
        if (!calendar) {
            return <ErrorPage error={notFoundError} />;
        }
        const createDate: Date = calendar.createdAt ? new Date(calendar.createdAt) : new Date(0);
        const labelStyle: any = {
            color: "grey",
            marginTop: 2,
            marginBottom: 2
        };
        const panes = [
            { menuItem: "Settings", render: () => <Tab.Pane>
                { this.renderSettingsPane(calendar) }
            </Tab.Pane> },
            { menuItem: "Monitoring", render: () => <Tab.Pane>
                <ViewCountComponent pageType={CallPage.Calendar} title="Views" dataLabel="views" actionType={PageActionType.ViewCount} key={this.calendarId + "-viewcount"} showReload={true} id={calendar.calendarComponentId} ></ViewCountComponent>
            </Tab.Pane> },
            { menuItem: "Set-up / Installation", render: () => <Tab.Pane>
                <Header as="h3" id="setup">Setup</Header>
                <Tab panes={[
                    {menuItem: "npm", render: () => <Tab.Pane>
                        <SyntaxHighlighter language="javascript" style={vs2015}>
                            { "npm install @airjam/react-uploader@latest" }
                        </SyntaxHighlighter>
                    </Tab.Pane>},
                    {menuItem: "yarn", render: () => <Tab.Pane>
                        <SyntaxHighlighter language="javascript" style={vs2015}>
                            { "yarn add @airjam/react-uploader@latest" }
                        </SyntaxHighlighter>
                    </Tab.Pane>}
                ]} menu={{ inverted: false, attached: true, tabular: true }} />

                <Header as="h3" id="usage">Usage</Header>
                Paste the code below to start using your calendar component.
                <SyntaxHighlighter language="javascript" style={vs2015}>
                    {  "import { Calendar } from '@airjam/react-calendar';\n...\n" +
                    '<Calendar id="' + calendar.calendarComponentId + '"/>' }
                </SyntaxHighlighter>
                <Header as="h4">Custom styling</Header>
                If you want to use default styling to build upon it, you can import default styles by using:

                <SyntaxHighlighter language="javascript" style={vs2015}>
                    import '@airjam/react-calendar/dist/style.css';
                </SyntaxHighlighter>

                For additional configurations, please review the props and callbacks documented below.
                <Header as="h3" id="props">Props and Callbacks</Header>
                <ul>
                    <li><code>id</code> - <b>(REQUIRED)</b> id of the calendar component.</li>
                    <li><code>viewAs</code> - (Optional) default is <code>ViewType.List</code> Specifies how the calendar is displayed as. Type: <code>ViewType</code> Supported options: <code>[LIST | CalendarView | DayView | DayViewByLocation | DayList]</code></li>
                    <li><code>showDate</code> - (Optional) default is set to the current day. Use this prop to specify which day to show when the component loads. Type: <code>Date</code> </li>
                    <li><code>showEndDate</code> - (Optional) For <code>ViewType.LIST</code> views, you can also specify the end date to show for the component. Type: <code>Date</code> </li>
                    <li><code>location</code> - (Optional) If specified, the component will only return events that that match the location field. Please note, this is a case sensitive filter. Type: <code>string</code> </li>
                    <li><code>descriptionLength</code> - (Optional) Specifies the maximum length of event description text to display before the component trims the full text. Default is 30 characters. Type: <code>number</code> </li>
                    <li>
                        <code>renderEventFunc</code> (Optional) This is an event that gets fired when a user removes a file. Type: <code>func (event: Event, index: number) =&gt; React.JSX.Element</code>

                        <SyntaxHighlighter language="javascript" style={vs2015}>
                            { '<Calendar id="<your-bucket-id>" renderEventFunc={renderEvent} />\n...\n function renderEvent(e: Event, idx: number) {\n  return (<div>{e.title}{your_cool_feature(e.start,e.end)}</div>);\n });\n}\n' }
                        </SyntaxHighlighter>
                    </li>
                </ul>
            </Tab.Pane> },
        ];
        return (
            <Fragment>
                <div style={{padding: 20}} >
                    <Header size={"medium"}>
                        <Link to="/calendars"><Icon name="home" /></Link>
                        {calendar.name}
                    </Header>
                    <Label style={labelStyle} color="black">
                        <FormattedMessage id="post.created_at" />
                        {moment(createDate).fromNow()}
                    </Label>
                    <Label style={labelStyle} color="black">
                        id: { calendar.calendarComponentId } &nbsp; <Icon style={{cursor: "pointer"}} name="copy" onClick={() => {
                            navigator.clipboard.writeText(calendar.calendarComponentId);
                            toast().success("toast.text.copied");
                        }}/>
                    </Label>
                    <div>&nbsp; </div>
                    <Tab menu={{ inverted: false, attached: true, tabular: true }} panes={panes} />
                </div>
                {
                    this.renderDeleteWarningModal(calendar)
                }
            </Fragment>
        );
    }

    private renderSettingsPane = (calendar: Calendar): React.ReactElement<any> => {
        return <div>
            <CalendarEditor calendar={calendar}
                submitTextId="component.button.update"
                onSubmit={this.editCalendar}
                loading={this.props.state.uploaderState.loading} />
        </div>;
    }

    private editCalendar = (calendar: Calendar): void => {
        if (this.props.state.userState.currentUser) {
            this.props.actions.editCalendar(calendar);
        }
    }

    private isAuthorOf = (calendar: Calendar): boolean => {
        return calendar.ownerId === (
            this.props.state.userState.currentUser &&
            this.props.state.userState.currentUser._id);
    }
    private addFabActions = (): void => {
        const calendar: Calendar | undefined = this.props.state.calendarState.data.find(
            (value: Calendar): boolean => value._id === this.calendarId
        );
        if (!calendar) {
            return;
        }
        if (this.isAuthorOf(calendar)) {
            const actions: FabAction[] = [{
                text: this.getString({id: "component.button.delete"}),
                icon: "trash alternate",
                onClick: () => { this.setState({openDeleteWarning: true }); },
            }, {
                text: this.getString({id: "component.button.edit"}),
                icon: "edit",
                onClick: () => {
                    const target: string = this.props.match.url.replace(/^(.+)(\/[0-9a-z]+$)/, "$1/edit$2");
                    this.props.history.push(target, this.props.location.state);
                },
            }];
            this.props.actions.setFabActions(actions);
        }
    }
    private renderDeleteWarningModal = (calendar: Calendar): React.ReactElement<any> | undefined => {
        return this.isAuthorOf(calendar) ?
            <WarningModal
                descriptionIcon="delete" open={this.state.openDeleteWarning}
                descriptionText={this.getString({id: "page.calendar.delete"}, {title: calendar.name})}
                warningText={this.getString({id: "page.calendar.delete_confirmation"})}
                onConfirm={this.removeCalendar}
                onCancel={ () => {this.setState({openDeleteWarning: false}); }}/>
                : undefined;
    }
    private renderMetaInfo = (calendar: Calendar): React.ReactElement<any> => {
        const createDate: Date = calendar.createdAt ? new Date(calendar.createdAt) : new Date(0);
        const labelStyle: any = {
            color: "grey",
            marginTop: 2,
            marginBottom: 2
        };
        return <Fragment>
            <Container text>
                <UserLabel user={this.props.state.userDictionary[calendar.ownerId]} />
                <Label style={labelStyle}>
                    <FormattedMessage id="post.created_at" />
                    {moment(createDate).fromNow()}
                </Label>
            </Container>
        </Fragment>;
    }
    private removeCalendar = (): void => {
        this.props.actions.removeCalendar(this.calendarId);
    }
}

export default connectAllProps(CalendarDetail);