import { DropdownItemProps } from "semantic-ui-react";
import CompressionOptionType from "../../../models/CompressionOptionType";
import { ComponentProps as Props } from "../../../shared/ComponentProps";

export default function compressionOptionType(props: Props): DropdownItemProps[] {
    return Array.from(Object.keys(CompressionOptionType) as Array<keyof typeof CompressionOptionType>).map((key) => {
        return {
            key: key.toString(),
            text: props.intl.formatMessage({ id: "component.dropdown.compression_option_type." + CompressionOptionType[key].toString() }),
            value: CompressionOptionType[key].toString()
        } as DropdownItemProps;
    });
}
