import Project from "../../../models/Project";
import { Form, Button, FormGroup } from "semantic-ui-react";
import { RefObject } from "react";
import React from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import "codemirror/lib/codemirror.css";
import "tui-editor/dist/tui-editor.min.css";
import "tui-editor/dist/tui-editor-contents.min.css";
import "../../css/tui-editor-override.css";
import { Editor } from "@toast-ui/react-editor";
import connectAllProps from "../../../shared/connect";
import fetch from "../../../shared/fetch";
import { getToast as toast } from "../../../shared/toast";
import { DEFAULT_PREFERENCES } from "../../../shared/preferences";
import ResponsiveFormField from "../shared/ResponsiveFormField";
import { isMobile } from "../dimension";
import { PrimitiveType } from "intl-messageformat";
import WarningModal from "../shared/WarningModal";
import { ComponentProps } from "../../../shared/ComponentProps";

interface Props extends ComponentProps {
    project?: Project;
    submitTextId: string;
    onSubmit: (title: string, description: string) => void;
    loading?: boolean;
}

interface States {
    editing: boolean;
    openClearEditWarning: boolean;
}

class ProjectEditor extends React.Component<Props, States> {
    private titleRef: RefObject<HTMLInputElement>;
    private descriptionRef: RefObject<any>;

    private originalTitle: string = "";
    private originalDescription: string = "";
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.titleRef = React.createRef();
        this.descriptionRef = React.createRef();
        this.getString = this.props.intl.formatMessage;
        this.state = {
            editing: false,
            openClearEditWarning: false
        };
    }
    componentDidMount() {
    }
    render(): React.ReactElement<any> {
        if (this.props.project) {
            this.originalTitle = this.props.project.title;
            this.originalDescription = this.props.project.description;
        }
        let editorType: string;
        if (this.props.state.userState.currentUser &&
            this.props.state.userState.currentUser.preferences &&
            this.props.state.userState.currentUser.preferences.editorType) {
            editorType = this.props.state.userState.currentUser.preferences.editorType;
        } else {
            editorType = DEFAULT_PREFERENCES.editorType;
        }
        return (
            <Form>
                <ResponsiveFormField>
                    <label>
                        <FormattedMessage id="project.title" />
                    </label>
                    <input ref={this.titleRef} autoFocus={true}
                        defaultValue={this.originalTitle}
                        onChange={this.onEditing}/>
                </ResponsiveFormField>
                <Form.Field>
                    <label>
                        <FormattedMessage id="project.description" />
                    </label>
                    <Editor
                        language={this.props.state.translations.locale.replace("-", "_")} // i18n use _ instead of -
                        ref={this.descriptionRef}
                        initialValue={this.originalDescription}
                        placeholder={this.getString({id: "project.description_placeholder"})}
                        previewStyle={isMobile() ? "tab" : "vertical"}
                        height="54vh"
                        initialEditType={editorType}
                        usageStatistics={false}
                        hideModeSwitch={true}
                        useCommandShortcut={true}
                        toolbarItems={[
                            "image", "link", "table", "divider",
                            "bold", "italic", "strike", "divider",
                            "heading", "hr", "quote", "divider",
                            "ol", "ul", "task", "divider",
                            "indent", "outdent", "divider",
                            "code", "codeblock"
                        ]}
                        events={{
                            change: () => {
                                this.onEditing();
                            }
                        }}
                        hooks={{
                            addImageBlobHook: this.onInsertImage,
                        }} />
                </Form.Field>
                <FormGroup inline>
                    <Form.Field control={Button} onClick={this.onSubmit} primary
                        loading={this.props.loading}
                        disabled={this.props.loading || !this.state.editing}>
                        <FormattedMessage id={this.props.submitTextId} />
                    </Form.Field>
                    <Form.Field control={Button} onClick={() => this.setState({openClearEditWarning: true})}
                        loading={this.props.loading}
                        disabled={this.props.loading || !this.state.editing}>
                        <FormattedMessage id="component.button.clear_edit" />
                    </Form.Field>
                </FormGroup>
                {this.renderClearEditWarningModal()}
            </Form>
        );
    }

    private onSubmit = (): void => {
        const title: any = this.titleRef.current && this.titleRef.current.value;
        const description: any = this.descriptionRef.current && this.descriptionRef.current.value;
        this.props.onSubmit(title, description);
    }

    private onInsertImage = (blob: File, callback: (url: string, altText: string) => void): void => {
        fetch("/api/image/upload/project", blob, "PUT", true)
        .then((json: any) => {
            if (json && json.url) {
                callback(json.url, blob.name);
            } else {
                toast().error("toast.post.insert_image_failed");
            }
        }, (error: Error) => {
            toast().error("toast.post.insert_image_failed");
        });
    }
    private onEditing = () => {
        if (!this.descriptionRef.current || !this.titleRef.current) {
            return;
        }
        const instanceTitle: string = this.titleRef.current.value;
        const instanceDescription: string = this.descriptionRef.current.value;
        if (this.originalTitle === instanceTitle
            && this.originalDescription === instanceDescription) {
            this.setState({
                editing: false
            });
        } else {
            this.setState({
                editing: true
            });
        }
    }
    private clearEditing = () => {
        if (this.titleRef.current && this.descriptionRef.current) {
            this.titleRef.current.value = this.originalTitle;
            this.descriptionRef.current.value = this.originalDescription;
        }
        this.setState({
            editing: false,
            openClearEditWarning: false
        });
    }
    private renderClearEditWarningModal = (): React.ReactElement<any> | undefined => {
        return <WarningModal
                descriptionIcon="close" open={this.state.openClearEditWarning}
                descriptionText={this.getString({id: "page.project.clear_edit"})}
                warningText={this.getString({id: "page.project.clear_edit_confirmation"})}
                onConfirm={this.clearEditing}
                onCancel={ () => {this.setState({openClearEditWarning: false}); }}/>;
    }
}

export default connectAllProps(ProjectEditor);