import { AnyAction as Action } from "redux";
import TableViewState from "../models/client/TableViewState";
import { GET_TABLE_VIEW_SUCCESS, SAVE_TABLE_VIEW_SUCCESS, GET_TABLE_VIEW_BEGIN, SAVE_TABLE_VIEW_BEGIN, SAVE_TABLE_VIEW_FAILED, GET_TABLE_VIEW_FAILED, GET_MORE_TABLE_VIEW_SUCCESS, GET_MORE_TABLE_VIEW_BEGIN, GET_MORE_TABLE_VIEW_FAILED, SET_EDIT_TABLE_VIEW_CACHE, REMOVE_EDIT_TABLE_VIEW_CACHE, REMOVE_TABLE_VIEW_BEGIN, REMOVE_TABLE_VIEW_SUCCESS, REMOVE_TABLE_VIEW_FAILED, TABLE_VIEW_PUBLISHED_STATUS_CHECKED } from "../actions/table_view";
import { UPDATE_PROFILE_SUCCESS } from "../actions/user";
import TableViewCache from "../models/client/TableViewCache";

const initialState: TableViewState = {
    loading: false,
    valid: false,
    data: [],
    loadingMore: false,
    hasMore: false,
    hasChanges: {},
    savedVersion: {},
    publishedVersion: {},
    editCache: {},
};

const tableView = (state: TableViewState = initialState, action: Action): TableViewState => {
    switch (action.type) {
        case GET_TABLE_VIEW_BEGIN:
        case SAVE_TABLE_VIEW_BEGIN:
        case REMOVE_TABLE_VIEW_BEGIN:
            return {...state, loading: true};
        case GET_TABLE_VIEW_SUCCESS:
            return {
                ...state,
                data: action.tableViews,
                valid: true,
                loading: false,
                hasMore: action.hasMore
            };
        case GET_MORE_TABLE_VIEW_BEGIN:
            return {...state, loadingMore: true};
        case GET_MORE_TABLE_VIEW_SUCCESS:
            return {
                ...state,
                data: [...state.data, ...action.tableViews],
                loadingMore: false,
                hasMore: action.hasMore
            };
        case SAVE_TABLE_VIEW_SUCCESS:
            // merge the added/updated table view instantly, without waiting for the table view list fetching
            const hasChanges: {[id: string]: boolean} = {...state.hasChanges};
            const savedVersion: {[id: string]: number} = {...state.savedVersion};
            const publishedVersion: {[id: string]: number} = {...state.publishedVersion};
            if (action.tableView._id) {
                savedVersion[action.tableView._id] = action.tableView.version;
            }
            if (!action.publish) {
                hasChanges[action.tableView._id] = true;
            } else {
                publishedVersion[action.tableView._id] = action.tableView.version;
            }
            return {
                ...state,
                valid: false,
                loading: false,
                data: [...state.data, action.tableView],
                hasChanges: hasChanges,
                savedVersion: savedVersion,
                publishedVersion: publishedVersion
            };
        case REMOVE_TABLE_VIEW_SUCCESS:
        case UPDATE_PROFILE_SUCCESS:
            return {...state, valid: false, loading: false};
        case GET_TABLE_VIEW_FAILED:
        case REMOVE_TABLE_VIEW_FAILED:
        case SAVE_TABLE_VIEW_FAILED:
            return {...state, loading: false};
        case GET_MORE_TABLE_VIEW_FAILED:
            return {
                ...state,
                loadingMore: false,
                hasMore: false
            };
        case SET_EDIT_TABLE_VIEW_CACHE: {
            const cloneCache: {[id: string]: TableViewCache} = {...state.editCache};
            cloneCache[action.id] = action.cache;
            return {...state, editCache: cloneCache};
        }
        case REMOVE_EDIT_TABLE_VIEW_CACHE: {
            const cloneCache: {[id: string]: TableViewCache} = {...state.editCache};
            delete cloneCache[action.id];
            return {...state, editCache: cloneCache};
        }
        case TABLE_VIEW_PUBLISHED_STATUS_CHECKED: {
            const hasChanges: {[id: string]: boolean} = {...state.hasChanges};
            const savedVersion: {[id: string]: number} = {...state.savedVersion};
            const publishedVersion: {[id: string]: number} = {...state.publishedVersion};
            if (action.id) {
                hasChanges[action.id] = action.hasChanges;
                if (action.savedVersion) savedVersion[action.id] = action.savedVersion;
                if (action.publishedVersion) publishedVersion[action.id] = action.publishedVersion;
            }
            return {...state, hasChanges: hasChanges, savedVersion: savedVersion, publishedVersion: publishedVersion};
        }
        default:
            return state;
    }
};

export default tableView;