import { DropdownItemProps } from "semantic-ui-react";
import DataSource from "../../../models/DataSource";
import { ComponentProps as Props } from "../../../shared/ComponentProps";

export default function dataSources(props: Props): DropdownItemProps[] {
    return Array.from(Object.keys(DataSource) as Array<keyof typeof DataSource>).map((key) => {
        return {
            key: key.toString(),
            text: props.intl.formatMessage({ id: "component.dropdown.data_source." + DataSource[key].toString() }),
            value: DataSource[key].toString()
        } as DropdownItemProps;
    });
}
