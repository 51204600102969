import React from "react";
import { Header, Icon, Grid, Button, Container, Tab } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ComponentProps } from "../../../shared/ComponentProps";
import User from "../../../models/User";
import connectAllProps from "../../../shared/connect";
import { TableView } from "@airjam/react-table-view";
import BubbleInstructions from "../../components/appointment/static/BubbleInstructions";

interface States {}
class AppointmentBubble extends React.Component<ComponentProps, States> {
    render() {
        const user: User | undefined = this.props.state.userState.currentUser;
        return <Container style={CONTAINER_STYLE}>
            <Grid stackable>
                <Grid.Row style={{ minHeight: 30 }}></Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={10} verticalAlign="bottom" textAlign="center">
                        <Header textAlign="center" size="huge">
                            <div>
                                <Icon name="calendar check"></Icon>
                            </div>
                            <span className="punchLine">Bookings &amp; Appointments Component for Bubble.io</span>
                            <Header.Subheader className="punchLineSubHeader">
                                The most comprehensive and fully-featured booking and appointment component, built for some serious use cases — completely FREE!
                            </Header.Subheader>
                        </Header>
                        <br />
                        <img src="/images/templates/bubble-punch.png" alt="" style={{width: "60%"}} />
                        <Header textAlign="center" size="huge">
                            <Header.Subheader className="punchLineSubHeader">
                                {user ? <Link to={user ? "/appointments/" : "/login/r/appointments"}>
                                    <Button animated="vertical" style={{ marginTop: 15, marginBottom: 20, borderRadius: 30, padding: "15px 20px 15px 20px" }} color="black">
                                        <Button.Content visible>Go to console</Button.Content>
                                        <Button.Content hidden>
                                            <Icon name="terminal" />
                                        </Button.Content>
                                    </Button>
                                </Link> : ""}
                                {user ? "" : <Link to={user ? "/appointments/" : "/join/r/appointments"}>
                                    <Button animated="vertical" style={{ marginTop: 15, marginBottom: 20, borderRadius: 30, padding: "15px 20px 15px 20px" }} color="orange">
                                        <Button.Content visible><FormattedMessage id="get_started" /></Button.Content>
                                        <Button.Content hidden>
                                            <Icon name="arrow right" />
                                        </Button.Content>
                                    </Button>
                                </Link>}
                                <Link to={{ pathname: "https://bubble.io/plugin/booking-and-appointments-1730152230209x863112966839992300" }} target="_blank" >
                                    <Button animated="vertical" style={{ opacity: 1, marginTop: 15, marginBottom: 20, borderRadius: 30, padding: "15px 20px 15px 20px" }} color="black">
                                        <Button.Content visible>Go to plugin</Button.Content>
                                        <Button.Content hidden>
                                            <Icon name="power cord" />
                                        </Button.Content>
                                    </Button>
                                </Link>
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={10}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <i className={"fa-solid fa-globe fa-2x"}></i>
                                    <h4>Designed for real-world use cases</h4>
                                    <p>AirJam's Appointment component is the only plugin focused on real-world features, such as customizing your own branding and messaging, supporting the languages you need, and connecting to authentication services you already use, like Firebase and Clerk. And the best part? You can manage all of this seamlessly within your admin console.</p>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <i className={"fa-solid fa-shuffle fa-2x"}></i>
                                    <h4>Go cross-platform in seconds</h4>
                                    <p>Add booking and appointment features to WordPress, Bubble, and mobile apps—all in seconds. AirJam's Appointment component is designed to support a wide range of platforms, letting you integrate booking features into your existing systems with minimal effort and without the high cost of switching to new platforms.</p>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <i className={"fa-regular fa-calendar fa-2x"}></i>
                                    <h4>Manage from your own calendar (Closed-beta)</h4>
                                    <p>
                                        Use your own calendar services such as Google Calendar or Microsoft Outlook to manage and update your availabilities. AirJam automatically syncs and updates your booking component and your calendar service using CalDAV.
                                    </p>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <i className={"fa-solid fa-hammer fa-2x"}></i>
                                    <h4>The ultimate admin console</h4>
                                    <p>
                                        AirJam's booking component comes with a comprehensive admin console, giving you full control to manage things like payments using Stripe, booking moderation, email templates, and so much more, all from a single place!
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={10}>
                        <h3>How to use the plugin</h3>
                        <BubbleInstructions />
                    </Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>;
    }
}

export default connectAllProps(AppointmentBubble);