import React from "react";
import connectAllProps from "../../../shared/connect";
import { ComponentProps } from "../../../shared/ComponentProps";
import { MessageDescriptor } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import { Container, Header, Image } from "semantic-ui-react";
import { ComponentTemplate, template_cache, TableViewViewType } from "@airjam/types";

interface States {
    selectedTemplate?: string;
}

interface Props extends ComponentProps {
    componentId: string;
    initialTemplate?: string;
    key: string;
    selectedViewType: string;
    templates?: {[id: string]: ComponentTemplate};
    onChange: (componentId: string, newTemplateKey: string, newTemplateVersion: number) => void;
}

class TemplateSelect extends React.Component<Props, States> {
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;

    constructor(props: Props) {
        super(props);
        this.getString = this.props.intl.formatMessage;
        this.state = {
            selectedTemplate: props.initialTemplate
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.selectedViewType !== this.props.selectedViewType) {
            this.forceUpdate();
        }
    }

    render(): React.ReactElement<any> {
        const templates: ComponentTemplate[] = this.getEligibleTemplates();
        return <div key={this.props.key}>
        {
            templates.map((template: ComponentTemplate) => this.renderTemplate(template))
        }
        </div>;
    }

    private renderTemplate = (template: ComponentTemplate): React.ReactElement<any> => {
        return <Container className={"templateOptionContainer" + this.classNameIfSelected(template.shortId)} onClick={() => this.templateSelected(template.shortId, template.version)} key={this.props.key + template.name}>
                <Image src={template.previewImageUrls[0]}></Image>
                <Header size="small">{template.name}</Header>
            </Container>;
    }

    private classNameIfSelected = (templateId: string): string => {
        if (this.state.selectedTemplate && (this.state.selectedTemplate!.toLowerCase() === templateId.toLowerCase())) {
            return " selected";
        }
        return "";
    }

    private templateSelected = (newTemplateId: string, newTemplateVersion: number) => {
        this.setState({
            selectedTemplate: newTemplateId.toLowerCase()
        });
        if (this.props.onChange) this.props.onChange(this.props.componentId, newTemplateId, newTemplateVersion);
        this.forceUpdate();
    }

    private getEligibleTemplates(): ComponentTemplate[] {
        const templates = this.props.templates ? this.props.templates : template_cache;
        const keyIdx: number = Object.keys(TableViewViewType).indexOf(this.props.selectedViewType);
        const typeValue: string = Array.from(Object.values(TableViewViewType))[keyIdx];
        const compatibleTemplates: ComponentTemplate[] = Object.entries(templates).filter((value: [string, ComponentTemplate]) => {
            for (let i = 0; i < value[1].compatibleDisplayType.length; i++) {
                if (value[1].compatibleDisplayType[i].toLowerCase() === typeValue) {
                    return true;
                }
            }
            return false;
        }).map((value: [string, ComponentTemplate]) => value[1]);
        return compatibleTemplates;
    }

}

export default connectAllProps(TemplateSelect);