import TableViewActionCreator from "../models/client/TableViewActionCreator";
import { Dispatch, AnyAction as Action } from "redux";
import fetch from "../shared/fetch";
import actions from "./common";
import { TableView } from "../models/TableView";
import GetTableViewsResponse from "../models/response/GetTableViewsResponse";
import TableViewCache from "../models/client/TableViewCache";
import { getToast as toast } from "../shared/toast";
import { getStorage as localStorage } from "../shared/storage";

export const SAVE_TABLE_VIEW_BEGIN: string = "SAVE_TABLE_VIEW_BEGIN";
export const SAVE_TABLE_VIEW_SUCCESS: string = "SAVE_TABLE_VIEW_SUCCESS";
export const SAVE_TABLE_VIEW_FAILED: string = "SAVE_TABLE_VIEW_FAILED";
export const REMOVE_TABLE_VIEW_BEGIN: string = "REMOVE_TABLE_VIEW_BEGIN";
export const REMOVE_TABLE_VIEW_SUCCESS: string = "REMOVE_TABLE_VIEW_SUCCESS";
export const REMOVE_TABLE_VIEW_FAILED: string = "REMOVE_TABLE_VIEW_FAILED";
export const GET_TABLE_VIEW_BEGIN: string = "GET_TABLE_VIEW_BEGIN";
export const GET_TABLE_VIEW_SUCCESS: string = "GET_TABLE_VIEW_SUCCESS";
export const GET_TABLE_VIEW_FAILED: string = "GET_TABLE_VIEW_FAILED";
export const GET_MORE_TABLE_VIEW_BEGIN: string = "GET_MORE_TABLE_VIEW_BEGIN";
export const GET_MORE_TABLE_VIEW_SUCCESS: string = "GET_MORE_TABLE_VIEW_SUCCESS";
export const GET_MORE_TABLE_VIEW_FAILED: string = "GET_MORE_TABLE_VIEW_FAILED";
export const INSERT_IMAGE_BEGIN: string = "INSERT_IMAGE_BEGIN";
export const INSERT_IMAGE_SUCCESS: string = "INSERT_IMAGE_SUCCESS";
export const INSERT_IMAGE_FAILED: string = "INSERT_IMAGE_FAILED";
export const SET_EDIT_TABLE_VIEW_CACHE: string = "SET_EDIT_TABLE_VIEW_CACHE";
export const REMOVE_EDIT_TABLE_VIEW_CACHE: string = "REMOVE_EDIT_TABLE_VIEW_CACHE";
export const IGNORE_CACHE_RESTORE: string = "IGNORE_CACHE_RESTORE";
export const NEW_TABLE_VIEW_CACHE_ID: string = "NEW_TABLE_VIEW_CACHE_ID";
export const TABLE_VIEW_PUBLISHED_STATUS_CHECKED: string = "TABLE_VIEW_PUBLISHED_STATUS_CHECKED";
export const TABLE_VIEW_EDIT_CACHE_KEY_PREFIX: string = "tableViewEdit/";

const removeEditCacheExec = (id: string, dispatch: Dispatch<any>): void => {
    localStorage().removeItem(TABLE_VIEW_EDIT_CACHE_KEY_PREFIX + id);
    dispatch({
        type: REMOVE_EDIT_TABLE_VIEW_CACHE,
        id: id
    });
};

const tableViewActionCreator: TableViewActionCreator = {
    getTableViews(): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: GET_TABLE_VIEW_BEGIN});
            fetch("/api/view/table", undefined, "GET")
            .then((json: GetTableViewsResponse) => {
                if (json && json.tableViews) {
                    dispatch({
                        type: GET_TABLE_VIEW_SUCCESS,
                        tableViews: json.tableViews,
                        hasMore: json.hasMore
                    });
                } else {
                    return Promise.reject({ name: "500 Internal Server Error", message: "" });
                }
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(GET_TABLE_VIEW_FAILED, error));
            });
        };
    },
    getMoreTableViews(earlierThan: string): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: GET_MORE_TABLE_VIEW_BEGIN});
            fetch(`/api/view/table?latest=${earlierThan}`, undefined, "GET")
            .then((json: GetTableViewsResponse) => {
                if (json && json.tableViews) {
                    dispatch({
                        type: GET_MORE_TABLE_VIEW_SUCCESS,
                        tableViews: json.tableViews,
                        hasMore: json.hasMore
                    });
                } else {
                    return Promise.reject({ name: "500 Internal Server Error", message: "" });
                }
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(GET_MORE_TABLE_VIEW_FAILED, error));
            });
        };
    },
    checkIfChangesUnpublished(id: string): any {
        return (dispatch: Dispatch<any>): void => {
            fetch(`/api/view/table/unpublished_changes/${id}`, undefined, "GET", /*withToken*/ true)
            .then((json: any) => {
                dispatch({
                    type: TABLE_VIEW_PUBLISHED_STATUS_CHECKED,
                    id: id,
                    hasChanges: json.hasChanges,
                    savedVersion: json.savedVersion,
                    publishedVersion: json.publishedVersion,
                });
            });
        };
    },
    addTableView(tableView: TableView): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: SAVE_TABLE_VIEW_BEGIN});
            const postBody: any = tableView;
            fetch("/api/view/table/create", { ...postBody }, "POST", /*withToken*/ true)
            .then((added: TableView) => {
                if (added) {
                    removeEditCacheExec(NEW_TABLE_VIEW_CACHE_ID, dispatch);
                    toast().success("toast.view.table.save_successfully");
                    dispatch({
                        type: SAVE_TABLE_VIEW_SUCCESS,
                        tableView: added,
                        projectId: tableView.projectId,
                        publish: false
                    });
                } else {
                    return Promise.reject({ name: "500 Internal Server Error", message: "Broken data." });
                }
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(SAVE_TABLE_VIEW_FAILED, error));
            });
        };
    },
    editTableView(projectId: string, tableView: TableView, publish: boolean): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: SAVE_TABLE_VIEW_BEGIN});
            const postBody: any = tableView;
            postBody.publish = publish;
            fetch("/api/view/table/edit", postBody, "POST", /*withToken*/ true)
            .then((updated: TableView) => {
                removeEditCacheExec(tableView._id, dispatch);
                toast().success("toast.view.table.save_successfully");
                dispatch({
                    type: SAVE_TABLE_VIEW_SUCCESS,
                    tableView: updated,
                    publish: publish,
                    projectId: projectId
                });
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(SAVE_TABLE_VIEW_FAILED, error));
            });
        };
    },
    removeTableView(projectId: string, id: string): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: REMOVE_TABLE_VIEW_BEGIN});
            fetch(`/api/view/table/remove/${id}`, undefined, "GET", /*withToken*/ true)
            .then((json: any) => {
                toast().success("toast.view.table.delete_successfully");
                removeEditCacheExec(id, dispatch);
                dispatch({
                    type: REMOVE_TABLE_VIEW_SUCCESS,
                    id: id,
                    projectId: projectId
                });
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(REMOVE_TABLE_VIEW_FAILED, error));
            });
        };
    },
    setEditTableViewCache(id: string, cache: TableViewCache): Action {
        localStorage().setItem(TABLE_VIEW_EDIT_CACHE_KEY_PREFIX + id, JSON.stringify(cache));
        return {
            type: SET_EDIT_TABLE_VIEW_CACHE,
            id: id,
            cache: cache
        };
    },
    removeEditTableViewCache(id: string): any {
        return (dispatch: Dispatch<any>): void => {
            removeEditCacheExec(id, dispatch);
        };
    },
    restoreEditTableViewCache(): any {
        return (dispatch: Dispatch<any>): void => {
            localStorage()
            .getAllKeys()
            .then((keys: string[]) => {
                keys.forEach(key => {
                    if (!key || !key.startsWith(TABLE_VIEW_EDIT_CACHE_KEY_PREFIX)) {
                        return dispatch({
                            type: IGNORE_CACHE_RESTORE
                        });
                    }
                    localStorage().getItem(key).then((value: string | null) => {
                        if (!key || !key.startsWith(TABLE_VIEW_EDIT_CACHE_KEY_PREFIX) || !value) {
                            return dispatch({
                                type: IGNORE_CACHE_RESTORE
                            });
                        }
                        const id: string = key.slice(TABLE_VIEW_EDIT_CACHE_KEY_PREFIX.length);
                        dispatch({
                            type: SET_EDIT_TABLE_VIEW_CACHE,
                            id: id,
                            cache: JSON.parse(value) as TableViewCache
                        });
                    }).catch((reason: any) => {
                        return Promise.reject();
                    });
                });
            }).catch((reason: any) => {
                dispatch({
                    type: IGNORE_CACHE_RESTORE
                });
            });
        };
    }
};

export default tableViewActionCreator;