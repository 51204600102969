import React from "react";
import connectAllProps from "../../../shared/connect";
import { Redirect } from "react-router-dom";
import { TableView } from "../../../models/TableView";
import ErrorPage from "../../pages/ErrorPage";
import { Container, Header } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
import TableViewEditor from "./TableViewEditor";
import { FormattedMessage } from "react-intl";
import { isMobile } from "../dimension";
import { pendingRedirect } from "../../../shared/redirect";
import { ComponentProps as Props } from "../../../shared/ComponentProps";

interface States {}
class EditTableView extends React.Component<Props, States> {
    private tableViewId: string = "";
    render(): React.ReactElement<any> {
        if (pendingRedirect(this.props)) {
            return <Redirect to={this.props.state.redirectTask.to} />;
        }
        const notFoundError: Error = {
            name: "404 Not Found",
            message: `not found for ${window.location.href} `
        };
        this.tableViewId = this.props.match && this.props.match.params && this.props.match.params.tableViewId;
        if (!this.tableViewId) {
            return <ErrorPage error={notFoundError} />;
        }
        const tableView: TableView | undefined = this.props.state.tableViewState.data.find(
            (value: TableView): boolean => value._id === this.tableViewId
        );
        if (!tableView) {
            return <ErrorPage error={notFoundError} />;
        }
        if (this.props.state.userState.currentUser) {
            const containerStyle: any = isMobile() ? CONTAINER_STYLE :
                {...CONTAINER_STYLE, paddingLeft: 20, paddingRight: 20};
            return (
                <Container style={containerStyle}>
                    <Header size={"medium"}>
                        <FormattedMessage id="page.table.view.edit" />
                    </Header>
                    <TableViewEditor tableView={tableView}
                        submitTextId="component.button.update"
                        onSubmit={this.editTableView}
                        loading={this.props.state.tableViewState.loading} />
                </Container>
            );
        } else {
            return <Redirect to="/view/table" />;
        }
    }

    private editTableView = (projectId: string, title: string, type: string, paginationStyle: string): void => {
        if (this.props.state.userState.currentUser) {
            this.props.actions.editTableView(
                projectId, {
                projectId: projectId,
                title: title,
                type: type,
                paginationStyle: paginationStyle,
                _id: this.tableViewId
            } as TableView,
            false); // todo control publish actions
        }
    }
}

export default connectAllProps(EditTableView);