import React from "react";
import connectAllProps from "../../../shared/connect";
import fetch from "../../../shared/fetch";
import { ComponentProps } from "../../../shared/ComponentProps";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";
import { MessageDescriptor } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import { GoogleSheet, GoogleSheetDocument } from "../../../models/views/GoogleSheetDocument";

interface Props extends ComponentProps {
    onChange: (componentId: string, newValue: string) => void;
    sheetId: string;
    componentId: string;
    key: string;
    initialSheetSelection: string;
    className: string;
}

interface States {
    currentSheetId: string;
    forceResetValueFlag: boolean;
    options: DropdownItemProps[];
}

class GoogleSheetSheetSelector extends React.Component<Props, States> {
    private _isMounted: boolean = false;
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    private sheetSelection: string;
    constructor(props: Props) {
        super(props);
        this.getString = this.props.intl.formatMessage;
        this.sheetSelection = "";
        this.state = {
            forceResetValueFlag: false,
            currentSheetId: this.props.sheetId,
            options: [{ key: "0", value: "0", text: this.getString({ id: "data.source.spreadsheet.select" }) }]
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.refreshSheetSpec();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps: Props) {
        if (prevProps.sheetId !== this.props.sheetId) {
            this.refreshSheetSpec();
        }
    }
    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<States>, nextContext: any): boolean {
        if (nextState.forceResetValueFlag) {
            this.sheetSelection = this.getString({ id: "data.source.spreadsheet.select" });
            this.setState({
                options: [],
                forceResetValueFlag: false
            });
            this.forceUpdate();
        }

        return true;
    }

    public static getDerivedStateFromProps(nextProps: Readonly<Props>, prevState: States): Partial<States> | null {
        if (prevState.currentSheetId && nextProps.sheetId !== prevState.currentSheetId) {
            return { forceResetValueFlag: true };
        }
        return {
            currentSheetId: nextProps.sheetId
        };
    }

    render(): React.ReactElement<any> {
        return <div className={this.props.className}>
            <Dropdown
            key={this.props.key}
            text={this.sheetSelection ? this.sheetSelection : this.props.initialSheetSelection}
            value={this.sheetSelection ? this.sheetSelection : this.props.initialSheetSelection}
            fluid
            selection
            onChange={(e: any, value: any) => {
                this.sheetSelection = value.value;
                this.props.onChange(this.props.componentId, value.value);
                this.forceUpdate();
            }}
            options={this.state.options}
        />
        </div>;
    }

    private refreshSheetSpec() {
        if (this.props.sheetId) {
            fetch(`/api/view/table/sheetspec/${this.props.sheetId}`, undefined, "GET", true)
                .then((doc: GoogleSheetDocument) => {
                    if (doc && this._isMounted) {
                        this.setState({
                            options: []
                        });
                        doc.sheets.forEach(sheet => {
                            this.state.options.push(this.googleSheetToOptions(sheet));
                        });
                        this.forceUpdate();
                    }
                }).catch((error: Error) => {
                    const msg = error.message as any;
                    if (msg && msg.name && msg.name === "oauth") {
                        alert("Your authentication with Google is expired, and you are now being logged out. Please log in again to re-authenticate with Google");
                        this.props.actions.logout();
                    }
                });
        }
    }

    private googleSheetToOptions(sheet: GoogleSheet): DropdownItemProps {
        return {
            key: sheet.index,
            text: sheet.title,
            value: sheet.title
        };
    }
}

export default connectAllProps(GoogleSheetSheetSelector);