import React, { Fragment, RefObject } from "react";
import connectAllProps from "../../../shared/connect";
import { Link, match, Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { pendingRedirect } from "../../../shared/redirect";
import Project from "../../../models/Project";
import ErrorPage from "../../pages/ErrorPage";
import { Button, Checkbox, CheckboxProps, Confirm, Divider, Dropdown, Grid, Header, Input, Label, List, Menu, Popup, Segment, Statistic, Tab, Table } from "semantic-ui-react";
import "react-tiny-fab/dist/styles.css";
import { MessageDescriptor, FormattedMessage } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import WarningModal from "../shared/WarningModal";
import { ComponentProps as Props } from "../../../shared/ComponentProps";
import { TableView, TableViewRef, refToTableView, vendTableViewRefFromComponent } from "../../../models/TableView";
import { StaticContext } from "react-router";
import GoogleSheetDocumentSelector from "../components/GoogleSheetDocumentSelector";
import GoogleSheetSheetSelector from "../components/GoogleSheetSheetSelector";
import GoogleSheetSheetFieldConfiguration from "../components/GoogleSheetSheetFieldConfiguration";
import ViewTypeSelect from "../components/ViewTypeSelect";
import TemplateSelect from "../components/TemplateSelect";
import StyleSelect from "../components/StyleSelect";
import TemplateFieldConfiguration from "../components/TemplateFieldConfiguration";
import paginationStyles from "../components/PaginationStyles";
import sortByOptions from "../components/SortByOptions";
import { ComponentTemplate, DataSourceField, formattedField, style_cache, TemplateProperty, TemplateStyle, template_cache, TableViewViewType } from "@airjam/types";
import CreateComponentWizard from "./CreateComponentWizard";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import ViewCountComponent from "./ViewCountComponent";
import { CallPage } from "../../../models/CallType";

interface States {
    openDeleteWarning: boolean;
    openNewComponentDialog: boolean;
    openDeleteProjectConfirmDialog: boolean;
    publishComponentConfirmDialogId: string;
    deleteComponentConfirmDialogId: string;
    dirty: {[id: string]: boolean};
}

interface DetailedTableViewRef extends TableViewRef {
    exampleData: any;
}

class ProjectDetail extends React.Component<Props, States> {
    private projectId: string = "";
    private currentPageId: string = "";
    private tableComponentRef: {[id: string]: DetailedTableViewRef} = {};
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.projectId = this.props.match && this.props.match.params && this.props.match.params.projectId;
        this.getString = this.props.intl.formatMessage;
        this.state = {
            openDeleteWarning: false,
            openNewComponentDialog: false,
            openDeleteProjectConfirmDialog: false,
            publishComponentConfirmDialogId: "",
            deleteComponentConfirmDialogId: "",
            dirty: {}
        };
    }

    private initializeComponentRefs = () => {
        const project: Project = this.props.state.projectState.detailedProjects[this.projectId];
        if (!project) return;
        project.tableViewComponents.forEach((component: TableView) => {
            if (!this.tableComponentRef[component._id]) {
                const newRef: DetailedTableViewRef = vendTableViewRefFromComponent(component) as DetailedTableViewRef;
                newRef.exampleData = {};
                this.tableComponentRef[component._id] = newRef;
            }
        });
    }

    componentDidMount() {
        if (this.projectId) {
            this.fetchProjectIfNeeded();
        }
        this.props.actions.resetRedirectTask();
        this.initializeComponentRefs();
        window.scrollTo(0, 0);
    }
    componentDidUpdate(prevProps: Props) {
        if (!this.projectId) {
            console.log("project id not set");
        }
        const currentProjectId: string = (this.props.match && this.props.match.params) ? this.props.match.params.projectId : "";
        const prevProjectId: string = (prevProps.match && prevProps.match.params) ? prevProps.match.params.projectId : "";
        if (currentProjectId !== prevProjectId) {
            console.log("project id changed");
            this.projectId = currentProjectId;
            this.initializeComponentRefs();
        } else if (currentProjectId && this.props.state.projectState.detailedProjects[currentProjectId]) {
            this.initializeComponentRefs();
        }
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.forceUpdate();
        }
    }

    componentWillUnmount() {
        this.props.actions.setFabActions([]);
    }
    render(): React.ReactElement<any> {
        if (pendingRedirect(this.props)) {
            return <Redirect to={this.props.state.redirectTask.to} />;
        }
        const notFoundError: Error = {
            name: "404 Not Found",
            message: `not found for ${window.location.href} `
        };
        if (!this.projectId) {
            return <ErrorPage error={notFoundError} />;
        }
        const project: Project = this.props.state.projectState.detailedProjects[this.projectId];
        if (!project) {
            return <ErrorPage error={notFoundError} />;
        }
        const match: match<any> = this.props.match;
        return (
            <div className="fullHeight">
                <Grid columns={3} divided>
                    <Grid.Row stretched className="fullHeight">
                    <Grid.Column width={3} className="leftPanel">

                    <List relaxed>
                        <List.Item>
                        <List.Icon name="share alternate" />
                        <List.Content>
                            <List.Header><Link to={"/project/" + this.projectId}>{project?.title}</Link></List.Header>
                            <List.List>
                            <List.Item>
                                <List.Icon name="dashboard" />
                                <List.Content>
                                <List.Header>
                                    <Link to={"/project/" + this.projectId}>
                                        <FormattedMessage id="page.project.dashboard" />
                                    </Link>
                                </List.Header>
                                </List.Content>
                            </List.Item>
                            {
                                project.tableViewComponents.map((component: TableView) => {
                                    return <List.Item key={component._id}>
                                        <List.Icon name="table" />
                                        <List.Content>
                                            <List.Header>
                                                <Link to={"/project/" + this.projectId + "/view/" + component._id }>
                                                    {component.title}
                                                </Link>
                                            </List.Header>
                                            <List.List>
                                                <List.Item>
                                                <List.Icon name="home" />
                                                <List.Content>
                                                    <List.Header>
                                                        <Link to={"/project/" + this.projectId + "/view/" + component._id }>
                                                            <FormattedMessage id="page.project.component.overview" />
                                                        </Link>
                                                    </List.Header>
                                                </List.Content>
                                                </List.Item>
                                                <List.Item>
                                                <List.Icon name="database" />
                                                <List.Content>
                                                    <List.Header>
                                                        <Link to={"/project/" + this.projectId + "/data/" + component._id }>
                                                            <FormattedMessage id="page.project.component.data" />
                                                        </Link>
                                                    </List.Header>
                                                </List.Content>
                                                </List.Item>
                                                <List.Item>
                                                <List.Icon name="object group" />
                                                <List.Content>
                                                    <List.Header>
                                                        <Link to={"/project/" + this.projectId + "/style/" + component._id }>
                                                            <FormattedMessage id="page.project.component.look_and_feel" />
                                                        </Link>
                                                    </List.Header>
                                                </List.Content>
                                                </List.Item>
                                            </List.List>
                                        </List.Content>
                                    </List.Item>;
                                })
                            }
                            <List.Item>
                                <List.Icon name="setting" />
                                <List.Content>
                                <List.Header>
                                    <Link to={"/project/" + this.projectId + "/settings"} >
                                        <FormattedMessage id="page.project.settings" />
                                    </Link>
                                </List.Header>
                                </List.Content>
                            </List.Item>
                            </List.List>
                        </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Content>
                                <Segment className="hollowSegment" compact>
                                    <Grid columns={3} verticalAlign="middle" onClick={() => this.setState({openNewComponentDialog: true})}>
                                        <Grid.Row>
                                            <Grid.Column width="3" textAlign="center">
                                                <span className="more">+</span>
                                            </Grid.Column>
                                            <Grid.Column width="13">
                                                <Header size="small">
                                                    <FormattedMessage id="project.navigation.add_component" />
                                                </Header>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </List.Content>
                        </List.Item>
                    </List>
                    <CreateComponentWizard {...this.props} projectId={this.projectId} defaultComponentName={this.getString({ id: "page.project.wizard.configure.title.default_name" }, { index: project.tableViewComponents.length + 1 })} visible={this.state.openNewComponentDialog} closeModal={() => this.setState({openNewComponentDialog : false})}></CreateComponentWizard>

                    </Grid.Column>
                    <Grid.Column width={9} className="mainWorkspace">
                        { this.renderDashboard() }
                        { this.renderSettings() }
                        { this.renderComponentView() }
                        { this.renderComponentData() }
                        { this.renderComponentStyle() }
                        <Switch>
                            <Route exact path={match.url} props={this.props} render={() => { return this.showNestedRouteStep("dashboard"); }} />
                            <Route path={`${match.url}/settings`} props={this.props} render={() => { return this.showNestedRouteStep("settings"); }} />
                            <Route path={`${match.url}/view/:componentId`} props={this.props} render={(componentProps: RouteComponentProps<any, StaticContext, any>) => { return this.showNestedRouteStep("view_" + componentProps.match.params.componentId); }} />
                            <Route path={`${match.url}/data/:componentId`} props={this.props} render={(componentProps: RouteComponentProps<any, StaticContext, any>) => { return this.showNestedRouteStep("data_" + componentProps.match.params.componentId); }}  />
                            <Route path={`${match.url}/style/:componentId`} props={this.props} render={(componentProps: RouteComponentProps<any, StaticContext, any>) => { return this.showNestedRouteStep("style_" + componentProps.match.params.componentId); }}  />
                        </Switch>
                        {
                            this.renderDeleteWarningModal(project)
                        }
                    </Grid.Column>
                    <Grid.Column width={4} className="rightPanel">
                        { this.renderRhs() }
                    </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }

    private renderRhs = (): React.ReactElement<any> => {
        const match: match<any> = this.props.match;
        return <Switch>
            <Route exact path={match.url} props={this.props} render={() => { return this.renderProjectRhs(); }} />
            <Route path={`${match.url}/settings`} props={this.props} render={() => { return this.renderProjectSettingsRhs(); }} />
            <Route path={`${match.url}/view/:componentId`} props={this.props} render={(componentProps: RouteComponentProps<any, StaticContext, any>) => { return this.renderComponentDashboardRhs(componentProps.match.params.componentId); }} />
            <Route path={`${match.url}/data/:componentId`} props={this.props} render={(componentProps: RouteComponentProps<any, StaticContext, any>) => { return this.renderComponentDataRhs(componentProps.match.params.componentId); }}  />
            <Route path={`${match.url}/style/:componentId`} props={this.props} render={(componentProps: RouteComponentProps<any, StaticContext, any>) => { return this.renderComponentStyleRhs(componentProps.match.params.componentId); }}  />
        </Switch>;
    }

    private renderProjectRhs = (): React.ReactElement<any> => {
        return <div>
            <p></p>
        </div>;
    }
    private renderProjectSettingsRhs = (): React.ReactElement<any> => {
        return <span></span>;
    }
    private renderComponentDashboardRhs = (componentId: string): React.ReactElement<any> => {
        return <div>
            <p></p>
            {this.renderComponentActions(componentId)}
            <p></p>
            <Segment className="disclaimer">
                <Header size="tiny">Things to note</Header>
                <div>
                    <ol>
                        <li>Format of your spreadsheet must match the specs of your chosen template for your component to work</li>
                        <li>Make sure all columns are labeled in the first row</li>
                        <li>All sheets in your spreadsheet should have unique names</li>
                        <li>Do not shuffle columns after your sheet is configured with AirJam. AirJam uses the column's location to map fields</li>
                        <li>Any changes to your spreadsheet can crash your component! Treat it like a live database!</li>
                    </ol>
                </div>
            </Segment>
        </div>;
    }
    private renderComponentDataRhs = (componentId: string): React.ReactElement<any> => {
        if (!this.tableComponentRef || !this.tableComponentRef[componentId] || !this.tableComponentRef[componentId].fieldMappingResult || !this.tableComponentRef[componentId].exampleData) return <Fragment></Fragment>;
        return <div>
            <p></p>
            {this.renderComponentActions(componentId)}
            <div>
                <Header size="tiny">
                    <Divider />
                    <FormattedMessage id="page.project.component.example.title" />
                </Header>
                <div className="section label subtext">
                    <FormattedMessage id="page.project.component.example.title.description" />
                </div>
                <p></p>
                {this.renderExample(this.tableComponentRef[componentId])}
            </div>
        </div>;
    }

    private renderExample = (view: DetailedTableViewRef): React.ReactElement<any> => {
        const fieldMapping = view.fieldMappingResult;
        if (!view.exampleData) return <Fragment></Fragment>;
        return <div>
            <Table celled selectable fixed size="small">
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell><FormattedMessage id="data.source.spreadsheet.configure.column_name" /></Table.HeaderCell>
                    <Table.HeaderCell><FormattedMessage id="data.source.spreadsheet.configure.example" /></Table.HeaderCell>
                </Table.Row>
                </Table.Header>
                <Table.Body>
                {
                    Object.keys(fieldMapping).map((key: string) => {
                        if (view.exampleData[key] && fieldMapping[key].show && fieldMapping[key].displayAs) {
                            return <Table.Row key={"example." + key}>
                                <Table.Cell>{fieldMapping[key].fieldName}</Table.Cell>
                                <Table.Cell>{formattedField(view.exampleData[key], fieldMapping[key].displayAs)}</Table.Cell>
                            </Table.Row>;
                        }
                        return undefined;
                    })
                }
                </Table.Body>
            </Table>
        </div>;
    }
    private renderComponentStyleRhs = (componentId: string): React.ReactElement<any> => {
        if (!this.tableComponentRef || !this.tableComponentRef[componentId]) return <Fragment></Fragment>;
        let template: ComponentTemplate | undefined = undefined;
        let style: TemplateStyle | undefined = undefined;
        template = this.getTemplate(this.tableComponentRef[componentId].templateId, this.tableComponentRef[componentId].templateVersion);
        style = this.getStyle(this.tableComponentRef[componentId].styleId, this.tableComponentRef[componentId].styleVersion);
        return <div>
            <p></p>
            {this.renderComponentActions(componentId)}
            <div>
                {this.renderTemplateDescription(componentId, template)}
                {this.renderStyleDescription(componentId, style)}
            </div>
        </div>;
    }

    private renderStyleDescription = (componentId: string, style?: TemplateStyle): React.ReactElement<any> => {
        if (!style) return <Fragment></Fragment>;
        return <div>
            <p></p>
            <Header size="tiny">
                <Divider />
                {style.name}
            </Header>
            <div className="section label subtext">
                {style.description}
            </div>
            {this.renderStyleProperties(componentId, style.properties)}
        </div>;
    }

    private renderTemplateDescription = (componentId: string, template?: ComponentTemplate): React.ReactElement<any> => {
        if (!template) return <Fragment></Fragment>;
        return <div>
            <p></p>
            <Header size="tiny">
                <Divider />
                {template.name}
            </Header>
            <div className="section label subtext">
                {template.description}
            </div>
            <p></p>
            {this.renderTemplateProperties(componentId, template.properties)}
        </div>;
    }

    private renderStyleProperties = (componentId: string, properties: {[id: string]: TemplateProperty}): React.ReactElement<any> => {
        return <div>
            {
                Object.keys(properties).map((key: string) => {
                    return this.renderStyleProperty(componentId, key, properties[key]);
                })
            }
        </div>;
    }

    private renderTemplateProperties = (componentId: string, properties: {[id: string]: TemplateProperty}): React.ReactElement<any> => {
        return <div>
            {
                Object.keys(properties).map((key: string) => {
                    return this.renderTemplateProperty(componentId, key, properties[key]);
                })
            }
        </div>;
    }

    private renderStyleProperty = (componentId: string, key: string, property: TemplateProperty): React.ReactElement<any> => {
        // does not fully use the default value atm
        switch (property.type) {
            case "BOOLEAN":
                let defaultValue: boolean = false;
                const initialValue = this.getComponentStyleProperty(componentId, key);
                if (typeof initialValue === "boolean") defaultValue = this.getComponentStyleProperty(componentId, key, property.default);
                if (initialValue === "true") defaultValue = true;
                return <div key={componentId + key} className="templateProperty">
                    <Popup content={property.description} size="mini" position="right center" trigger={<Checkbox label={<label className="section label subtext">{property.name}</label>} defaultChecked={defaultValue} onChange={(event: any, data: CheckboxProps) => { this.updateComponentTemplateProperty(componentId, key, data.checked); }}/>} />
                </div>;
            case "LIST":
                // todo --> get default value and select it to list
                return <div key={componentId + key} className="templateProperty">
                    <Popup content={property.description} size="mini" position="right center" trigger={
                    <div>
                        <label className="section label subtext">{property.name}</label>
                        <Dropdown
                            key={componentId + key + property.name}
                            fluid
                            selection
                            onChange={(e: any, value: any) => {
                                this.updateComponentStyleProperty(componentId, key, value.value);
                            }}
                            options={property.values!} />
                    </div>} />
                </div>;
            case "MULTI":
                return <div key={componentId + key} className="templateProperty">
                    <Popup content={property.description} size="mini" position="right center" trigger={
                    <div>
                        <label className="section label subtext">{property.name}</label>
                        <Dropdown
                            key={componentId + key + property.name}
                            fluid
                            multiple
                            defaultValue={this.getComponentStyleProperty(componentId, key)}
                            selection
                            onChange={(e: any, value: any) => {
                                this.updateComponentStyleProperty(componentId, key, value.value);
                            }}
                            options={property.values!} />
                    </div>} />
                </div>;
            case "TEXT":
                return <div key={componentId + key} className="templateProperty">
                    <Popup content={property.description} size="mini" position="right center" trigger={
                    <div>
                        <label className="section label subtext">{property.name}</label>
                        <Input fluid defaultValue={this.getComponentStyleProperty(componentId, key, property.default)} onChange={(e: any) => {
                            this.updateComponentStyleProperty(componentId, key, e.target.value);
                        }} />
                    </div>} />
                </div>;
            case "NUMBER":
                return <div key={componentId + key} className="templateProperty">
                    <Popup content={property.description} size="mini" position="right center" trigger={
                    <div>
                        <label className="section label subtext">{property.name}</label>

                        <Input fluid defaultValue={this.getComponentStyleProperty(componentId, key, property.default)} onKeyPress={(event: any) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); }}} onChange={(e: any) => {
                            this.updateComponentStyleProperty(componentId, key, e.target.value);
                        }}>
                            <input />
                        </Input>
                    </div>} />
                </div>;
            default:
                return <Fragment key={componentId + key}></Fragment>;
        }
    }

    private renderTemplateProperty = (componentId: string, key: string, property: TemplateProperty): React.ReactElement<any> => {
        // does not fully use the default value atm
        switch (property.type) {
            case "BOOLEAN":
                let defaultValue: boolean = false;
                const initialValue = this.getComponentTemplateProperty(componentId, key);
                if (typeof initialValue === "boolean") defaultValue = this.getComponentTemplateProperty(componentId, key, property.default);
                if (initialValue === "true") defaultValue = true;
                return <div key={componentId + key} className="templateProperty">
                    <Popup content={property.description} size="mini" position="right center" trigger={<Checkbox label={<label className="section label subtext">{property.name}</label>} defaultChecked={defaultValue} onChange={(event: any, data: CheckboxProps) => { this.updateComponentTemplateProperty(componentId, key, data.checked); }}/>} />
                </div>;
            case "LIST":
                // todo --> get default value and select it to list
                return <div key={componentId + key} className="templateProperty">
                    <Popup content={property.description} size="mini" position="right center" trigger={
                    <div>
                        <label className="section label subtext">{property.name}</label>
                        <Dropdown
                            key={componentId + key + property.name}
                            fluid
                            selection
                            onChange={(e: any, value: any) => {
                                this.updateComponentTemplateProperty(componentId, key, value.value);
                            }}
                            options={property.values!} />
                    </div>} />
                </div>;
            case "MULTI":
                return <div key={componentId + key} className="templateProperty">
                    <Popup content={property.description} size="mini" position="right center" trigger={
                    <div>
                        <label className="section label subtext">{property.name}</label>
                        <Dropdown
                            key={componentId + key + property.name}
                            fluid
                            multiple
                            defaultValue={this.getComponentTemplateProperty(componentId, key)}
                            selection
                            onChange={(e: any, value: any) => {
                                this.updateComponentTemplateProperty(componentId, key, value.value);
                            }}
                            options={property.values!} />
                    </div>} />
                </div>;
            case "TEXT":
                return <div key={componentId + key} className="templateProperty">
                    <Popup content={property.description} size="mini" position="right center" trigger={
                    <div>
                        <label className="section label subtext">{property.name}</label>
                        <Input fluid defaultValue={this.getComponentTemplateProperty(componentId, key, property.default)} onChange={(e: any) => {
                            this.updateComponentTemplateProperty(componentId, key, e.target.value);
                        }} />
                    </div>} />
                </div>;
            case "NUMBER":
                return <div key={componentId + key} className="templateProperty">
                    <Popup content={property.description} size="mini" position="right center" trigger={
                    <div>
                        <label className="section label subtext">{property.name}</label>

                        <Input fluid defaultValue={this.getComponentTemplateProperty(componentId, key, property.default)} onKeyPress={(event: any) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); }}} onChange={(e: any) => {
                            this.updateComponentTemplateProperty(componentId, key, e.target.value);
                        }}>
                            <input />
                        </Input>
                    </div>} />
                </div>;
            default:
                return <Fragment key={componentId + key}></Fragment>;
        }
    }

    private updateComponentTemplateProperty = (componentId: string, property: string, value: any) => {
        if (this.tableComponentRef && this.tableComponentRef[componentId]) {
            this.tableComponentRef[componentId].templateProperties[property] = value;
        }
        this.onEditing(componentId);
    }

    private updateComponentStyleProperty = (componentId: string, property: string, value: any) => {
        if (this.tableComponentRef && this.tableComponentRef[componentId]) {
            this.tableComponentRef[componentId].styleProperties[property] = value;
        }
        this.onEditing(componentId);
    }

    private getComponentTemplateProperty = (componentId: string, property: string, defaultValue?: any): any => {
        if (this.tableComponentRef && this.tableComponentRef[componentId]) {
            const componentRef = this.tableComponentRef[componentId];
            if (componentRef.templateProperties && componentRef.templateProperties[property]) {
                return componentRef.templateProperties[property];
            }
        }
        return defaultValue ? defaultValue : undefined;
    }

    private getComponentStyleProperty = (componentId: string, property: string, defaultValue?: any): any => {
        if (this.tableComponentRef && this.tableComponentRef[componentId]) {
            const componentRef = this.tableComponentRef[componentId];
            if (componentRef.styleProperties && componentRef.styleProperties[property]) {
                return componentRef.styleProperties[property];
            }
        }
        return defaultValue ? defaultValue : undefined;
    }

    private getTemplate = (templateId: string, templateVersion: number): ComponentTemplate | undefined => {
        // todo(minjae) also get from the server
        if (!templateId) return undefined;
        const templates = Object.entries(template_cache).filter((value: [string, ComponentTemplate]) => value[0] === templateId).map((value: [string, ComponentTemplate]) => value[1]);
        if (templates.length === 0) return undefined;
        const versionIdx: number = templates.findIndex((value: ComponentTemplate) => value.version === templateVersion);
        if (versionIdx > -1) {
            return templates[versionIdx];
        }
        return templates[templates.length - 1]; // just return the version stored returned last (assuming higher version is returned later)
    }

    private getStyle = (styleId: string, styleVersion: number): TemplateStyle | undefined => {
        // todo(minjae) also get from the server
        if (!styleId) return undefined;
        const styles = Object.entries(style_cache).filter((value: [string, TemplateStyle]) => value[0] === styleId).map((value: [string, TemplateStyle]) => value[1]);
        if (styles.length === 0) return undefined;
        const versionIdx: number = styles.findIndex((value: TemplateStyle) => value.version === styleVersion);
        if (versionIdx > -1) {
            return styles[versionIdx];
        }
        return styles[styles.length - 1]; // just return the version stored returned last (assuming higher version is returned later)
    }

    private renderComponentActions = (componentId: string): React.ReactElement<any> => {
        const project: Project = this.props.state.projectState.detailedProjects[this.projectId];
        const currentComponent = project.tableViewComponents.find((tableView: TableView) => tableView._id === componentId);
        const currentPublishedVersion = this.props.state.tableViewState.publishedVersion[componentId];
        let canPublish: boolean = false;
        if (currentComponent && (currentComponent.version !== currentPublishedVersion)) canPublish = true;
        return <span>
            <Confirm
                open={this.state.publishComponentConfirmDialogId === componentId}
                content={this.getString({ id: "project.component.publish.dialog.text" })}
                onCancel={() => this.clearPublishComponentConfirmDialog()}
                onConfirm={() => {this.publishComponent(componentId); this.clearPublishComponentConfirmDialog(); }}
            />
            <Button.Group labeled icon widths="2">
                <Button icon="save" content="Save" color="orange" onClick={() => this.saveComponent(componentId)} disabled={this.state.dirty[componentId] ? false : true} />
                <Button icon="play" content="Publish" color="orange" onClick={() => this.openPublishComponentDialog(componentId)} disabled={this.state.dirty[componentId] || canPublish ? false : true} />
            </Button.Group>
        </span>;
    }

    private saveComponent = (componentId: string) => {
        const tableView: TableView = refToTableView(this.tableComponentRef[componentId]);
        this.props.actions.editTableView(this.projectId, tableView, false);
        const dirtyMap = this.state.dirty;
        dirtyMap[componentId] = false;
        this.setState({
            dirty: dirtyMap
        });
    }
    private publishComponent = (componentId: string) => {
        const tableView: TableView = refToTableView(this.tableComponentRef[componentId]);
        console.log(tableView);
        this.props.actions.editTableView(this.projectId, tableView, true);
        const dirtyMap = this.state.dirty;
        dirtyMap[componentId] = false;
        this.setState({
            dirty: dirtyMap
        });
    }

    private openPublishComponentDialog = (componentId: string) => {
        this.setState({
            publishComponentConfirmDialogId: componentId
        });
        this.forceUpdate();
    }
    private clearPublishComponentConfirmDialog = () => {
        this.setState({
            publishComponentConfirmDialogId: ""
        });
        this.forceUpdate();
    }

    private openDeleteComponentDialog = (componentId: string) => {
        this.setState({
            deleteComponentConfirmDialogId: componentId
        });
        this.forceUpdate();
    }
    private clearDeleteComponentConfirmDialog = () => {
        this.setState({
            deleteComponentConfirmDialogId: ""
        });
        this.forceUpdate();
    }

    private showNestedRouteStep = (routeId: string): React.ReactElement<any> => {
        this.currentPageId = routeId;
        return <Fragment></Fragment>;
    }

    private renderDashboard = (): React.ReactElement<any> => {
        const project: Project = this.props.state.projectState.detailedProjects[this.projectId];
        return <div className={this.hideClassUnspecifiedStep("dashboard")}>
            <p></p>
            <Header size="large">Project Dashboard: {project.title}</Header>
            <p></p>
            <ViewCountComponent pageType={CallPage.TableView} title={this.getString({id: "project.component.dashboard.traffic"})} key={this.projectId} showReload={true} projectId={this.projectId} componentId="" ></ViewCountComponent>
            <Grid>
                {
                    project.tableViewComponents.map((value: TableView, index: number) => {
                        return <Grid.Row columns={1} key={"component.dashboard." + index}>
                            <Grid.Column width={16}>
                                <Header size="large">{value.title}</Header>
                                <ViewCountComponent pageType={CallPage.TableView} title={this.getString({id: "project.component.dashboard.component.traffic"})} key={this.projectId + ".component." + index} showReload={true} projectId={this.projectId} componentId={value.tableViewId} ></ViewCountComponent>
                            </Grid.Column>
                        </Grid.Row>;
                    })
                }
            </Grid>
        </div>;
    }

    private renderSettings = (): React.ReactElement<any> => {
        return <div className={this.hideClassUnspecifiedStep("settings")}>
            <p></p>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            <FormattedMessage id="project.delete" />
                        </span>
                        <span className="section label subtext">
                            <FormattedMessage id="project.delete.description" />
                        </span>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <div>
                            <FormattedMessage id="project.delete.text" />
                        </div>
                        <p></p>

                        <Confirm
                            open={this.state.openDeleteProjectConfirmDialog}
                            content={this.getString({ id: "project.delete.dialog.text" })}
                            onCancel={() => this.setState({openDeleteProjectConfirmDialog: false})}
                            onConfirm={() => { this.removeThisProject(); }}
                        />
                        <Button negative icon="trash" content="Delete Project" onClick={() => this.setState({openDeleteProjectConfirmDialog: true})} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>;
    }

    private removeThisProject = () => {
        this.props.actions.removeProject(this.projectId);
        this.setState({openDeleteProjectConfirmDialog: false});
        this.props.history.push("/", this.props.location.state);
        this.forceUpdate();
    }

    private renderComponentView = (): React.ReactElement<any> => {
        const project: Project = this.props.state.projectState.detailedProjects[this.projectId];
        return <Fragment> {
            project.tableViewComponents.map((tableView: TableView, index: number) => {
                const panes = [
                    { menuItem: this.getString({ id: "javascript" }),
                    render: () => <Tab.Pane>
                        <p></p>
                        First, add following line at the END of your &lt;BODY&gt; tag.
                        <SyntaxHighlighter language="javascript" style={vscDarkPlus}>
                            { "<script src=\"https://airjam-co.github.io/table-view/dist/library.min.js\"></script>\n" }
                        </SyntaxHighlighter>
                        <p></p>
                        Then, add follow line to the part of your webpage where you would like your component to render
                        <SyntaxHighlighter language="javascript" style={vscDarkPlus}>
                            { "<div display=\"airjam-tableview\" id=\"" + tableView.tableViewId + "\"></div>" }
                        </SyntaxHighlighter>
                    </Tab.Pane> },
                    { menuItem: this.getString({ id: "react" }),
                    render: () => <Tab.Pane>
                        For NPM:
                        <SyntaxHighlighter language="typescript" style={vscDarkPlus}>
                            { "npm install @airjam/table-view\n" }
                        </SyntaxHighlighter>
                        <p></p>
                        For Yarn:
                        <SyntaxHighlighter language="typescript" style={vscDarkPlus}>
                            { "yarn add @airjam/react-table-view" }
                        </SyntaxHighlighter>
                        <p></p>
                        Then, add follow line to the part of your react page where you would like your component to render
                        <SyntaxHighlighter language="typescript" style={vscDarkPlus}>
                            { "import { TableView } from \"@airjam/react-table-view\";\n" +
                              "<TableView id=\"rHLOWVGc\" />" }
                        </SyntaxHighlighter>
                    </Tab.Pane> },
                ];

                const componentRef: TableViewRef | undefined = this.tableComponentRef[tableView._id];
                if (!componentRef) {
                    return <Fragment key={"fragment_view_" + tableView._id}></Fragment>;
                }
                return <Grid.Column width={11} key={"view_" + tableView._id} className={"wizardContent " + this.hideClassUnspecifiedStep("view_" + tableView._id)}>
                    <p></p>
                    <Header size="large">{tableView.title}</Header>
                    <span>{this.getString({ id: "page.project.wizard.configure.description" }, { title: this.refValueOrDefault(componentRef.title, tableView.title) })}</span>
                    <p>&nbsp;</p>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <span className="section label">
                                    <FormattedMessage id="project.component.instruction" />
                                </span>
                                <span className="section label subtext">
                                    <FormattedMessage id="project.component.instruction.description" />
                                </span>
                            </Grid.Column>
                            <Grid.Column width={11}>

                                <Tab panes={panes} menu={{ inverted: false, attached: true, tabular: true }} />

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <span className="section label">
                                    <FormattedMessage id="project.component.delete" />
                                </span>
                                <span className="section label subtext">
                                    <FormattedMessage id="project.component.delete.description" />
                                </span>
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <div>
                                    <FormattedMessage id="project.component.delete.text" />
                                </div>
                                <p></p>

                                <Confirm
                                    open={this.state.deleteComponentConfirmDialogId === tableView._id}
                                    content={this.getString({ id: "project.component.delete.dialog.text" })}
                                    onCancel={() => this.clearDeleteComponentConfirmDialog()}
                                    onConfirm={() => {this.props.actions.removeTableView(this.projectId, tableView._id); this.clearDeleteComponentConfirmDialog(); }}
                                />
                                <Button negative icon="trash" content="Delete Component" onClick={() => this.openDeleteComponentDialog(tableView._id)} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    </Grid.Column>;
            })
        }
        </Fragment>;
    }

    private renderComponentData = (): React.ReactElement<any> => {
        const project: Project = this.props.state.projectState.detailedProjects[this.projectId];
        return <Fragment> {
            project.tableViewComponents.map((tableView: TableView, index: number) => {
                const componentRef: TableViewRef | undefined = this.tableComponentRef[tableView._id];
                if (!componentRef) {
                    return <Fragment key={"fragment_data_" + tableView._id}></Fragment>;
                }

                return <Grid.Column width={11} key={"data_" + tableView._id} className={"wizardContent " + this.hideClassUnspecifiedStep("data_" + tableView._id)}>
                    <p></p>
                    <Header size="large">{this.getString({ id: "page.project.wizard.configure.title" }, { title: this.refValueOrDefault(componentRef.title, tableView.title) })}</Header>
                    <span>{this.getString({ id: "page.project.wizard.configure.description" }, { title: this.refValueOrDefault(componentRef.title, tableView.title) })}</span>
                    <p>&nbsp;</p>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <span className="section label">
                                    <FormattedMessage id="data.source.spreadsheet.component.title.title" />
                                </span>
                                <span className="section label subtext">
                                    <FormattedMessage id="data.source.spreadsheet.component.title" />
                                </span>
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <span className="ui form">
                                    <input autoFocus={true} defaultValue={tableView.title} ref={componentRef.title}/>
                                </span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <span className="section label">
                                    <FormattedMessage id="data.source.spreadsheet.select.title" />
                                </span>
                                <span className="section label subtext">
                                    <FormattedMessage id="data.source.spreadsheet.select" />
                                </span>
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <GoogleSheetDocumentSelector {...this.props} onChange={this.sheetSelectionChanged} componentId={tableView._id} initialKey={componentRef.currentSheetKey} key={"doc.selector." + tableView._id} />
                                {
                                    tableView.sheetDocumentId ?
                                    <a className="openLink" target="_blank" rel="noreferrer" href={"https://docs.google.com/spreadsheets/d/" + tableView.sheetDocumentId}>
                                        <i className="fa-solid fa-arrow-up-right-from-square"></i>&nbsp;
                                        <FormattedMessage id="data.source.open" />
                                    </a> : <Fragment></Fragment>
                                }
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <span className="section label">
                                    <FormattedMessage id="data.source.spreadsheet.select.sheet.title" />
                                </span>
                                <span className="section label subtext">
                                    <FormattedMessage id="data.source.spreadsheet.select.sheet" />
                                </span>
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <GoogleSheetSheetSelector {...this.props} componentId={tableView._id}  sheetId={componentRef.currentSheetKey} onChange={this.sheetSheetSelectionChanged} initialSheetSelection={componentRef.currentSheetSelection} key={"sheet.selector." + tableView._id} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <span className="section label">
                                    <FormattedMessage id="data.source.spreadsheet.configure.title" />
                                </span>
                                <span className="section label subtext">
                                    <FormattedMessage id="data.source.spreadsheet.configure" />
                                </span>
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <GoogleSheetSheetFieldConfiguration {...this.props} componentId={tableView._id} sheetId={componentRef.currentSheetKey} sheetKey={componentRef.currentSheetSelection} originalFieldMapping={componentRef.fieldMappingResult} onChange={this.fieldMappingChanged} key={"field.selector." + tableView._id} hideExample={true} />
                                <p>&nbsp;</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>;
            })
        }
        </Fragment>;
    }
    private renderComponentStyle = (): React.ReactElement<any> => {
        const project: Project = this.props.state.projectState.detailedProjects[this.projectId];
        return <Fragment> {
            project.tableViewComponents.map((tableView: TableView, index: number) => {
                const componentRef: TableViewRef | undefined = this.tableComponentRef[tableView._id];
                if (!componentRef) {
                    return <Fragment key={"fragment_style_" + tableView._id}></Fragment>;
                }
                return <Grid.Column width={11} key={"style_" + tableView._id} className={"wizardContent " + this.hideClassUnspecifiedStep("style_" + tableView._id)}>
                    <p></p>
                    <Header size="large">{this.getString({ id: "page.project.wizard.look_and_feel.title" }, { title: this.refValueOrDefault(componentRef.title, tableView.title) })}</Header>
                    <span><FormattedMessage id="page.project.wizard.look_and_feel.description" /></span>
                    <p>&nbsp;</p>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <span className="section label">
                                    <FormattedMessage id="project.component.style.view_type" />
                                </span>
                                <span className="section label subtext">
                                    <FormattedMessage id="project.component.style.view_type.description" />
                                </span>
                            </Grid.Column>
                            <Grid.Column width={11}><ViewTypeSelect key={"view_type_" + tableView._id} componentId={tableView._id} initialType={tableView.type} onChange={this.viewTypeSelectionChanged}></ViewTypeSelect></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <span className="section label">
                                    <FormattedMessage id="project.component.style.template" />
                                </span>
                                <span className="section label subtext">
                                    <FormattedMessage id="project.component.style.template.description" />
                                </span>
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <TemplateSelect key={"template_select_" + tableView._id} componentId={tableView._id} selectedViewType={componentRef.type} initialTemplate={tableView.templateId} onChange={this.templateSelectionChanged}></TemplateSelect>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <span className="section label">
                                    <FormattedMessage id="project.component.style.style" />
                                </span>
                                <span className="section label subtext">
                                    <FormattedMessage id="project.component.style.style.description" />
                                </span>
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <StyleSelect key={"style_select_" + tableView._id} componentId={tableView._id} selectedTemplateId={componentRef.templateId} initialStyle={tableView.styleId} onChange={this.styleSelectionChanged}></StyleSelect>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <span className="section label">
                                    <FormattedMessage id="project.component.style.template_mapping" />
                                </span>
                                <span className="section label subtext">
                                    <FormattedMessage id="project.component.style.template_mapping.description" />
                                </span>
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <TemplateFieldConfiguration key={"template_field_config_" + tableView._id} componentId={tableView._id} selectedTemplateId={componentRef.templateId} fieldMapping={componentRef.fieldMappingResult} initialTemplateFieldMapping={tableView.templateFields} onChange={this.templateMappingChanged}></TemplateFieldConfiguration>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className={this.paginationStyleApplicable(componentRef) ? "" : "hideImportant"}>
                            <Grid.Column width={5}>
                                <span className="section label">
                                    <FormattedMessage id="project.component.style.pagination" />
                                </span>
                                <span className="section label subtext">
                                    <FormattedMessage id="project.component.style.pagination.description" />
                                </span>
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <Dropdown
                                    placeholder={this.getString({ id: "component.table.view.pagination_style_select" })}
                                    key={"pagination_" + tableView._id}
                                    fluid
                                    selection
                                    defaultValue={tableView.paginationStyle}
                                    onChange={(e: any, value: any) => {
                                        componentRef.currentPaginationStyle = value.value;
                                    }}
                                    options={paginationStyles(this.props)}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <span className="section label">
                                    <FormattedMessage id="project.component.style.sort_by" />
                                </span>
                                <span className="section label subtext">
                                    <FormattedMessage id="project.component.style.sort_by.description" />
                                </span>
                            </Grid.Column>
                            <Grid.Column width={11}>
                                <Dropdown
                                    placeholder={this.getString({ id: "component.dropdown.sort_by_select" })}
                                    key={"sort_by_" + tableView._id}
                                    fluid
                                    selection
                                    defaultValue={tableView.sortBy}
                                    onChange={(e: any, value: any) => {
                                        componentRef.currentSortBy = value.value;
                                    }}
                                    options={sortByOptions(this.props)}
                                />
                                <p>&nbsp;</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>;
            })
        }
        </Fragment>;
    }
    private isAuthorOf = (project: Project): boolean => {
        return project.ownerId === (
            this.props.state.userState.currentUser &&
            this.props.state.userState.currentUser._id);
    }

    private paginationStyleApplicable = (tableView: TableViewRef): boolean => {
        const viewType = TableViewViewType[tableView.type as keyof typeof TableViewViewType];
        switch (viewType) {
            case TableViewViewType.List:
            case TableViewViewType.Gallery:
            case TableViewViewType.Map:
                return true;
        }
        return false;
    }
    private sheetSelectionChanged = (componentId: string, newKey: string) => {
        if (this.tableComponentRef[componentId]) {
            this.tableComponentRef[componentId].currentSheetKey = newKey;
            this.tableComponentRef[componentId].currentSheetSelection = ""; // reset sheet selection when doc changes
            this.tableComponentRef[componentId].fieldMapping = React.createRef();
        }
        this.onEditing(componentId);
    }

    private sheetSheetSelectionChanged = (componentId: string, newSheet: string) => {
        this.tableComponentRef[componentId].currentSheetSelection = newSheet;
        this.onEditing(componentId);
    }

    private fieldMappingChanged = (componentId: string, key: string, newMapping: {[id: string]: DataSourceField}, example: any) => {
        this.tableComponentRef[componentId].fieldMappingResult = newMapping;
        this.tableComponentRef[componentId].exampleData = example;
        this.onEditing(componentId);
    }

    private viewTypeSelectionChanged = (componentId: string, newType: string) => {
        this.tableComponentRef[componentId].type = newType;
        this.onEditing(componentId);
    }

    private templateSelectionChanged = (componentId: string, newTemplate: string, newTemplateVersion: number) => {
        this.tableComponentRef[componentId].templateId = newTemplate;
        this.tableComponentRef[componentId].templateVersion = newTemplateVersion;
        this.onEditing(componentId);
    }

    private styleSelectionChanged = (componentId: string, newStyle: string, newVersion: number) => {
        this.tableComponentRef[componentId].styleId = newStyle;
        this.tableComponentRef[componentId].styleVersion = newVersion;
        this.onEditing(componentId);
    }

    private templateMappingChanged = (componentId: string, templateMapping: {[id: string]: string}) => {
        this.tableComponentRef[componentId].templateFieldMapping = templateMapping;
        this.onEditing(componentId);
    }

    private onEditing = (componentId: string) => {
        const dirtyStates = this.state.dirty;
        dirtyStates[componentId] = true;
        this.setState({
            dirty: dirtyStates
        });
        this.forceUpdate();
    }

    private renderDeleteWarningModal = (project: Project): React.ReactElement<any> | undefined => {
        return this.isAuthorOf(project) ?
            <WarningModal
                descriptionIcon="delete" open={this.state.openDeleteWarning}
                descriptionText={this.getString({id: "page.project.delete"}, {title: project.title})}
                warningText={this.getString({id: "page.project.delete_confirmation"})}
                onConfirm={this.removeProject}
                onCancel={ () => {this.setState({openDeleteWarning: false}); }}/>
                : undefined;
    }
    private hideClassUnspecifiedStep = (step: string): string => {
        if (step === this.currentPageId) return "";
        return "hideImportant";
    }
    private refValueOrDefault(ref: RefObject<HTMLInputElement>, defaultStr: string): string {
        if (ref && ref.current) return ref.current.value;
        return defaultStr;
    }
    private fetchProjectIfNeeded = () => {
        if (this.projectId && !this.props.state.projectState.detailedProjects[this.projectId]) {
            this.props.actions.getProject(this.projectId, this.props.actions);
        }
    }
    private removeProject = (): void => {
        this.props.actions.removeProject(this.projectId);
    }
}

export default connectAllProps(ProjectDetail);