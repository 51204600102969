import React, { Fragment } from "react";
import connectAllProps from "../../../shared/connect";
import { Link, Redirect } from "react-router-dom";
import { pendingRedirect } from "../../../shared/redirect";
import ErrorPage from "../../pages/ErrorPage";
import { Header, Icon, Label, Segment, Tab } from "semantic-ui-react";
import "react-tiny-fab/dist/styles.css";
import { MessageDescriptor, FormattedMessage } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import WarningModal from "../shared/WarningModal";
import FabAction from "../../../models/client/FabAction";
import moment from "moment";
import { ComponentProps as Props } from "../../../shared/ComponentProps";
import { Uploader } from "../../../models/Uploader";
import ViewCountComponent from "./ViewCountComponent";
import { getToast as toast } from "../../../shared/toast";
import { CallPage, PageActionType } from "../../../models/CallType";
import BucketBrowser from "./BucketBrowser";
import UploaderEditor from "./UploaderEditor";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";

interface States {
    openDeleteWarning: boolean;
}

class UploaderDetail extends React.Component<Props, States> {
    private _isMounted: boolean = false;
    private uploaderId: string = "";
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.uploaderId = this.props.match && this.props.match.params && this.props.match.params.uploaderId;
        this.getString = this.props.intl.formatMessage;
        this.state = {
            openDeleteWarning: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
        if (this.uploaderId) {
            this.addFabActions();
        }
        this.props.actions.resetRedirectTask();
        window.scrollTo(0, 0);
    }
    componentDidUpdate(prevProps: Props) {
        if ((prevProps.state.uploaderState.loading
            && !this.props.state.uploaderState.loading) ||
            (!prevProps.state.userState.currentUser
            && this.props.state.userState.currentUser)) {
            this.addFabActions();
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.props.actions.setFabActions([]);
    }
    render(): React.ReactElement<any> {
        if (pendingRedirect(this.props)) {
            return <Redirect to={this.props.state.redirectTask.to} />;
        }
        const notFoundError: Error = {
            name: "404 Not Found",
            message: `not found for ${window.location.href} `
        };
        if (!this.uploaderId) {
            return <ErrorPage error={notFoundError} />;
        }
        const uploader: Uploader | undefined = this.props.state.uploaderState.data.find(
            (value: Uploader): boolean => value._id === this.uploaderId
        );
        if (!uploader) {
            return <ErrorPage error={notFoundError} />;
        }
        const createDate: Date = uploader.createdAt ? new Date(uploader.createdAt) : new Date(0);
        const labelStyle: any = {
            color: "grey",
            marginTop: 2,
            marginBottom: 2
        };
        const panes = [
            { menuItem: "Files", render: () => <Tab.Pane>
                <BucketBrowser id={this.uploaderId} key={this.uploaderId + "-bucket-browser"} showReload={true} />
            </Tab.Pane> },
            { menuItem: "Settings", render: () => <Tab.Pane>
                { this.renderSettingsPane(uploader) }
            </Tab.Pane> },
            { menuItem: "Monitoring", render: () => <Tab.Pane>
                <ViewCountComponent pageType={CallPage.Uploader} title="Upload Count" dataLabel="uploads" actionType={PageActionType.UploadFile} key={this.uploaderId + "-filecount"} showReload={true} id={this.uploaderId} ></ViewCountComponent>
                <ViewCountComponent pageType={CallPage.Uploader} title="Bytes Added" dataLabel="bytes" actionType={PageActionType.IngressSize} key={this.uploaderId + "-ingress"} showReload={true} id={this.uploaderId} ></ViewCountComponent>
                <ViewCountComponent pageType={CallPage.Uploader} title="Bytes Removed" dataLabel="bytes" actionType={PageActionType.OutgressSize} key={this.uploaderId + "-outgress"} showReload={true} id={this.uploaderId} ></ViewCountComponent>
            </Tab.Pane> },
            { menuItem: "Set-up / Installation", render: () => <Tab.Pane>
                        <Segment>
                            <Header as="h4" id="setup">Sample Project</Header>
                            Firebase Auth Sample: <a href="/samples/uploader-sample-v1.zip" target="_blank"><Icon name="file" /> uploader-sample-v1.zip</a><br />
                            Clerk Auth Sample: <a href="/samples/uploader-clerk-react.zip" target="_blank"><Icon name="file" /> uploader-clerk-react.zip</a><br /><br />
                            Click the link above to download a sample project for uploader component, written in React.JS.
                        </Segment>
                        <Header as="h3" id="setup">Setup</Header>
                        <Tab panes={[
                            {menuItem: "npm", render: () => <Tab.Pane>
                                <SyntaxHighlighter language="javascript" style={vs2015}>
                                    { "npm install @airjam/react-uploader@latest" }
                                </SyntaxHighlighter>
                            </Tab.Pane>},
                            {menuItem: "yarn", render: () => <Tab.Pane>
                                <SyntaxHighlighter language="javascript" style={vs2015}>
                                    { "yarn add @airjam/react-uploader@latest" }
                                </SyntaxHighlighter>
                            </Tab.Pane>}
                        ]} menu={{ inverted: false, attached: true, tabular: true }} />
                        <Header as="h3" id="usage">Usage</Header>
                        Paste the code below to start using your upload bucket.<br />
                        <SyntaxHighlighter language="javascript" style={vs2015}>
                            {  "import { Uploader } from '@airjam/react-uploader';\n...\n" +
                            '<Uploader id="' + uploader.uploaderId + '" uploaderName={username} />' }
                        </SyntaxHighlighter>
                        'username' is a unique id of the user that you provide to the uploader component to identify who is uploading the files
                        <Header as="h3" id="props">Props and Callbacks</Header>
                        <ul>
                            <li><code>id</code> - <b>(REQUIRED)</b> bucket id of the uploader component.</li>
                            <li><code>uploaderName</code> - (Optional) Custom name of the user uploading the data object. This name is used as the name for the folder that stores the uploaded data.</li>
                            <li><code>authToken</code> - (Optional) User-level authentication token to pass to authentication service. This is a required field if you are using an authentication provider (such as Clerk or Firebase Authentication) </li>
                            <li>
                                <code>sessionId</code> - (Optional) Additional Session ID to provide authentication provider (such as clerk)
                                <SyntaxHighlighter language="javascript" style={vs2015}>
                                    { 'import { Uploader } from "@airjam/react-uploader";\nimport { SignedIn, useAuth } from "@clerk/clerk-react";\n\nfunction App() {\n  const [authToken, setAuthToken] = React.useState();\n  const { isLoaded, userId, sessionId, getToken } = useAuth();\n  if (isLoaded && userId && !authToken) {\n    getToken().then((token) => {\n      setAuthToken(token);\n    });\n  }\n\n  return (\n    <div className="app">\n      <SignedIn>\n        <Uploader id="<your-uploader-id>" authToken={authToken} sessionId={sessionId} uploaderName={userId} ></Uploader>\n      </SignedIn>\n    </div>\n  );\n}' }
                                </SyntaxHighlighter>
                            </li>
                            <li>
                                <code>onRemove</code> - This is an event that gets fired when a user removes a file.
                                <SyntaxHighlighter language="javascript" style={vs2015}>
                                    { '<Uploader id="<your-uploader-id>" onRemove={removed} />\n...\n function removed(removed) {\n  console.log("File(s) are removed");\n  removed.forEach(file => {\n    console.log("File unique id: " + file.id);\n    console.log("Publicly accessible url for the file: " + file.url);\n  });\n}\n' }
                                </SyntaxHighlighter>
                            </li>
                            <li>
                                <code>onAdd</code> - This is an event that gets fired when a user adds a file.
                                <SyntaxHighlighter language="javascript" style={vs2015}>
                                    { '<Uploader id="<your-uploader-id>" onAdd={added} />\n...\n function added(added) {\n  console.log("File(s) are added");\n  added.forEach(file => {\n    console.log("File unique id: " + file.id);\n    console.log("Publicly accessible url for the file: " + file.url);\n  });\n}\n' }
                                </SyntaxHighlighter>
                            </li>
                        </ul>
                        <Header as="h3" id="usage">Tracking uploaded files</Header>
                        AirJam's uploader component tracks uploaded files with an &lt;input&gt; element with ID being the specified bucket ID. If you are using &lt;form&gt; element, wrap &lt;form&gt; tag around the &lt;uploader&gt; tag and your form will be auto populated with the uploaded files and their filenames.
                        For more flexibility, use the onAdd and onRemove callbacks to track the uploaded files manually.
                        <Header as="h3" id="configuration">Configuration</Header>
                        Click on the 'settings' tab to configure your uploader component.
            </Tab.Pane> },
        ];
        return (
            <Fragment>
                <div style={{padding: 20}} >
                    <Header size={"medium"}>
                        <Link to="/uploaders"><Icon name="home" /></Link>
                        {uploader.name}
                    </Header>
                    <Label style={labelStyle} color="black">
                        <FormattedMessage id="post.created_at" />
                        {moment(createDate).fromNow()}
                    </Label>
                    <Label style={labelStyle} color="black">
                        id: { uploader.uploaderId } &nbsp; <Icon style={{cursor: "pointer"}} name="copy" onClick={() => {
                            navigator.clipboard.writeText(uploader.uploaderId);
                            toast().success("toast.text.copied");
                        }}/>
                    </Label>
                    <div>&nbsp; </div>
                    <Tab menu={{ inverted: false, attached: true, tabular: true }} panes={panes} />
                </div>
                {
                    this.renderDeleteWarningModal(uploader)
                }
            </Fragment>
        );
    }
    private isAuthorOf = (uploader: Uploader): boolean => {
        return uploader.ownerId === (
            this.props.state.userState.currentUser &&
            this.props.state.userState.currentUser._id);
    }
    private addFabActions = (): void => {
        const uploader: Uploader | undefined = this.props.state.uploaderState.data.find(
            (value: Uploader): boolean => value._id === this.uploaderId
        );
        if (!uploader) {
            return;
        }
        if (this.isAuthorOf(uploader)) {
            const actions: FabAction[] = [{
                text: this.getString({id: "component.button.delete"}),
                icon: "trash alternate",
                onClick: () => { this.setState({openDeleteWarning: true }); },
            }, {
                text: this.getString({id: "component.button.edit"}),
                icon: "edit",
                onClick: () => {
                    const target: string = this.props.match.url.replace(/^(.+)(\/[0-9a-z]+$)/, "$1/edit$2");
                    this.props.history.push(target, this.props.location.state);
                },
            }];
            this.props.actions.setFabActions(actions);
        }
    }
    private renderDeleteWarningModal = (uploader: Uploader): React.ReactElement<any> | undefined => {
        return this.isAuthorOf(uploader) ?
            <WarningModal
                descriptionIcon="delete" open={this.state.openDeleteWarning}
                descriptionText={this.getString({id: "page.uploader.delete"}, {name: uploader.name})}
                warningText={this.getString({id: "page.uploader.delete_confirmation"})}
                onConfirm={this.removeUploader}
                onCancel={ () => {this.setState({openDeleteWarning: false}); }}/>
                : undefined;
    }

    private renderSettingsPane = (uploader: Uploader): React.ReactElement<any> => {
        return <div>
            <UploaderEditor uploader={uploader}
                submitTextId="component.button.update"
                onSubmit={this.editUploader}
                loading={this.props.state.uploaderState.loading} />
        </div>;
    }
    private removeUploader = (): void => {
        this.props.actions.removeUploader(this.uploaderId);
    }
    private editUploader = (uploader: Uploader): void => {
        if (this.props.state.userState.currentUser) {
            this.props.actions.updateUploader(uploader);
        }
    }
}

export default connectAllProps(UploaderDetail);