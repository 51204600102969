import React, { ChangeEvent, Fragment } from "react";
import connectAllProps from "../../shared/connect";
import EditorType from "../../models/EditorType";
import { Redirect } from "react-router";
import { Container, Form, Radio, Header, Modal, Confirm, Sidebar, Menu, Icon, Segment, Grid } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../shared/styles";
import User from "../../models/User";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import { DEFAULT_PREFERENCES } from "../../shared/preferences";
import { ComponentProps as Props } from "../../shared/ComponentProps";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripePaymentElement from "../components/components/StripePaymentElement";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import PaymentData from "../../models/PaymentData";
import { STRIPE_PUBLIC_KEY } from "../../shared/constants";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

interface States {
    selectedEditorType: EditorType;
    editorTypeChanged: boolean;
    addPaymentVisible: boolean;
    cardSidebarVisibleId: string;
    visiblePrimaryCardDialogId: string;
    visibleDeleteCardDialogId: string;
}

const stripeBrandToCardBrandMap: {[stripeBrand: string]: string} = {
    "amex": "amex",
    "diners": "dinersclub",
    "discover": "discover",
    "jcb": "jcb",
    "mastercard": "mastercard",
    "unionpay": "unionpay",
    "visa": "visa"
};

class Preferences extends React.Component<Props, States> {
    getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.getString = this.props.intl.formatMessage;
        this.state = {
            selectedEditorType: DEFAULT_PREFERENCES.editorType,
            editorTypeChanged: false,
            addPaymentVisible: false,
            cardSidebarVisibleId: "",
            visiblePrimaryCardDialogId: "",
            visibleDeleteCardDialogId: ""
        };
    }

    componentDidMount() {
        if (this.props.state.userState.currentUser &&
            this.props.state.userState.currentUser.preferences &&
            this.props.state.userState.currentUser.preferences.editorType) {
            this.setState({selectedEditorType: this.props.state.userState.currentUser.preferences.editorType});
        }
        if (!this.props.state.userState.stripeClientSecret) {
            this.props.actions.loadStripeIntent();
        }
        if (!this.props.state.userState.stripeCardData || this.props.state.userState.stripeCardData.length === 0) {
            this.props.actions.getPaymentMethods();
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.state.userState.loading && !this.props.state.userState.loading) {
            this.setState({editorTypeChanged: false});
        }
        if (prevProps.state.userState.currentUser && this.props.state.userState.currentUser) {
            if (prevProps.state.userState.currentUser.primaryCard !== this.props.state.userState.currentUser.primaryCard) {
                this.forceUpdate();
            } else if (prevProps.state.userState.stripeCardData.length !== this.props.state.userState.stripeCardData.length) {
                this.forceUpdate();
            }
        }
    }

    render(): React.ReactElement<any> {
    if (this.props.state.userState.currentUser) {
            const loading: boolean = this.props.state.userState.loading;
            let stripePayments = <Fragment></Fragment>;
            if (this.props.state.userState.stripeClientSecret) {
                stripePayments = <Elements stripe={stripePromise} options={{
                    clientSecret: this.props.state.userState.stripeClientSecret,
                    appearance: { theme: "flat" },
                    }}>
                <StripePaymentElement {...this.props} onClose={() => this.closeAddCardWindow()} />
                </Elements>;
            }
            return (<Container text style={CONTAINER_STYLE}>
                <Header size={"medium"}>
                    <FormattedMessage id="page.me.payment_methods"/>
                </Header>
                <span className="header subtext"><FormattedMessage id="page.me.payment_methods.description"/></span>
                <div>&nbsp;</div>

                <Segment className="hollowSegment" compact>
                    <Grid columns={3} verticalAlign="middle" onClick={() => this.setState({addPaymentVisible : true})}>
                        <Grid.Row>
                            <Grid.Column width="3">
                                <i className="fas fa-credit-card fa-2x"></i>
                            </Grid.Column>
                            <Grid.Column width="10">
                                <Header size="small">
                                    <FormattedMessage id="preferences.dialog.add_card.button" />
                                </Header>
                            </Grid.Column>
                            <Grid.Column width="3" textAlign="center">
                                <span className="more">+</span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Modal
                    closeIcon
                    dimmer="inverted"
                    open={this.state.addPaymentVisible}
                    onClose={() => this.setState({addPaymentVisible : false})}>
                    <Header content={this.getString({id: "preferences.dialog.add_card.title"})} />
                    <Modal.Content>
                        {stripePayments}
                    </Modal.Content>
                </Modal>

                {this.props.state.userState.stripeCardData.map((paymentData: PaymentData) => {
                    return this.renderCard(paymentData);
                })}
                {/* <Form>
                    <Form.Group inline>
                        <label>
                            <FormattedMessage id="preferences.editor_type"/>
                        </label>
                            {
                                Object.values(EditorType).map((value: string) => this.renderEditorTypeRadio(value))
                            }
                    </Form.Group>
                    <Button color="orange" type="submit" onClick={ this.update }
                        loading={loading} disabled={loading || !this.isPreferencesChanged()}>
                        <Icon name="check circle outline" />
                        <FormattedMessage id="component.button.submit"/>
                    </Button>
                </Form> */}
            </Container>);
        } else {
            return <Redirect to="/login" />;
        }
    }
    private closeAddCardWindow = () => {
        this.setState({addPaymentVisible : false});
    }
    private isPreferencesChanged = (): boolean => {
        return this.state.editorTypeChanged;
    }
    private showPrimaryCardDialog = (id: string) => {
        this.setState({visiblePrimaryCardDialogId : id});
    }
    private closePrimaryCardDialog = () => {
        this.setState({
            visiblePrimaryCardDialogId: "",
            cardSidebarVisibleId: ""
        });
    }
    private showDeleteCardDialog = (id: string) => {
        this.setState({visibleDeleteCardDialogId : id});
    }
    private closeDeleteCardDialog = () => {
        this.setState({visibleDeleteCardDialogId: ""});
    }
    private makePrimaryCard = (id: string) => {
        this.props.actions.setPrimaryCard(id);
        this.closePrimaryCardDialog();
    }

    private deleteCard = (id: string) => {
        this.props.actions.deleteCard(id);
        this.closeDeleteCardDialog();
        this.setState({cardSidebarVisibleId: ""});
    }

    private toggleCardSidebar = (id: string) => {
        if (this.state.cardSidebarVisibleId === id) {
            this.setState({cardSidebarVisibleId: ""});
        } else {
            this.setState({cardSidebarVisibleId: id});
        }
        this.forceUpdate();
    }
    private renderCard = (paymentData: PaymentData): React.ReactElement<any> => {
        const isPrimaryCard: boolean = paymentData.id === this.props.state.userState.currentUser?.primaryCard;
        return <div
            key={"card_" + paymentData.id}
            onClick={() => this.toggleCardSidebar(paymentData.id)}
            className={isPrimaryCard ? "card primary" : "card"}>
            <div className={"cardRibbon " + (isPrimaryCard ? "" : "hidden")}>primary</div>
            <Confirm
                open={this.state.visiblePrimaryCardDialogId === paymentData.id}
                onCancel={this.closePrimaryCardDialog}
                content={this.getString({id: "preferences.dialog.add_primary_card.confirm"}, { ending: paymentData.last4 })}
                cancelButton={this.getString({id: "no"})}
                confirmButton={this.getString({id: "yes"})}
                onConfirm={() => this.makePrimaryCard(paymentData.id)}
            />
            <Confirm
                open={this.state.visibleDeleteCardDialogId === paymentData.id}
                onCancel={this.closeDeleteCardDialog}
                content={this.getString({id: "preferences.dialog.delete_primary_card.confirm"}, { ending: paymentData.last4 })}
                cancelButton={this.getString({id: "no"})}
                confirmButton={this.getString({id: "yes"})}
                onConfirm={() => this.deleteCard(paymentData.id)}
            />

            <Sidebar.Pushable as={Segment}>
                <Sidebar
                    as={Menu}
                    animation="overlay"
                    icon="labeled"
                    vertical
                    visible={paymentData.id === this.state.cardSidebarVisibleId}
                    width="thin">
                    <Menu.Item as="a" onClick={() => this.showPrimaryCardDialog(paymentData.id)} disabled={isPrimaryCard}>
                        <Icon name="check" />
                        <FormattedMessage id="component.button.make_primary"/>
                    </Menu.Item>
                    <Menu.Item as="a" onClick={() => this.showDeleteCardDialog(paymentData.id)}>
                        <Icon name="trash" />
                        <FormattedMessage id="component.button.delete"/>
                    </Menu.Item>
                </Sidebar>

                <Sidebar.Pusher>
                    <Cards
                        cvc={"***"}
                        expiry={paymentData.expMonth + "/" + paymentData.expYear}
                        name={paymentData.name ? paymentData.name : ""}
                        number={"************" + paymentData.last4}
                        issuer={this.stripeBrandToCardBrand(paymentData.brand)}
                        preview={true}
                    />
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </div>;
    };

    private stripeBrandToCardBrand = (stripeBrand: string): string => {
        if (stripeBrandToCardBrandMap[stripeBrand.toLowerCase()]) {
            return stripeBrandToCardBrandMap[stripeBrand.toLowerCase()];
        }
        return "";
    }

    private renderEditorTypeRadio = (editorType: string): React.ReactElement<any> | undefined => {
        return <Form.Field
            key={editorType}
            control={Radio}
            label={this.getString({ id: `preferences.editor_type.${editorType}`})}
            value={editorType}
            checked={this.state.selectedEditorType === editorType}
            onChange={this.onSelectedEditorTypeChange} />;
    };
    private onSelectedEditorTypeChange = (event: ChangeEvent, data: any): void => {
        if (this.props.state.userState.currentUser &&
            this.props.state.userState.currentUser.preferences &&
            this.props.state.userState.currentUser.preferences.editorType) {
            const nextEditorType: EditorType = data.value as EditorType;
            this.setState({
                selectedEditorType: nextEditorType,
                editorTypeChanged: nextEditorType !== this.props.state.userState.currentUser.preferences.editorType
            });
        }
    }
    private update = (): void => {
        if  (this.props.state.userState.currentUser) {
            const user: User = this.props.state.userState.currentUser;
            const editorType: EditorType = this.state.selectedEditorType;
            this.props.actions.updatePreferences(user._id, { editorType });
        }
    }
}

export default connectAllProps(Preferences);