import React from "react";
import fetch from "../../../shared/fetch";
import connectAllProps from "../../../shared/connect";
import { ComponentProps } from "../../../shared/ComponentProps";
import { MessageDescriptor } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import _ from "lodash";
import { Confirm, Grid, Icon, Menu, Table } from "semantic-ui-react";

interface States {
    currentFolder: string;
    rootContents: any[];
    folderData: any[];
    column: any;
    direction: SortDirection;
    deleteConfirmationOpenId: string;
    activeItem: string;
}

interface Props extends ComponentProps {
    key: string;
    showReload?: boolean;
    id: string;
}

enum SortDirection {
    Ascending = "ascending",
    Descending = "descending",
}

class BucketBrowser extends React.Component<Props, States> {
    private _isMounted: boolean = false;
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;

    constructor(props: Props) {
        super(props);
        this.getString = this.props.intl.formatMessage;
        this.state = {
            currentFolder: "",
            rootContents: [],
            folderData: [],
            column: undefined,
            direction: SortDirection.Ascending,
            deleteConfirmationOpenId: "",
            activeItem: ""
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getRoot();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps: Props) {
    }

    render(): React.ReactElement<any> {
        return <div key={this.props.key}>
            <Grid columns={2} divided>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <Menu secondary vertical>
                            {
                                this.state.rootContents.filter((f) => f && f._id).map((folder: any) => {
                                    return <Menu.Item
                                        active={this.state.currentFolder === folder._id}
                                        key={folder._id + "-folder-menu"}
                                        onClick={() => { this.getFolder(folder._id); this.setState({ currentFolder: folder._id }); }}>
                                        {this.limitText(folder.name ? folder.name : folder._id, 20)}
                                        <Icon name={this.state.currentFolder === folder._id ? "folder open" : "folder"} />
                                        </Menu.Item>;
                                })
                            }
                        </Menu>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Table compact sortable celled singleLine striped size="small" basic="very">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        sorted={this.state.column === "_id" ? this.state.direction : undefined}
                                        onClick={() => this.changeSort("_id")}
                                    >
                                        File Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        sorted={this.state.column === "contentType" ? this.state.direction : undefined}
                                        onClick={() => this.changeSort("contentType")}
                                    >
                                        Content Type
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        sorted={this.state.column === "uploadedSize" ? this.state.direction : undefined}
                                        onClick={() => this.changeSort("uploadedSize")}
                                    >
                                        File Size
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        sorted={this.state.column === "createdAt" ? this.state.direction : undefined}
                                        onClick={() => this.changeSort("createdAt")}
                                    >
                                        Uploaded At
                                    </Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.folderData.map(({ _id, originalFileName, contentType, link, uploadedSize, createdAt }) => (
                                <Table.Row key={_id}>
                                    <Table.Cell>{originalFileName}</Table.Cell>
                                    <Table.Cell>{this.limitText(contentType, 20)}</Table.Cell>
                                    <Table.Cell>{this.formatBytes(uploadedSize)}</Table.Cell>
                                    <Table.Cell>{this.formatDate(createdAt)}</Table.Cell>
                                    <Table.Cell><a href={link} target="_blank" rel="noopener noreferrer"><Icon name="download" /></a></Table.Cell>
                                    <Table.Cell>
                                        <span onClick={() => this.setState({deleteConfirmationOpenId: _id})} style={{cursor: "pointer"}}><Icon name="trash alternate" /></span>
                                        <Confirm
                                            open={this.state.deleteConfirmationOpenId === _id}
                                            onCancel={() => this.setState({deleteConfirmationOpenId: ""})}
                                            onConfirm={() => this.deleteFile(this.state.currentFolder, _id)}
                                            content="Are you sure you want to delete this file?"
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>;
    }

    private limitText(text: string, max: number) {
        if (text.length <= max) return text;
        return text.substring(0, max) + "...";
    }

    private formatDate(isoDate: string) {
        const date: number = Date.parse(isoDate);
        const dateTimeFormat = new Intl.DateTimeFormat("en", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        });
        return dateTimeFormat.format(date);
    }
    private formatBytes(bytes: number, decimals: number = 2) {
        if (!+bytes) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }

    private deleteFile(folderName: string, fileId: string) {
        // confirm first.
        fetch( "/api/uploader/file/" + fileId, {}, "DELETE", true).then((result: any) => {
            if (this._isMounted && this.state.currentFolder === folderName) {
                this.setState({
                    folderData: this.state.folderData.filter((f) => f._id !== fileId)
                });
            }
        }).catch((error: Error) => {
            console.log(error);
        });
    }

    private getFolder(folderName: string) {
        fetch( "/api/uploader/list/" + this.props.id + "/" + folderName, {}, "GET", true).then((result: any) => {
            if (this._isMounted && result) {
                if (result.files) {
                    this.setState({
                        currentFolder: folderName,
                        folderData: result.files
                    });
                }
            }
        }).catch((error: Error) => {
            console.log(error);
        });
    }

    private getRoot() {
        fetch("/api/uploader/list/" + this.props.id, {}, "GET", true).then((result: any) => {
            if (this._isMounted && result) {
                if (result.uploaderIds) {
                    this.setState({rootContents : result.uploaderIds});
                }
            }
        }).catch((error: Error) => {
            console.log(error);
        });
    }

    private changeSort(column: any) {
        if (this.state.column === column) {
            this.setState({
                folderData: this.state.folderData.slice().reverse(),
                direction: this.state.direction === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending,
            });
        } else {
            this.setState({
                column: column,
                folderData: _.sortBy(this.state.folderData, [column]),
                direction: SortDirection.Ascending,
            });
        }
    }
}

export default connectAllProps(BucketBrowser);