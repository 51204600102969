import Block from "../../../models/Block";
import React from "react";
import { Segment, Item } from "semantic-ui-react";
import connectAllProps from "../../../shared/connect";
import { Viewer } from "@toast-ui/react-editor";
import moment from "moment";
import { ComponentProps } from "../../../shared/ComponentProps";

interface Props extends ComponentProps {
    block: Block;
}

interface States { }

class BlockItem extends React.Component<Props, States> {
    render(): React.ReactElement<any> {
        const { block } = this.props;
        const createDate: Date = block.createdAt ? new Date(block.createdAt) : new Date(0);
        const previewContent: string = "abstract here";
        return <Segment key={createDate.getMilliseconds()}>
            <Item>
                <Item.Content>
                    <Item.Header as="h2">{block.type}</Item.Header>
                    <Item.Meta>
                       some metadata
                    </Item.Meta>
                    <Viewer style={{ height: 3 }} initialValue={previewContent + "..."} />
                    <Item.Extra style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}>
                        <div style={{ color: "grey" }}>
                            {moment(createDate).fromNow()}
                        </div>
                        block item
                    </Item.Extra>
                </Item.Content>
            </Item>
        </Segment>;
    }
}

export default connectAllProps(BlockItem);