import { DropdownItemProps } from "semantic-ui-react";
import TableViewType from "../../../models/TableViewType";
import { ComponentProps as Props } from "../../../shared/ComponentProps";

export default function tableViewType(props: Props): DropdownItemProps[] {
    return Array.from(Object.keys(TableViewType) as Array<keyof typeof TableViewType>).map((key) => {
        return {
            key: key.toString(),
            text: props.intl.formatMessage({ id: "component.dropdown.table_view_type." + TableViewType[key].toString() }),
            value: TableViewType[key].toString()
        } as DropdownItemProps;
    });
}
