import React, { Fragment } from "react";
import connectAllProps from "../../../shared/connect";
import { Uploader } from "../../../models/Uploader";
import { byCreatedAtLatestFirst } from "../../../shared/date";
import { Container, Header, Icon, Segment } from "semantic-ui-react";
import UploaderItem from "./UploaderItem";
import { CONTAINER_STYLE } from "../../../shared/styles";
import Loading from "./Loading";
import FabAction from "../../../models/client/FabAction";
import { ComponentProps as Props } from "../../../shared/ComponentProps";
import { Link } from "react-router-dom";

interface States {}

class UploaderList extends React.Component<Props, States> {
    render(): React.ReactElement<any> {
        return <Container text style={CONTAINER_STYLE}>
            {this.renderCreateUploaderSection()}
            {this.renderUploaders()}
        </Container>;
    }
    componentDidMount() {
        this.props.actions.resetRedirectTask();
        this.addFabActions();
    }
    componentDidUpdate(prevProps: Props) {
        if ((prevProps.state.uploaderState.loading
            && !this.props.state.uploaderState.loading) ||
            (!prevProps.state.userState.currentUser
            && this.props.state.userState.currentUser)) {
            this.addFabActions();
        }
    }
    componentWillUnmount() {
        this.props.actions.setFabActions([]);
    }
    private renderUploaders = (): React.ReactElement<any> => {
        if (this.props.state.uploaderState.loading) {
            return <Loading />;
        } else {
            return <Fragment>
            {
                this.props.state.uploaderState.data
                .sort(byCreatedAtLatestFirst).map(
                    (uploader: Uploader) => <UploaderItem key={uploader._id} uploader={uploader} />
                )
            }
            </Fragment>;
        }
    }
    private addFabActions = (): void => {
        if (this.props.state.userState.currentUser) {
            const editUri: string = "/uploaders/create";
            const actions: FabAction[] = [{
                text: this.props.intl.formatMessage({id: "page.uploader.add"}),
                icon: "add",
                onClick: () => { this.props.history.push(editUri); },
            }];
            this.props.actions.setFabActions(actions);
        }
    }
    private renderCreateUploaderSection = (): React.ReactElement<any> | undefined => {
        const uploaders: Uploader [] = this.props.state.uploaderState.data;
        if (this.props.state.uploaderState.loading) {
            return <Loading />;
        } else if (this.props.state.userState.currentUser) {
            if (!uploaders || uploaders.length === 0) {
                return <Segment placeholder textAlign="center">
                        <Header icon>
                            <Link to="/uploaders/create">
                                <Icon name="plus circle" />
                                You do not have any buckets yet.
                            </Link>
                        </Header>
                        Click on the + button below to create a new bucket.
                    </Segment>;
            }
            return <></>;
        } else {
            if (uploaders && uploaders.length > 0) {
                return undefined;
            } else {
                return <></>;
            }
        }
    }
}

export default connectAllProps(UploaderList);