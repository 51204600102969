import AppointmentActionCreator from "../models/client/AppointmentActionCreator";
import { Dispatch } from "redux";
import fetch from "../shared/fetch";
import actions from "./common";
import { Appointment } from "../models/Appointment";
import GetCalendarsResponse from "../models/response/GetCalendarsResponse";
import { getToast as toast } from "../shared/toast";
import CalendarInstanceType from "../models/CalendarInstanceType";

export const SAVE_APPOINTMENT_BEGIN: string = "SAVE_APPOINTMENT_BEGIN";
export const SAVE_APPOINTMENT_SUCCESS: string = "SAVE_APPOINTMENT_SUCCESS";
export const SAVE_APPOINTMENT_FAILED: string = "SAVE_APPOINTMENT_FAILED";
export const REMOVE_APPOINTMENT_BEGIN: string = "REMOVE_APPOINTMENT_BEGIN";
export const REMOVE_APPOINTMENT_SUCCESS: string = "REMOVE_APPOINTMENT_SUCCESS";
export const REMOVE_APPOINTMENT_FAILED: string = "REMOVE_APPOINTMENT_FAILED";
export const GET_APPOINTMENT_BEGIN: string = "GET_APPOINTMENT_BEGIN";
export const GET_APPOINTMENT_SUCCESS: string = "GET_APPOINTMENT_SUCCESS";
export const GET_APPOINTMENT_FAILED: string = "GET_APPOINTMENT_FAILED";
export const GET_MORE_APPOINTMENT_BEGIN: string = "GET_MORE_APPOINTMENT_BEGIN";
export const GET_MORE_APPOINTMENT_SUCCESS: string = "GET_MORE_APPOINTMENT_SUCCESS";
export const GET_MORE_APPOINTMENT_FAILED: string = "GET_MORE_APPOINTMENT_FAILED";

const appointmentActionCreator: AppointmentActionCreator = {
    getAppointments(): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: GET_APPOINTMENT_BEGIN});
            fetch("/api/calendar?instanceType=appointment", undefined, "GET")
            .then((json: GetCalendarsResponse) => {
                if (json && json.data) {
                    dispatch({
                        type: GET_APPOINTMENT_SUCCESS,
                        appointments: json.data,
                        hasMore: json.hasMore
                    });
                } else {
                    return Promise.reject({ name: "500 Internal Server Error", message: "" });
                }
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(GET_APPOINTMENT_FAILED, error));
            });
        };
    },
    getMoreAppointments(earlierThan: string): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: GET_MORE_APPOINTMENT_BEGIN});
            fetch(`/api/calendar?instanceType=appointment&latest=${earlierThan}`, undefined, "GET")
            .then((json: GetCalendarsResponse) => {
                if (json && json.data) {
                    dispatch({
                        type: GET_MORE_APPOINTMENT_SUCCESS,
                        appointments: json.data,
                        hasMore: json.hasMore
                    });
                } else {
                    return Promise.reject({ name: "500 Internal Server Error", message: "" });
                }
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(GET_MORE_APPOINTMENT_FAILED, error));
            });
        };
    },
    addAppointment(appointment: Appointment): any {
        console.log(appointment);
        const savingAppointment = appointment;
        savingAppointment.instanceType = CalendarInstanceType.Appointment;
        console.log(savingAppointment);
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: SAVE_APPOINTMENT_BEGIN});
            fetch("/api/calendar/create", savingAppointment, "POST", /*withToken*/ true)
            .then((added: Appointment) => {
                if (added) {
                    toast().success("toast.appointment.save_successfully");
                    dispatch({
                        type: SAVE_APPOINTMENT_SUCCESS,
                        appointment: added,
                        redirectTask: {
                            redirected: false,
                            to: `/appointments/${added._id}`
                        }
                    });
                } else {
                    return Promise.reject({ name: "500 Internal Server Error", message: "Broken data." });
                }
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(SAVE_APPOINTMENT_FAILED, error));
            });
        };
    },
    editAppointment(appointment: Appointment): any {
        console.log(appointment);
        const savingAppointment = appointment;
        if (!savingAppointment.instanceType) savingAppointment.instanceType = CalendarInstanceType.Appointment;
        console.log(savingAppointment);
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: SAVE_APPOINTMENT_BEGIN});
            fetch("/api/calendar/edit", appointment, "POST", /*withToken*/ true)
            .then((updated: Appointment) => {
                toast().success("toast.appointment.save_successfully");
                dispatch({
                    type: SAVE_APPOINTMENT_SUCCESS,
                    appointment: updated,
                    redirectTask: {
                        redirected: true,
                        to: `/appointments/${updated._id}`
                    }
                });
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(SAVE_APPOINTMENT_FAILED, error));
            });
        };
    },
    removeAppointment(id: string): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: REMOVE_APPOINTMENT_BEGIN});
            fetch(`/api/calendar/remove/${id}`, undefined, "GET", /*withToken*/ true)
            .then((json: any) => {
                toast().success("toast.appointment.delete_successfully");
                dispatch({
                    type: REMOVE_APPOINTMENT_SUCCESS,
                    redirectTask: {
                        redirected: false,
                        to: "/appointments"
                    }
                });
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(REMOVE_APPOINTMENT_FAILED, error));
            });
        };
    }
};

export default appointmentActionCreator;