import CalendarActionCreator from "../models/client/CalendarActionCreator";
import { Dispatch } from "redux";
import fetch from "../shared/fetch";
import actions from "./common";
import { Calendar } from "../models/Calendar";
import GetCalendarsResponse from "../models/response/GetCalendarsResponse.d";
import { getToast as toast } from "../shared/toast";

export const SAVE_CALENDAR_BEGIN: string = "SAVE_CALENDAR_BEGIN";
export const SAVE_CALENDAR_SUCCESS: string = "SAVE_CALENDAR_SUCCESS";
export const SAVE_CALENDAR_FAILED: string = "SAVE_CALENDAR_FAILED";
export const REMOVE_CALENDAR_BEGIN: string = "REMOVE_CALENDAR_BEGIN";
export const REMOVE_CALENDAR_SUCCESS: string = "REMOVE_CALENDAR_SUCCESS";
export const REMOVE_CALENDAR_FAILED: string = "REMOVE_CALENDAR_FAILED";
export const GET_CALENDAR_BEGIN: string = "GET_CALENDAR_BEGIN";
export const GET_CALENDAR_SUCCESS: string = "GET_CALENDAR_SUCCESS";
export const GET_CALENDAR_FAILED: string = "GET_CALENDAR_FAILED";
export const GET_MORE_CALENDAR_BEGIN: string = "GET_MORE_CALENDAR_BEGIN";
export const GET_MORE_CALENDAR_SUCCESS: string = "GET_MORE_CALENDAR_SUCCESS";
export const GET_MORE_CALENDAR_FAILED: string = "GET_MORE_CALENDAR_FAILED";

const calendarActionCreator: CalendarActionCreator = {
    getCalendars(): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: GET_CALENDAR_BEGIN});
            fetch("/api/calendar", undefined, "GET")
            .then((json: GetCalendarsResponse) => {
                if (json && json.data) {
                    dispatch({
                        type: GET_CALENDAR_SUCCESS,
                        calendars: json.data,
                        hasMore: json.hasMore
                    });
                } else {
                    return Promise.reject({ name: "500 Internal Server Error", message: "" });
                }
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(GET_CALENDAR_FAILED, error));
            });
        };
    },
    getMoreCalendars(earlierThan: string): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: GET_MORE_CALENDAR_BEGIN});
            fetch(`/api/calendar?latest=${earlierThan}`, undefined, "GET")
            .then((json: GetCalendarsResponse) => {
                if (json && json.data) {
                    dispatch({
                        type: GET_MORE_CALENDAR_SUCCESS,
                        calendars: json.data,
                        hasMore: json.hasMore
                    });
                } else {
                    return Promise.reject({ name: "500 Internal Server Error", message: "" });
                }
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(GET_MORE_CALENDAR_FAILED, error));
            });
        };
    },
    addCalendar(calendar: Calendar): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: SAVE_CALENDAR_BEGIN});
            fetch("/api/calendar/create", calendar, "POST", /*withToken*/ true)
            .then((added: Calendar) => {
                if (added) {
                    toast().success("toast.calendar.save_successfully");
                    dispatch({
                        type: SAVE_CALENDAR_SUCCESS,
                        calendar: added,
                        redirectTask: {
                            redirected: false,
                            to: `/calendars/${added._id}`
                        }
                    });
                } else {
                    return Promise.reject({ name: "500 Internal Server Error", message: "Broken data." });
                }
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(SAVE_CALENDAR_FAILED, error));
            });
        };
    },
    editCalendar(calendar: Calendar): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: SAVE_CALENDAR_BEGIN});
            fetch("/api/calendar/edit", calendar, "POST", /*withToken*/ true)
            .then((updated: Calendar) => {
                toast().success("toast.calendar.save_successfully");
                dispatch({
                    type: SAVE_CALENDAR_SUCCESS,
                    calendar: updated,
                    redirectTask: {
                        redirected: true,
                        to: `/calendars/${updated._id}`
                    }
                });
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(SAVE_CALENDAR_FAILED, error));
            });
        };
    },
    removeCalendar(id: string): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: REMOVE_CALENDAR_BEGIN});
            fetch(`/api/calendar/remove/${id}`, undefined, "GET", /*withToken*/ true)
            .then((json: any) => {
                toast().success("toast.calendar.delete_successfully");
                dispatch({
                    type: REMOVE_CALENDAR_SUCCESS,
                    redirectTask: {
                        redirected: false,
                        to: "/calendars"
                    }
                });
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(REMOVE_CALENDAR_FAILED, error));
            });
        };
    }
};

export default calendarActionCreator;