import React, { RefObject } from "react";
import { TableViewViewType, PaginationStyle, UnifiedModel, SortBy } from "@airjam/types";
import { DataSourceFieldRef, dataSourceFieldToRef, refToDataSourceField } from "./DataSourceField";
import { DataSourceField } from "@airjam/types";

export interface TableView extends UnifiedModel {
    projectId: string; // read only for now
    title: string;
    tableViewId: string; // read only
    ownerId: string; // read only
    type: TableViewViewType;
    paginationStyle: PaginationStyle;
    fieldMapping: any;
    sheetDocumentId: string;
    sheetId: string;
    sortBy: SortBy;
    version: number;

    // search bar (top, bottom), UI flow

    // look and feel section
    templateId: string;
    templateVersion: number;
    styleId: string;
    styleVersion: number;  // always ask to upgrade.
    cssOverride: string;
    templateFields: {[id: string]: string};
    templateProperties: {[id: string]: any};
    styleProperties: {[id: string]: any};
}

export interface TableViewRef {
    id: string;
    projectId: string;
    title: RefObject<HTMLInputElement>;
    tableViewId: string;
    type: string;
    templateId: string;
    templateVersion: number;
    styleId: string;
    styleVersion: number;
    fieldMapping: RefObject<DataSourceFieldRef[]>;
    currentSheetKey: string;
    currentSheetSelection: string;
    currentPaginationStyle: string;
    currentSortBy: string;
    fieldMappingResult: {[id: string]: DataSourceField};
    templateFieldMapping: {[id: string]: string};
    templateProperties: {[id: string]: any};
    styleProperties: {[id: string]: any};
}

export function vendTableViewRef(): TableViewRef {
    return {
        id: "",
        projectId: "",
        title: React.createRef(),
        tableViewId: "",
        type: "",
        fieldMapping: React.createRef(),
        currentSheetKey: "",
        currentSheetSelection: "",
        currentPaginationStyle: "",
        currentSortBy: "",
        fieldMappingResult: {},
        templateFieldMapping: {},
        templateProperties: {},
        styleProperties: {}
    } as TableViewRef;
}

export function vendTableViewRefFromComponent(component: TableView): TableViewRef {
    const ref: TableViewRef = vendTableViewRef();
    ref.id = component._id;
    ref.currentSheetKey = component.sheetDocumentId;
    ref.currentSheetSelection = component.sheetId;
    ref.currentPaginationStyle = component.paginationStyle;
    ref.currentSortBy = component.sortBy;
    ref.projectId = component.projectId;
    ref.tableViewId = component.tableViewId;
    if (ref.title.current) ref.title.current.value = component.title;
    ref.type = component.type;
    ref.templateId = component.templateId;
    ref.templateVersion = component.templateVersion;
    ref.styleId = component.styleId;
    ref.styleVersion = component.styleVersion;
    ref.templateFieldMapping = component.templateFields;
    ref.fieldMappingResult = component.fieldMapping as {[idx: string]: DataSourceField};
    ref.fieldMapping = React.createRef();
    ref.templateProperties = component.templateProperties ? component.templateProperties : {};
    ref.styleProperties = component.styleProperties ? component.styleProperties : {};
    return ref;
}

type PaginationStyleKeys = keyof typeof PaginationStyle;
type ViewTypeKeys = keyof typeof TableViewViewType;
type SortByKeys = keyof typeof SortBy;


export function refToTableView(component: TableViewRef): TableView {
    return {
        _id: component.id,
        ownerId: "", // ignored
        projectId: component.projectId,
        tableViewId: component.tableViewId,
        version: 0, // ignored
        sheetDocumentId: component.currentSheetKey,
        sheetId: component.currentSheetSelection,
        title: component.title.current?.value,
        type: component.type,
        fieldMapping: component.fieldMappingResult,
        templateId: component.templateId,
        templateVersion: component.templateVersion,
        styleId: component.styleId,
        styleVersion: component.styleVersion,
        cssOverride: "", // todo add this
        templateFields: component.templateFieldMapping,
        templateProperties: component.templateProperties,
        styleProperties: component.styleProperties,
        sortBy: component.currentSortBy,
        paginationStyle: component.currentPaginationStyle,
    } as TableView;
}