import { DropdownItemProps } from "semantic-ui-react";
import AuthProviderType from "../../../models/AuthProviderType";
import { ComponentProps as Props } from "../../../shared/ComponentProps";

export default function authProviderType(props: Props): DropdownItemProps[] {
    return Array.from(Object.keys(AuthProviderType) as Array<keyof typeof AuthProviderType>).map((key) => {
        return {
            key: key.toString(),
            text: props.intl.formatMessage({ id: "component.dropdown.auth_provider_type." + AuthProviderType[key].toString() }),
            value: AuthProviderType[key].toString()
        } as DropdownItemProps;
    });
}
