import React from "react";
import connectAllProps from "../../../shared/connect";
import { Redirect } from "react-router-dom";
import Block from "../../../models/Block";
import ErrorPage from "../../pages/ErrorPage";
import { Container, Header } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
import BlockEditor from "./BlockEditor";
import { FormattedMessage } from "react-intl";
import { isMobile } from "../dimension";
import { pendingRedirect } from "../../../shared/redirect";
import { ComponentProps as Props } from "../../../shared/ComponentProps";

interface States { }
class EditBlock extends React.Component<Props, States> {
    private blockId: string = "";
    render(): React.ReactElement<any> {
        if (pendingRedirect(this.props)) {
            return <Redirect to={this.props.state.redirectTask.to} />;
        }
        const notFoundError: Error = {
            name: "404 Not Found",
            message: `not found for ${window.location.href} `
        };
        this.blockId = this.props.match && this.props.match.params && this.props.match.params.blockId;
        if (!this.blockId) {
            return <ErrorPage error={notFoundError} />;
        }
        const block: Block | undefined = this.props.state.blockState.data.find(
            (value: Block): boolean => value._id === this.blockId
        );
        if (!block) {
            return <ErrorPage error={notFoundError} />;
        }
        if (this.props.state.userState.currentUser) {
            const containerStyle: any = isMobile() ? CONTAINER_STYLE :
                { ...CONTAINER_STYLE, paddingLeft: 20, paddingRight: 20 };
            return (
                <Container style={containerStyle}>
                    <Header size={"medium"}>
                        <FormattedMessage id="page.block.edit" />
                    </Header>
                    <BlockEditor block={block}
                        submitTextId="component.button.update"
                        onSubmit={this.editBlock}
                        loading={this.props.state.blockState.loading} />
                </Container>
            );
        } else {
            return <Redirect to="/block" />;
        }
    }

    private editBlock = (title: string, dataSource: string, sharingType: string): void => {
        if (this.props.state.userState.currentUser) {
            this.props.actions.editBlock({
                ownerId: this.props.state.userState.currentUser._id,
                title: title,
                dataSource: dataSource,
                sharingType: sharingType,
                _id: this.blockId
            } as Block);
        }
    }
}

export default connectAllProps(EditBlock);