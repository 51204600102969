import { AnyAction as Action } from "redux";
import UploaderState from "../models/client/UploaderState";
import { GET_UPLOADER_SUCCESS, SAVE_UPLOADER_SUCCESS, GET_UPLOADER_BEGIN, SAVE_UPLOADER_BEGIN, SAVE_UPLOADER_FAILED, GET_UPLOADER_FAILED, REMOVE_UPLOADER_BEGIN, REMOVE_UPLOADER_SUCCESS, REMOVE_UPLOADER_FAILED } from "../actions/uploader";

const initialState: UploaderState = {
    loading: false,
    valid: false,
    data: []
};

const uploader = (state: UploaderState = initialState, action: Action): UploaderState => {
    switch (action.type) {
        case GET_UPLOADER_BEGIN:
        case SAVE_UPLOADER_BEGIN:
        case REMOVE_UPLOADER_BEGIN:
            return {...state, loading: true};
        case GET_UPLOADER_SUCCESS:
            return {
                ...state,
                data: action.uploaders,
                valid: true,
                loading: false
            };
        case SAVE_UPLOADER_SUCCESS:
            // merge the added/updated uploader instantly, without waiting for the uploader list fetching
            return {...state, valid: false, loading: false, data: [...state.data, action.uploader]};
        case REMOVE_UPLOADER_SUCCESS:
            return {...state, valid: false, loading: false};
        case GET_UPLOADER_FAILED:
        case REMOVE_UPLOADER_FAILED:
        case SAVE_UPLOADER_FAILED:
            return {...state, loading: false};
        default:
            return state;
    }
};

export default uploader;