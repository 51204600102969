import { TableView } from "../../../models/TableView";
import { Form, Button, FormGroup, Dropdown } from "semantic-ui-react";
import { RefObject } from "react";
import React from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import connectAllProps from "../../../shared/connect";
import ResponsiveFormField from "../shared/ResponsiveFormField";
import { PrimitiveType } from "intl-messageformat";
import WarningModal from "../shared/WarningModal";
import { ComponentProps } from "../../../shared/ComponentProps";
import tableViewTypes from "../components/TableViewTypes";
import paginationStyles from "../components/PaginationStyles";

interface Props extends ComponentProps {
    tableView?: TableView;
    submitTextId: string;
    onSubmit: (projectId: string, title: string, viewType: string, paginationStyle: string) => void;
    loading?: boolean;
}

interface States {
    editing: boolean;
    openClearEditWarning: boolean;
}


class TableViewEditor extends React.Component<Props, States> {
    private projectIdRef: RefObject<HTMLInputElement>;
    private titleRef: RefObject<HTMLInputElement>;
    private typeRef: RefObject<HTMLInputElement>;
    private paginationStyleRef: RefObject<HTMLInputElement>;
    private originalProjectId: string = "";
    private originalTitle: string = "";
    private originalType: string = "";
    private originalPaginationStyle: string = "";
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.projectIdRef = React.createRef();
        this.titleRef = React.createRef();
        this.typeRef = React.createRef();
        this.paginationStyleRef = React.createRef();
        this.getString = this.props.intl.formatMessage;
        this.state = {
            editing: false,
            openClearEditWarning: false
        };
    }
    render(): React.ReactElement<any> {
        const getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string
            = this.props.intl.formatMessage;
        if (this.props.tableView) {
            this.originalProjectId = this.props.tableView.projectId;
            this.originalTitle = this.props.tableView.title;
            this.originalType = this.props.tableView.type;
            this.originalPaginationStyle = this.props.tableView.paginationStyle;
        }
        return (
            <Form>
                <ResponsiveFormField>
                    <label>
                        <FormattedMessage id="table.view.project_id" />
                    </label>
                    <input ref={this.projectIdRef} autoFocus={true}
                        defaultValue={this.originalProjectId}
                        onChange={this.onEditing} />
                </ResponsiveFormField>
                <ResponsiveFormField>
                    <label>
                        <FormattedMessage id="table.view.title" />
                    </label>
                    <input ref={this.titleRef} autoFocus={true}
                        defaultValue={this.originalTitle}
                        onChange={this.onEditing} />
                </ResponsiveFormField>
                <ResponsiveFormField>
                    <label>
                        <FormattedMessage id="table.view.type" />
                    </label>
                    <Dropdown
                        placeholder={getString({ id: "component.table.view.table_view_type_select" })}
                        fluid
                        selection
                        defaultValue={this.originalType}
                        onChange={(e: any, value: any) => {
                            if (this.typeRef.current) this.typeRef.current.value = value.value;
                            this.onEditing();
                        }}
                        options={tableViewTypes(this.props)}
                    />
                    <input style={{ display: "none" }} ref={this.typeRef}
                        defaultValue={this.originalType} onChange={this.onEditing} />
                </ResponsiveFormField>
                <ResponsiveFormField>
                    <label>
                        <FormattedMessage id="table.view.pagination_style" />
                    </label>
                    <Dropdown
                        placeholder={getString({ id: "component.table.view.pagination_style_select" })}
                        fluid
                        selection
                        defaultValue={this.originalPaginationStyle}
                        onChange={(e: any, value: any) => {
                            if (this.paginationStyleRef.current) this.paginationStyleRef.current.value = value.value;
                            this.onEditing();
                        }}
                        options={paginationStyles(this.props)}
                    />
                    <input style={{ display : "none" }} ref={this.paginationStyleRef}
                        defaultValue={this.originalPaginationStyle} onChange={this.onEditing} />
                </ResponsiveFormField>
                <FormGroup inline>
                    <Form.Field control={Button} onClick={this.onSubmit} primary
                        loading={this.props.loading}
                        disabled={this.props.loading || !this.state.editing}>
                        <FormattedMessage id={this.props.submitTextId} />
                    </Form.Field>
                    <Form.Field control={Button} onClick={() => this.setState({ openClearEditWarning: true })}
                        loading={this.props.loading}
                        disabled={this.props.loading || !this.state.editing}>
                        <FormattedMessage id="component.button.clear_edit" />
                    </Form.Field>
                </FormGroup>
                {this.renderClearEditWarningModal()}
            </Form>
        );
    }
    private onSubmit = (): void => {
        const projectId: any = this.projectIdRef.current && this.projectIdRef.current.value;
        const title: any = this.titleRef.current && this.titleRef.current.value;
        const type: any = this.typeRef.current && this.typeRef.current.value;
        const paginationStyle: any = this.paginationStyleRef.current && this.paginationStyleRef.current.value;
        this.props.onSubmit(projectId, title, type, paginationStyle);
    }

    private clearEditing = () => {
        if (this.projectIdRef.current) this.projectIdRef.current.value = this.originalProjectId;
        if (this.titleRef.current) this.titleRef.current.value = this.originalTitle;
        if (this.typeRef.current) this.typeRef.current.value = this.originalType;
        if (this.paginationStyleRef.current) this.paginationStyleRef.current.value = this.originalPaginationStyle;
        this.setState({
            editing: false,
            openClearEditWarning: false
        });
    }

    private onEditing = () => {
        if (!this.projectIdRef.current
            || !this.titleRef.current
            || !this.typeRef.current
            || !this.paginationStyleRef.current) {
            return;
        }
        const instanceProjectId: string = this.projectIdRef.current.value;
        const instanceTitle: string = this.titleRef.current.value;
        const instanceType: string = this.typeRef.current.value;
        const instancePaginationStyle: string = this.paginationStyleRef.current.value;
        if (this.originalTitle === instanceTitle
            && this.originalProjectId === instanceProjectId
            && this.originalType === instanceType
            && this.originalPaginationStyle === instancePaginationStyle) {
            this.setState({
                editing: false
            });
        } else {
            this.setState({
                editing: true
            });
        }
    }

    private renderClearEditWarningModal = (): React.ReactElement<any> | undefined => {
        return <WarningModal
            descriptionIcon="close" open={this.state.openClearEditWarning}
            descriptionText={this.getString({ id: "page.table.view.clear_edit" })}
            warningText={this.getString({ id: "page.table.view.clear_edit_confirmation" })}
            onConfirm={this.clearEditing}
            onCancel={() => { this.setState({ openClearEditWarning: false }); }} />;
    }
}

export default connectAllProps(TableViewEditor);