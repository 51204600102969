import React from "react";
import connectAllProps from "../../../shared/connect";
import { ComponentProps } from "../../../shared/ComponentProps";
import { MessageDescriptor } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import { CallPage, CallWindow, PageActionType } from "../../../models/CallType";
import { Grid, Header, Tab } from "semantic-ui-react";
import ViewCountView from "./ViewCountView";

interface States {
    window: CallWindow;
}

interface Props extends ComponentProps {
    key: string;
    title: string;
    showReload?: boolean;
    actionType: PageActionType;
    dataLabel: string;
    id: string;
    pageType: CallPage;
}


class ViewCountComponent extends React.Component<Props, States> {
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;

    private callWindowToIdx: {[window: string]: number} = {
        "today": 0,
        "week": 1,
        "30d": 2
    };
    private idxToCallWindow: {[idx: number]: CallWindow} = {
        0: CallWindow.TODAY,
        1: CallWindow.WEEK,
        2: CallWindow.MONTH
    };
    constructor(props: Props) {
        super(props);
        this.getString = this.props.intl.formatMessage;
        this.state = {
            window: CallWindow.TODAY
        };
    }

    componentDidMount() {
    }
    componentWillUnmount() {
    }
    componentDidUpdate(prevProps: Props) {
    }

    render(): React.ReactElement<any> {
        return <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column width={8}>
                        <Header className="viewCountTabTitle" size="medium">{this.props.title}</Header>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Tab className="viewCountTab" activeIndex={this.callWindowToIdx[this.state.window]} menu={{ secondary: true, pointing: true, inverted: false }} panes={[
                            {menuItem: this.getString({id: this.idxToCallWindow[0]})},
                            {menuItem: this.getString({id: this.idxToCallWindow[1]})},
                            {menuItem: this.getString({id: this.idxToCallWindow[2]})},
                        ]}  onTabChange={this.handleChange} />
                   </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <ViewCountView pageType={this.props.pageType} key={this.props.id} showReload={true} id={this.props.id} actionType={this.props.actionType} dataLabel={this.props.dataLabel} window={this.state.window}></ViewCountView>
                   </Grid.Column>
                </Grid.Row>
            </Grid>;
    }

    private handleChange = (e: any, data: any) => {
        this.setState({window: this.idxToCallWindow[data.activeIndex]});
        this.forceUpdate();
    }
}

export default connectAllProps(ViewCountComponent);