import React, { Fragment } from "react";
// eslint-disable-next-line
import { Route, Switch, match } from "react-router";
import connectAllProps from "../../shared/connect";
import { ComponentProps as Props } from "../../shared/ComponentProps";
import TableViewList from "../components/table/TableViewList";
import CreateTableView from "../components/table/CreateTableView";
import TableViewDetail from "../components/table/TableViewDetail";
import EditTableView from "../components/table/EditTableView";

interface States { }

class TableViews extends React.Component<Props, States> {
    componentDidMount() {
        this.props.actions.getTableViews();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.state.tableViewState.valid && !this.props.state.tableViewState.valid) {
            this.props.actions.getTableViews();
        }
    }

    render(): any {
        const match: match<any> = this.props.match;
        return <Fragment>
            <Switch>
                <Route exact path={match.url} render={(props) => <TableViewList {...props} />} />
                <Route path={`${match.url}/create`} render={(props) => <CreateTableView {...props} />} />
                <Route path={`${match.url}/edit/:tableViewId`} render={(props) => <EditTableView {...props} />} />
                <Route path={`${match.url}/:tableViewId`} render={(props) => <TableViewDetail {...props} />} />
            </Switch>
        </Fragment>;
    }
}

export default connectAllProps(TableViews);
