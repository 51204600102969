import React from "react";
import { Container } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
interface Props {}

interface States {}
export default class StoreLocator extends React.Component<Props, States> {
    render() {
        return <Container text style={CONTAINER_STYLE}>
            <h1>"StoreJam" Landing Page</h1>

            <h2>Create a store locator page in seconds</h2>
            <img src="/images/store_locator1.png" alt="" />

            <h2>Manage and edit your stores, locations, and hours easily from a Google Spreadsheet</h2>

            <h2>Update your local store page and offer store pick up</h2>

            <h2>Completely customizable - add your logo, designate store types with different marker types</h2>

            <h2>Don't be held hostage by your local provider - own your data and edit whenever you want</h2>
        </Container>;
    }
}