import React from "react";
import { Header, Icon, Grid, Button, Container, Tab } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ComponentProps } from "../../../shared/ComponentProps";
import User from "../../../models/User";
import connectAllProps from "../../../shared/connect";
import { TableView } from "@airjam/react-table-view";

interface States {}
class Appointment extends React.Component<ComponentProps, States> {
    render() {
        const user: User | undefined = this.props.state.userState.currentUser;
        return <Container style={CONTAINER_STYLE}>
            <Grid stackable>
                <Grid.Row style={{ minHeight: 30 }}></Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={10} verticalAlign="bottom">
                        <Header textAlign="center" size="huge">
                            <div>
                                <Icon name="calendar check"></Icon>
                            </div>
                            <span className="punchLine">Bookings &amp; Appointments Component</span>
                            <Header.Subheader className="punchLineSubHeader">
                                The most comprehensive and fully-featured booking and appointment component, ready to be integrated and live in seconds — completely FREE!
                            </Header.Subheader>
                        </Header>
                        <Header textAlign="center" size="huge">
                            <Header.Subheader className="punchLineSubHeader">
                                {user ? <Link to={user ? "/appointments/" : "/login/r/appointments"}>
                                    <Button animated="vertical" style={{ marginTop: 15, marginBottom: 20, borderRadius: 30, padding: "15px 20px 15px 20px" }} color="black">
                                        <Button.Content visible>Go to console</Button.Content>
                                        <Button.Content hidden>
                                            <Icon name="terminal" />
                                        </Button.Content>
                                    </Button>
                                </Link> : ""}
                                {user ? "" : <Link to={user ? "/appointments/" : "/join/r/appointments"}>
                                    <Button animated="vertical" style={{ marginTop: 15, marginBottom: 20, borderRadius: 30, padding: "15px 20px 15px 20px" }} color="orange">
                                        <Button.Content visible><FormattedMessage id="get_started" /></Button.Content>
                                        <Button.Content hidden>
                                            <Icon name="arrow right" />
                                        </Button.Content>
                                    </Button>
                                </Link>}
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={10}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <img src="/images/templates/bubble_io.png" width={30} />
                                    <h4>Get started without coding with no-code tools</h4>
                                    <p>
                                        Build seamlessly with popular no-code tools such as Bubble and Retool, or website builders like Squarespace and WordPress.
                                    </p>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <i className={"fa-brands fa-react fa-2x"}></i>
                                    <h4>Switch between no-code tools and coding languages with almost zero effort</h4>
                                    <p>
                                        Ready to graduate your project out of no-code tools? We got you covered! AirJam's booking component also works with programming languages such as React.JS, Next.JS, and React Native, so you can switch to those languages in seconds!
                                    </p>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <i className={"fa-regular fa-calendar fa-2x"}></i>
                                    <h4>Manage from your own calendar (Coming Soon!)</h4>
                                    <p>
                                        Use your own calendar services such as Google Calendar or Microsoft Outlook to manage and update your availabilities. AirJam automatically syncs and updates your booking component and your calendar service using CalDAV.
                                    </p>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <i className={"fa-solid fa-hammer fa-2x"}></i>
                                    <h4>The ultimate admin console</h4>
                                    <p>
                                        AirJam's booking component comes with a comprehensive admin console, giving you full control to manage things like payments using Stripe, booking moderation, email templates, and so much more, all from a single place!
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>

                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/templates/appointments-punch1.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={7} verticalAlign="middle">
                        <Header as="h1" textAlign="left" size="large" style={{ width: "100%" }}>
                            The ultimate booking component that actually works
                            <Header.Subheader className="subheader_more_margin_top">
                                Choose a component that's built for the real world! AirJam's booking component is designed for real-life scenarios in mind, with the essential controls you actually need to manage booking operations in day-to-day operations.
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={7} verticalAlign="middle">
                        <Header as="h1" textAlign="right" size="large" style={{ width: "100%" }}>
                            Fully customizable with popular programming languages
                            <Header.Subheader className="subheader_more_margin_top">
                                Unleash the full potential of your operation with AirJam and powerful programming languages such as React and Next.js! AirJam is the first component to support everything from WordPress to Bubble to React.js, so you can bring your operations across multiple platforms and devices simultaneously. AirJam comes with extensive features set for programming languages such as React, React Native, and Next.js, so you can unleash the full power of programming languages when you're ready to take your operation up a notch.
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/templates/appointments-punch2.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/templates/appointments-punch3.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={7} verticalAlign="middle">
                        <Header as="h1" textAlign="left" size="large" style={{ width: "100%" }}>
                            Powerful admin interface
                            <Header.Subheader className="subheader_more_margin_top">
                                Serious operations require a serious admin console, and AirJam delivers just that. Manage operating hours, localization, email templates, and moderate booking requests, either from AirJam's built-in admin console, or integrate these features to your own admin console using AirJam booking admin console component.
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={7} verticalAlign="middle">
                        <Header as="h1" textAlign="right" size="large" style={{ width: "100%" }}>
                            Bring your own branding with AirJam
                            <Header.Subheader className="subheader_more_margin_top">
                                AirJam is designed to give you maximum control over your custom flow and styling. With AirJam's React and Next.js components, you can apply your own business logic, custom rendering, and CSS styles to your booking flow. Plus, AirJam's admin console lets you customize built-in messaging with localization and email template features, no matter whichever platform or programming languages you choose.
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/view_table_stickers.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={12}>
                        <Header as="h1" textAlign="center" size="large" style={{ width: "100%" }}>
                            FAQs
                        </Header>
                        <TableView id="aSrWMBaM" />
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>;
    }
}

export default connectAllProps(Appointment);