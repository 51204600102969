import React from "react";
import connectAllProps from "../../../shared/connect";
import fetch from "../../../shared/fetch";
import { ComponentProps } from "../../../shared/ComponentProps";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";
import { MessageDescriptor } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import SourceFileEntry from "../../../models/views/SourceFileEntry";
import GetTableViewSheetsResponse from "../../../models/response/GetTableViewSheetsResponse";

interface Props extends ComponentProps {
    onChange: (componentId: string, newValue: string) => void;
    showRefresh: boolean;
    initialKey: string;
    componentId: string;
    key: string;
}

interface States {
    options: DropdownItemProps[];
}

class GoogleSheetDocumentSelector extends React.Component<Props, States> {
    private _isMounted: boolean = false;
    private sheetDocumentSelection: string;
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.getString = this.props.intl.formatMessage;
        this.sheetDocumentSelection = "";
        this.state = {
            options: [{ key: "0", value: "0", text: this.getString({ id: "component.block.data_source_select" }) }]
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.refreshSheets();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render(): React.ReactElement<any> {
        let dropdownText: string = this.getOptionTextFromValue(this.props.initialKey);
        if (this.sheetDocumentSelection) dropdownText = this.getOptionTextFromValue(this.sheetDocumentSelection);

        return <div><Dropdown
            key={this.props.key}
            text={dropdownText}
            value={this.sheetDocumentSelection ? this.sheetDocumentSelection : this.props.initialKey}
            fluid
            selection
            onChange={(e: any, value: any) => {
                this.sheetDocumentSelection = value.value;
                this.props.onChange(this.props.componentId, value.value);
                this.forceUpdate();
            }}
            options={this.state.options}
        />
        </div>;
    }

    private getOptionTextFromValue(value: string): string {
        this.state.options.forEach((option: DropdownItemProps) => {
            if (option.value === value) return option.text?.toString();
        });
        return "";
    }

    private refreshSheets() {
        fetch(`/api/view/table/sheets`, undefined, "GET", true)
            .then((res: GetTableViewSheetsResponse) => {
                if (res && res.files && this._isMounted) {
                this.setState({
                    options: []
                });
                res.files.forEach((sheet: SourceFileEntry) => {
                    this.state.options.push({
                        key: sheet.id,
                        text: sheet.name,
                        value: sheet.id
                    });
                });
                if (this.state.options.length === 0) {
                        this.state.options.push({
                        key: 0,
                        text: "No sheets available",
                        value: 0
                    });
                }
                this.forceUpdate();
            }
        }).catch((error: Error) => {
            const msg = error.message as any;
            if (msg && msg.name && msg.name === "oauth") {
                alert("Your authentication with Google is expired, and you are now being logged out. Please log in again to re-authenticate with Google");
                this.props.actions.logout();
            }
        });
    }
}

export default connectAllProps(GoogleSheetDocumentSelector);