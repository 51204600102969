import React, { Fragment } from "react";
import connectAllProps from "../../../shared/connect";
import { TableView } from "../../../models/TableView";
import { byCreatedAtLatestFirst } from "../../../shared/date";
import { Container, Segment, Header, Icon, Button } from "semantic-ui-react";
import TableViewItem from "./TableViewItem";
import { CONTAINER_STYLE } from "../../../shared/styles";
import Loading from "../article/Loading";
import FabAction from "../../../models/client/FabAction";
import { FormattedMessage } from "react-intl";
import NothingMoreFooter from "../shared/NothingMoreFooter";
import { ComponentProps as Props } from "../../../shared/ComponentProps";

interface States { }

class TableViewList extends React.Component<Props, States> {
    render(): React.ReactElement<any> {
        return <Container text style={CONTAINER_STYLE}>
            {this.renderCreateTableViewSection()}
            {this.renderTableViews()}
            {this.renderLoadMore()}
        </Container>;
    }

    componentDidMount() {
        this.props.actions.resetRedirectTask();
        this.addFabActions();
    }
    componentDidUpdate(prevProps: Props) {
        if ((prevProps.state.tableViewState.loading
            && !this.props.state.tableViewState.loading) ||
            (!prevProps.state.userState.currentUser
                && this.props.state.userState.currentUser)) {
            this.addFabActions();
        }
    }
    componentWillUnmount() {
        this.props.actions.setFabActions([]);
    }
    private renderTableViews = (): React.ReactElement<any> => {
        if (this.props.state.tableViewState.loading) {
            return <Loading />;
        } else {
            return <Fragment>
                {
                    this.props.state.tableViewState.data
                        .sort(byCreatedAtLatestFirst).map(
                            (tableView: TableView) => <TableViewItem key={tableView._id} tableView={tableView} />
                        )
                }
            </Fragment>;
        }
    }

    private addFabActions = (): void => {
        if (this.props.state.userState.currentUser) {
            const editUri: string = "/view/table/create";
            const actions: FabAction[] = [{
                text: this.props.intl.formatMessage({ id: "page.table.view.add" }),
                icon: "add",
                onClick: () => { this.props.history.push(editUri); },
            }];
            this.props.actions.setFabActions(actions);
        }
    }
    private renderCreateTableViewSection = (): React.ReactElement<any> | undefined => {
        const tableViews: TableView[] = this.props.state.tableViewState.data;
        if (this.props.state.tableViewState.loading) {
            return <Loading />;
        } else if (this.props.state.userState.currentUser) {
            if (!tableViews || tableViews.length === 0) {
                return <Segment placeholder>
                    <Header icon>
                        <Icon name="edit outline" />
                        <FormattedMessage id="page.table.view.empty" />
                    </Header>
                </Segment>;
            }
        } else {
            if (tableViews && tableViews.length > 0) {
                return undefined;
            } else {
                return <p>unknown</p>;
            }
        }
    }
    private renderLoadMore = (): React.ReactElement<any> | undefined => {
        const tableViews: TableView[] = this.props.state.tableViewState.data;
        if (this.props.state.tableViewState.hasMore) {
            const loadingMore: boolean | undefined = this.props.state.tableViewState.loadingMore;
            const createdAt: string | undefined = tableViews[tableViews.length - 1].createdAt;
            if (!createdAt) {
                return undefined;
            }
            return <Button fluid basic
                onClick={() => { this.loadMore(createdAt); }}
                loading={loadingMore}
                disabled={loadingMore} >
                <Button.Content>
                    <FormattedMessage id="page.table.view.load_more" />
                </Button.Content>
            </Button>;
        } else if (tableViews.length > 0) {
            return <NothingMoreFooter />;
        } else {
            return undefined;
        }
    }

    private loadMore = (createdAt: string): void => {
        this.props.actions.getMoreTableViews(createdAt);
    }
}

export default connectAllProps(TableViewList);