import React, { Fragment } from "react";
import connectAllProps from "../../../shared/connect";
import Project from "../../../models/Project";
import { byCreatedAtLatestFirst } from "../../../shared/date";
import { Container, Segment, Header, Icon, Image, Button, Grid, Card, Label } from "semantic-ui-react";
import ProjectItem from "./ProjectItem";
import { CONTAINER_STYLE } from "../../../shared/styles";
import Loading from "./Loading";
import { FormattedMessage } from "react-intl";
import { ComponentProps as Props } from "../../../shared/ComponentProps";
import CreateProjectWizard from "./CreateProjectWizard";

interface States {
    wizardVisible: boolean;
}

class ProjectList extends React.Component<Props, States> {
    constructor(props: Props) {
        super(props);
        this.state = {
            wizardVisible: false
        };
    }

    render(): React.ReactElement<any> {
        return <Container text style={CONTAINER_STYLE}>
            {this.renderTopButtonSection()}
            {this.renderEmptyHelp()}
            {this.renderProjects()}
            {this.renderLoadMore()}
        </Container>;
    }
    componentDidMount() {
        this.props.actions.resetRedirectTask();
        this.addFabActions();
    }
    componentDidUpdate(prevProps: Props) {
        if ((prevProps.state.projectState.loading
            && !this.props.state.projectState.loading) ||
            (!prevProps.state.userState.currentUser
            && this.props.state.userState.currentUser)) {
            this.addFabActions();
        }
    }
    componentWillUnmount() {
        this.props.actions.setFabActions([]);
    }
    private renderEmptyHelp = (): React.ReactElement<any> => {
        if (this.props.state.projectState.data.length > 0) {
            return <Fragment></Fragment>;
        }
        return <Card className="informationalCard" href="/blog/create_new_project">
            <Image src="/images/learn_how.png" wrapped ui={false} />
            <Card.Content>
            <Card.Header>Creating your first project</Card.Header>
            <Card.Meta>
                <span>Learn how to create new projects and components, and start adding AirJam components to your application in minutes.</span>
            </Card.Meta>
            <Card.Description>
               <Label>10 minutes</Label>
            </Card.Description>
            </Card.Content>
            <Card.Content extra>
                Learn how to get started →
            </Card.Content>
        </Card>;
    }
    private renderProjects = (): React.ReactElement<any> => {
        if (this.props.state.projectState.loading) {
            return <Loading />;
        } else if (this.props.state.projectState.data.length === 0) {
            return <Fragment></Fragment>;
        } else {
            return <Fragment>
                <Card.Group>
            {
                this.props.state.projectState.data
                .sort(byCreatedAtLatestFirst).map(
                    (project: Project) => <ProjectItem key={project._id} project={project} {...this.props} />
                )
            }
                </Card.Group>
                <br />
                <a href="/blog/create_new_project">Need Help? Here's a guide on how to create new projects</a>
            </Fragment>;
        }
    }
    private addFabActions = (): void => {
        // if (this.props.state.userState.currentUser) {
        //     const editUri: string = "/project/create";
        //     const actions: FabAction[] = [{
        //         text: this.props.intl.formatMessage({id: "page.project.add"}),
        //         icon: "add",
        //         onClick: () => { this.props.history.push(editUri); },
        //     }];
        //     this.props.actions.setFabActions(actions);
        // }
    }

    private renderTopButtonSection = (): React.ReactElement<any> | undefined => {
        if (this.props.state.projectState.loading) {
            return;
        } else {
            return <Segment className="hollowSegment" compact>
                <Grid columns={3} verticalAlign="middle" onClick={this.showCreateProject}>
                    <Grid.Row>
                        <Grid.Column width="3">
                            <i className="fas fa-diagram-project fa-2x"></i>
                        </Grid.Column>
                        <Grid.Column width="10">
                            <Header size="small">
                                <FormattedMessage id="page.project.new.button" />
                                <span className="subheading"><FormattedMessage id="page.project.new.subheading" /></span>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width="3" textAlign="center">
                            <span className="more">+</span>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <CreateProjectWizard {...this.props} visible={this.state.wizardVisible} closeModal={this.closeCreateProject}></CreateProjectWizard>
            </Segment>;
        }
    }
    private showCreateProject = () => {
        this.setState({wizardVisible: true});
    }

    private closeCreateProject = () => {
        this.setState({ wizardVisible: false });
    }

    private renderLoadMore = (): React.ReactElement<any> => {
        const projects: Project [] = this.props.state.projectState.data;
        if (this.props.state.projectState.hasMore) {
            const loadingMore: boolean | undefined = this.props.state.projectState.loadingMore;
            const createdAt: string | undefined = projects[projects.length - 1].createdAt;
            if (!createdAt) {
                return <Fragment></Fragment>;
            }
            return <Button fluid basic
                onClick={() => { this.loadMore(createdAt); }}
                loading={loadingMore}
                disabled={loadingMore} >
                <Button.Content>
                    <FormattedMessage id="page.project.load_more" />
                </Button.Content>
            </Button>;
        } else {
            return <Fragment></Fragment>;
        }
    }

    private loadMore = (createdAt: string): void => {
        this.props.actions.getMoreProjects(createdAt);
    }
}

export default connectAllProps(ProjectList);