import UploaderActionCreator from "../models/client/UploaderActionCreator";
import { Dispatch } from "redux";
import fetch from "../shared/fetch";
import actions from "./common";
import { Uploader } from "../models/Uploader";
import GetUploadersResponse from "../models/response/GetUploadersResponse";
import { getToast as toast } from "../shared/toast";

export const SAVE_UPLOADER_BEGIN: string = "SAVE_UPLOADER_BEGIN";
export const SAVE_UPLOADER_SUCCESS: string = "SAVE_UPLOADER_SUCCESS";
export const SAVE_UPLOADER_FAILED: string = "SAVE_UPLOADER_FAILED";
export const REMOVE_UPLOADER_BEGIN: string = "REMOVE_UPLOADER_BEGIN";
export const REMOVE_UPLOADER_SUCCESS: string = "REMOVE_UPLOADER_SUCCESS";
export const REMOVE_UPLOADER_FAILED: string = "REMOVE_UPLOADER_FAILED";
export const GET_UPLOADER_BEGIN: string = "GET_UPLOADER_BEGIN";
export const GET_UPLOADER_SUCCESS: string = "GET_UPLOADER_SUCCESS";
export const GET_UPLOADER_FAILED: string = "GET_UPLOADER_FAILED";

const uploaderActionCreator: UploaderActionCreator = {
    getUploaders(): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: GET_UPLOADER_BEGIN});
            fetch("/api/uploader", undefined, "GET")
            .then((json: GetUploadersResponse) => {
                if (json && json.data) {
                    dispatch({
                        type: GET_UPLOADER_SUCCESS,
                        uploaders: json.data
                    });
                } else {
                    return Promise.reject({ name: "500 Internal Server Error", message: "" });
                }
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(GET_UPLOADER_FAILED, error));
            });
        };
    },
    getUploader(id: string): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: GET_UPLOADER_BEGIN});
            fetch(`/api/uploader/${id}`, undefined, "GET")
            .then((json: GetUploadersResponse) => {
                if (json && json.data) {
                    dispatch({
                        type: GET_UPLOADER_SUCCESS,
                        uploaders: json.data
                    });
                } else {
                    return Promise.reject({ name: "500 Internal Server Error", message: "" });
                }
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(GET_UPLOADER_FAILED, error));
            });
        };
    },
    addUploader(uploader: Uploader): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: SAVE_UPLOADER_BEGIN});
            fetch("/api/uploader/create", { ...uploader }, "POST", /*withToken*/ true)
            .then((added: Uploader) => {
                if (added) {
                    toast().success("toast.uploader.save_successfully");
                    dispatch({
                        type: SAVE_UPLOADER_SUCCESS,
                        uploader: added,
                        redirectTask: {
                            redirected: false,
                            to: `/uploaders/${added._id}`
                        }
                    });
                } else {
                    return Promise.reject({ name: "500 Internal Server Error", message: "Broken data." });
                }
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(SAVE_UPLOADER_FAILED, error));
            });
        };
    },
    updateUploader(uploader: Uploader): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: SAVE_UPLOADER_BEGIN});
            fetch("/api/uploader/edit", uploader, "POST", /*withToken*/ true)
            .then((updated: Uploader) => {
                toast().success("toast.uploader.save_successfully");
                dispatch({
                    type: SAVE_UPLOADER_SUCCESS,
                    uploader: updated,
                    redirectTask: {
                        redirected: false,
                        to: `/uploaders/${updated._id}`
                    }
                });
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(SAVE_UPLOADER_FAILED, error));
            });
        };
    },
    removeUploader(id: string): any {
        return (dispatch: Dispatch<any>): void => {
            dispatch({type: REMOVE_UPLOADER_BEGIN});
            fetch(`/api/uploader/remove/${id}`, undefined, "GET", /*withToken*/ true)
            .then((json: any) => {
                toast().success("toast.uploader.delete_successfully");
                dispatch({
                    type: REMOVE_UPLOADER_SUCCESS,
                    redirectTask: {
                        redirected: false,
                        to: "/uploaders"
                    }
                });
            })
            .catch((error: Error) => {
                dispatch(actions.handleFetchError(REMOVE_UPLOADER_FAILED, error));
            });
        };
    }
};

export default uploaderActionCreator;