import React from "react";
import { Header, Icon, Grid, Button, Container, Tab } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ComponentProps } from "../../../shared/ComponentProps";
import User from "../../../models/User";
import connectAllProps from "../../../shared/connect";
import { TableView } from "@airjam/react-table-view";

interface States {}
class AppointmentHow extends React.Component<ComponentProps, States> {
    render() {
        const user: User | undefined = this.props.state.userState.currentUser;
        return <Container style={CONTAINER_STYLE}>
            <Grid stackable>
                <Grid.Row style={{ minHeight: 30 }}></Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={10} verticalAlign="bottom" textAlign="center">
                        <Header textAlign="center" size="huge">
                            <div>
                                <Icon name="calendar check"></Icon>
                            </div>
                            <span className="punchLine">Appointment Component</span>
                        </Header>
                        <Header textAlign="center" size="huge">
                            <Header.Subheader className="punchLineSubHeader">
                                Creating a new component
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={10}>
                        The Appointment component offers a variety of ways to customize the behavior and the look and feel of your component, so you can tailor your component to suit your business needs. With the right configurations, you can unlock the full potential of your Appointment component to meet your demanding needs. This guide is designed to help you get started with creating your first Appointment component, and shows how to configure the options to help you configure your component to your needs.
                        <br />

                        <h3>Creating your first component</h3>
                        <br />
                        Let's get started with creating your very own Appointment component! To begin, you'll need an AirJam account. You can either <b><a href="https://airjam.co/join/r/appointments">create a new account</a></b> or <b><a href="https://airjam.co/login/r/appointments">log in to your existing account</a></b> to continue.

                        <ol>
                            <li>First, go to the <b><a href="http://airjam.co/appointments/">Appointment component console</a></b>. From there, you can create a new component by clicking the plus circle icon located in the bottom-right corner.</li>
                            <li>
                                In the <b>General</b> tab, enter a meaningful name and description for the component you are creating. You can save your progress anytime from this stage by clicking the Submit button located at the bottom of the left panel now, or at a later stage when you've finished with your configurations. Below are details of the additional options available in the General tab for further configuration:
                                <ol>
                                    <li><b>Default Timezone</b>: This sets the default timezone for the component, which is used to configure settings like operating hours and availability. It will also be the timezone shown to users if AirJam cannot automatically determine their time zone preference.</li>
                                    <li><b>Authentication Provider</b>: Use this option if you require your users to be signed in to access your booking features. AirJam supports popular authentication providers like Firebase and Clerk, and you can also integrate your own authentication service. Detailed instructions on configuring each authentication provider will show when you select it from the dropdown menu.</li>
                                    <li>Payment Processor<b>: If you're collecting payments for bookings, you will need to use this option to integrate a payment processor of your choice. Currently, AirJam only supports <b>Stripe</b> as the payment processor for this use.</b>
                                    <br /><br />
                                    When using Stripe, enter your <b>Stripe Secret Key</b> into the text box labeled <b>Processor API Key</b> after selecting Stripe as your payment processor.
                                    <br /><br />
                                    On the client / plugin side, provide your <b>Stripe Publishable Key</b> in the <b>Payment Processor Publishable Key</b> field to enable AirJam to manage Stripe's payment flow.
                                    <br /><br />
                                    <b>Important</b>: You must configure this setting properly to have your paid resources to show up to your users.
                                    </li>
                                </ol>
                            </li>
                            <li>The Appointment component requires that you configure at least one resource to display booking availability to your users. In other words, your component will not show any availability to book until you've added at least one resource to your component.
                                    <br /><br />
                                    Resources are distinct and exclusive entities with their own schedules and availability. For example, a resource can represent a single stylist in a salon or a single meeting room. Each resource can have its own rates, operating hours, its own availability and calendar sources, and so on.
                                    <br /><br />
                                    If you have multiple services sharing the same availability (e.g., a single professional offering different services with varying rates and durations), you can handle this by linking all shared resources to the same calendar data source (e.g., a shared Google Calendar).
                                    <br /><br />
                                    To get started, go to the <b>Resources</b> tab and click <b>New Resource</b> to enter details for your first resource. Below are options available in the <b>Resource</b> section for you to customize your resource:
                                    <br /><br />
                                    <ol>
                                        <li><b>Resource Name</b>: (Required) This is the name displayed to the users to identify a specific resource within the booking flow.</li>
                                        <li><b>Resource Description</b>: Enter description of the resource that will help your users to understand what they are booking. Use this field to help your users understand more details about the resource, including its purpose, features, or anything relevant information about its bookings, etc.</li>
                                        <li><b>Static Location</b>: This is an optional field to specify a physical location for the resource. By default this information will be shared with your users when they have made a booking for the resource, or when you use a map-based UI to let your users search for a resource by its location.</li>
                                        <li><b>Booking Duration</b>: Indicate whether your resource can be booked for a fixed duration (eg. 30 minutes, an hour), or for a flexible amount of time. For flexible durations, you can specify the time increment in which bookings should be made. If you'd like to avoid back-to-back bookings, you can also set buffer times before and after each meeting, so you can schedule more effectively.</li>
                                        <li><b>Availability</b>: Limit how far into the future users can book the resource by specifying how far into the future the bookings will be available for. Use this field if you'd like to prevent your users from scheduling appointments too far ahead, or beyond a specific date, etc.</li>
                                        <li><b>Quantity Available</b>: This field specifies how many bookings can be made for the same time slot. This is used for resources that can accommodate multiple bookings simultaneously, such as group sessions or shared spaces, etc.</li>
                                        <li><b>Base Price</b>: This field specifies the base pricing for your resource, which will be either fixed price for event for fixed-duration resources, or price per unit of time (eg. per hour) for flexible-duration resources.</li>
                                        <li><b>Moderation</b>: You can enable moderation for booking requests by setting the moderation option to <b>moderate</b>. By default, bookings are confirmed immediately without requiring moderation.</li>
                                        <li><b>Operating Hours</b>: Specify the resource's operating hours for each day of the week. You can set uniform hours for all seven days or customize them for each day individually. Note that the specified hours are based on the default timezone configured for the entire component.</li>
                                    </ol>
                            </li>
                            <li>If you want to add more than one resource to your component, click <b>Add a New Resource</b> under the <b>Resources</b> tab. Repeat the previous step to configure additional resource(s) as necessary. There is no limit on how many resources you can add to each component.</li>
                            <li>Once you've finished configuring your resources, click the <b>Submit</b> button on the left panel to save your changes and create your new component, or click the <b>Update</b> button to reflect changes you've made to the component you've already created. To add your new component to your app or a service, you'll need to add an appropriate client / plugin for your project and configure it. Refer to the instructions in the <b>Setup / Installation</b> tab for instructions on how to add and configure an appropriate client for your application's framework.</li>
                        </ol>
                        <h3>Customizing the email messages</h3>

                        With AirJam's Appointment component, you have comprehensive control over the messages that are sent to your users during various phases of their booking flow, including the visuals, the information, messaging, and localization. You can configure and customize these messages directly from your component's console using a mix of HTML and templating. To get started, goto <b>Email Messages</b> tab in your component console, then select the type of message you would like to customize from the dropdown list shown in that section.
                        <br /><br />
                        AirJam's templates are styled using <a href="https://eta.js.org/docs/intro/template-syntax"><b>ETA's syntax</b> (https://eta.js.org/docs/intro/template-syntax)</a>. Please refer to <a href="https://eta.js.org/docs/intro/template-syntax"><b>ETA's documentation</b> (https://eta.js.org/docs/intro/template-syntax)</a> on how to use ETA's syntax properly. AirJam's default templates for messaging are formatted using HTML, and you can choose to opt out of using HTML and use plaintext for messages as desired. For a complete list of all the information each message has access to, please refer to the instructions provided next to the template editor for each of the messages.

                    </Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
            </Grid>
        </Container>;
    }
}

export default connectAllProps(AppointmentHow);