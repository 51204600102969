import Block from "../../../models/Block";
import { Form, Button, FormGroup, Dropdown } from "semantic-ui-react";
import { RefObject } from "react";
import React from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import connectAllProps from "../../../shared/connect";
import ResponsiveFormField from "../shared/ResponsiveFormField";
import { PrimitiveType } from "intl-messageformat";
import WarningModal from "../shared/WarningModal";
import { ComponentProps } from "../../../shared/ComponentProps";
import sharingTypes from "../components/SharingTypes";
import dataSources from "../components/DataSources";

interface Props extends ComponentProps {
    block?: Block;
    submitTextId: string;
    onSubmit: (title: string, dataSource: string, sharingType: string) => void;
    loading?: boolean;
}

interface States {
    editing: boolean;
    openClearEditWarning: boolean;
}


class BlockEditor extends React.Component<Props, States> {
    private titleRef: RefObject<HTMLInputElement>;
    private dataSourceRef: RefObject<HTMLInputElement>;
    private sharingTypeRef: RefObject<HTMLInputElement>;
    private originalTitle: string = "";
    private originalDataSource: string = "";
    private originalSharingType: string = "";
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.titleRef = React.createRef();
        this.dataSourceRef = React.createRef();
        this.sharingTypeRef = React.createRef();
        this.getString = this.props.intl.formatMessage;
        this.state = {
            editing: false,
            openClearEditWarning: false
        };
    }
    render(): React.ReactElement<any> {
        const getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string
            = this.props.intl.formatMessage;
        if (this.props.block) {
            this.originalTitle = this.props.block.title;
            this.originalDataSource = this.props.block.dataSource;
            this.originalSharingType = this.props.block.sharingType;
        }
        return (
            <Form>
                <ResponsiveFormField>
                    <label>
                        <FormattedMessage id="block.title" />
                    </label>
                    <input ref={this.titleRef} autoFocus={true}
                        defaultValue={this.originalTitle} onChange={this.onEditing} />
                </ResponsiveFormField>
                <ResponsiveFormField>
                    <label>
                        <FormattedMessage id="block.data_source" />
                    </label>
                    <Dropdown
                        placeholder={getString({ id: "component.block.data_source_select" })}
                        fluid
                        selection
                        defaultValue={this.originalDataSource}
                        onChange={(e: any, value: any) => {
                            if (this.dataSourceRef.current) this.dataSourceRef.current.value = value.value;
                            this.onEditing();
                        }}
                        options={dataSources(this.props)}
                    />
                    <input type="hidden" ref={this.dataSourceRef}
                        defaultValue={this.originalDataSource} onChange={this.onEditing} />
                </ResponsiveFormField>
                <ResponsiveFormField>
                    <label>
                        <FormattedMessage id="block.sharing_type" />
                    </label>

                    <Dropdown
                        placeholder={getString({ id: "component.block.sharing_type_select" }) }
                        fluid
                        selection
                        defaultValue={this.originalSharingType}
                        onChange={(e: any, value: any) => {
                            if (this.sharingTypeRef.current) this.sharingTypeRef.current.value = value.value;
                            this.onEditing();
                        }}
                        options={sharingTypes(this.props)}
                    />
                    <input type="hidden" ref={this.sharingTypeRef}
                        defaultValue={this.originalSharingType} onChange={this.onEditing} />
                </ResponsiveFormField>
                <FormGroup inline>
                    <Form.Field control={Button} onClick={this.onSubmit} primary
                        loading={this.props.loading}
                        disabled={this.props.loading || !this.state.editing}>
                        <FormattedMessage id={this.props.submitTextId} />
                    </Form.Field>
                    <Form.Field control={Button} onClick={() => this.setState({ openClearEditWarning: true })}
                        loading={this.props.loading}
                        disabled={this.props.loading || !this.state.editing}>
                        <FormattedMessage id="component.button.clear_edit" />
                    </Form.Field>
                </FormGroup>
                {this.renderClearEditWarningModal()}
            </Form>
        );
    }
    private onSubmit = (): void => {
        const title: any = this.titleRef.current && this.titleRef.current.value;
        const dataSourceRef: any = this.dataSourceRef.current && this.dataSourceRef.current.value;
        const sharingTypeRef: any = this.sharingTypeRef.current && this.sharingTypeRef.current.value;
        this.props.onSubmit(title, dataSourceRef, sharingTypeRef);
    }

    private clearEditing = () => {
        if (this.titleRef.current) this.titleRef.current.value = this.originalTitle;
        if (this.dataSourceRef.current) this.dataSourceRef.current.value = this.originalDataSource;
        if (this.sharingTypeRef.current) this.sharingTypeRef.current.value = this.originalSharingType;
        this.setState({
            editing: false,
            openClearEditWarning: false
        });
    }

    private onEditing = () => {
        if (!this.titleRef.current || !this.dataSourceRef.current || !this.sharingTypeRef.current) {
            return;
        }
        const instanceTitle: string = this.titleRef.current.value;
        const instanceDataSource: string = this.dataSourceRef.current.value;
        const instanceSharingType: string = this.sharingTypeRef.current.value;
        if (this.originalTitle === instanceTitle
            && this.originalDataSource === instanceDataSource
            && this.originalSharingType === instanceSharingType) {
            this.setState({
                editing: false
            });
        } else {
            this.setState({
                editing: true
            });
        }
    }

    private renderClearEditWarningModal = (): React.ReactElement<any> | undefined => {
        return <WarningModal
            descriptionIcon="close" open={this.state.openClearEditWarning}
            descriptionText={this.getString({ id: "page.block.clear_edit" })}
            warningText={this.getString({ id: "page.block.clear_edit_confirmation" })}
            onConfirm={this.clearEditing}
            onCancel={() => { this.setState({ openClearEditWarning: false }); }} />;
    }
}

export default connectAllProps(BlockEditor);