import React from "react";
import connectAllProps from "../../../shared/connect";
import { MessageDescriptor } from "react-intl";
import { ComponentProps } from "../../../shared/ComponentProps";
import { PrimitiveType } from "intl-messageformat";
import { ComponentTranslation, TranslationDescription, Translation, CALENDAR_DEFAULT_TRANSLATIONS } from "@airjam/types";
import { Button, Dropdown, Grid, Icon, Input, Menu, MenuItem, Popup, Segment, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "semantic-ui-react";
import * as locale from "locale-codes";
import { over } from "lodash";

const DEFAULT_LOCALE = "en-US";

interface Props extends ComponentProps {
    translation: ComponentTranslation;
    defaultTranslation: ComponentTranslation;
    onChange?: (translation: ComponentTranslation) => void;
}

interface States {
    descriptions: {[key: string]: TranslationDescription};
    clientTranslations: {[locale: string]: Translation};
    serverTranslations: {[locale: string]: Translation};
    defaultLocale: string;
    supportedLocales: string[];

    localeOptions: any[];
    selectedLocale: string;

    newServerTranslationId: string;
    newServerTranslationDescription: string;
    newServerTranslationTranslation: string;
    newClientTranslationId: string;
    newClientTranslationDescription: string;
    newClientTranslationTranslation: string;

}

class ComponentTranslationEditor extends React.Component<Props, States> {
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    private allLocales: any;

    constructor(props: Props) {
        super(props);
        this.getString = this.props.intl.formatMessage;
        this.allLocales = this.getLocaleDropdownOptions();
        const defaultLocale = this.props.translation && this.props.translation.defaultLocale ? this.props.translation.defaultLocale : DEFAULT_LOCALE;
        const supportedLocale = this.props.translation && this.props.translation.supportedLocales ? this.props.translation.supportedLocales : [DEFAULT_LOCALE];
        this.state = {
            descriptions: this.props.translation ? this.props.translation.descriptions : {},
            clientTranslations: this.props.translation ? this.props.translation.clientTranslations : {},
            serverTranslations: this.props.translation ? this.props.translation.serverTranslations : {},
            defaultLocale: defaultLocale,
            selectedLocale: defaultLocale,
            supportedLocales: supportedLocale,
            localeOptions: this.getDefaultLocaleOptions(defaultLocale, supportedLocale),
            newServerTranslationId: "",
            newServerTranslationDescription: "",
            newServerTranslationTranslation: "",
            newClientTranslationId: "",
            newClientTranslationDescription: "",
            newClientTranslationTranslation: "",
        };
    }

    render(): React.ReactElement<any> {
        const defaultServerKeys = this.props.defaultTranslation ? this.getTranslationKeys(this.props.defaultTranslation.serverTranslations) : [];
        const defaultServerKeysMap = strArrToHashMap(defaultServerKeys);
        const instanceServerKeys = this.props.translation ? this.getTranslationKeys(this.props.translation.serverTranslations) : [];
        const instanceServerKeysMap = strArrToHashMap(instanceServerKeys);
        const uniqueServerCustomKeys = this.getUniqueCustomTranslationKeys(defaultServerKeys, instanceServerKeys);
        const uniqueServerCustomKeysMap = strArrToHashMap(uniqueServerCustomKeys);

        const defaultClientKeys = this.props.defaultTranslation ? this.getTranslationKeys(this.props.defaultTranslation.clientTranslations) : [];
        const defaultClientKeysMap = strArrToHashMap(defaultClientKeys);
        const instanceClientKeys = this.props.translation ? this.getTranslationKeys(this.props.translation.clientTranslations) : [];
        const instanceClientKeysMap = strArrToHashMap(instanceClientKeys);
        const uniqueClientCustomKeys = this.getUniqueCustomTranslationKeys(defaultClientKeys, instanceClientKeys);
        const uniqueClientCustomKeysMap = strArrToHashMap(uniqueClientCustomKeys);

        const defaultServerTranslations: Translation = this.props.defaultTranslation && this.props.defaultTranslation.serverTranslations ? this.props.defaultTranslation.serverTranslations[DEFAULT_LOCALE] : {} as Translation;
        const defaultClientTranslations: Translation = this.props.defaultTranslation && this.props.defaultTranslation.clientTranslations ? this.props.defaultTranslation.clientTranslations[DEFAULT_LOCALE] : {} as Translation;

        const addNewServerCustomKey = (locale: string) => {
            if (!this.state.newServerTranslationId ||
                !this.state.newServerTranslationTranslation ||
                defaultServerKeysMap[this.state.newServerTranslationId] ||
                instanceServerKeysMap[this.state.newServerTranslationId] ||
                uniqueServerCustomKeysMap[this.state.newServerTranslationId]) {
                console.log("cannot add");
                return;
            }
            const serverTrans = this.state.serverTranslations ? this.state.serverTranslations : {} as {[locale: string]: Translation};
            const trans = serverTrans[locale] ? serverTrans[locale] : {} as Translation;
            if (!trans.messages) trans.messages = {};
            trans.locale = locale;
            trans.messages[this.state.newServerTranslationId] = this.state.newServerTranslationTranslation;
            serverTrans[locale] = trans;
            const descriptions = this.state.descriptions ? this.state.descriptions : {} as {[k: string]: TranslationDescription};
            descriptions[this.state.newServerTranslationId] = { description: this.state.newServerTranslationDescription } as TranslationDescription ;
            this.setState({
                serverTranslations: serverTrans,
                descriptions: descriptions,
            }, this.onEdit);
        };

        const addNewClientCustomKey = (locale: string) => {
            if (!this.state.newClientTranslationId ||
                !this.state.newClientTranslationTranslation ||
                defaultClientKeysMap[this.state.newClientTranslationId] ||
                instanceClientKeysMap[this.state.newClientTranslationId] ||
                uniqueClientCustomKeysMap[this.state.newClientTranslationId]) {
                console.log("cannot add");
                return;
            }
            const clientTrans = this.state.clientTranslations ? this.state.clientTranslations : {} as {[locale: string]: Translation};
            const trans = clientTrans[locale] ? clientTrans[locale] : {} as Translation;
            if (!trans.messages) trans.messages = {};
            trans.locale = locale;
            trans.messages[this.state.newClientTranslationId] = this.state.newClientTranslationTranslation;
            clientTrans[locale] = trans;
            const descriptions = this.state.descriptions ? this.state.descriptions : {} as {[k: string]: TranslationDescription};
            descriptions[this.state.newClientTranslationId] = { description: this.state.newClientTranslationDescription } as TranslationDescription ;
            this.setState({
                clientTranslations: clientTrans,
                descriptions: descriptions,
            }, this.onEdit);
        };

        return <Grid className="componentTranslationEditor">
            <Grid.Row>
                <Grid.Column width={3}>
                    Default language
                </Grid.Column>
                <Grid.Column width={13}>
                    <Dropdown
                        search
                        options={this.state.localeOptions}
                        defaultValue={this.state.defaultLocale ? String(this.state.defaultLocale) : ""}
                        placeholder="Select language"
                        onChange={(e: any, value: any) => {
                            this.setState({
                                defaultLocale: value.value
                            }, this.onEdit);
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={3}>
                    Supported Languages
                </Grid.Column>
                <Grid.Column width={13}>
                    <Dropdown
                        clearable
                        fluid
                        multiple
                        search
                        selection
                        options={this.allLocales}
                        onChange={(e: any, value: any) => {
                            const distinctValues = this.makeElementsDistinct(value.value);
                            // TODO --> disallow users from deleting default locale.
                            // if (this.state.defaultLocale && !this.isInArray(this.state.defaultLocale, distinctValues)) {
                            //     alert("you cannot delete a default locale");
                            //     distinctValues.push(this.state.defaultLocale);
                            //     // TODO --> cancel action
                            // }
                                // localeOptions: this.getDefaultLocaleOptions(this.state.defaultLocale, distinctValues)
                            this.setState({
                                supportedLocales: distinctValues,
                            }, this.onEdit);
                        }}
                        defaultValue={this.state.supportedLocales}
                        placeholder="Select Language"
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={3}>
                    Translations
                </Grid.Column>
                <Grid.Column width={13}>
                    <Menu attached="top" tabular>
                        {this.state.supportedLocales.map((locale) => {
                            return <MenuItem key={"editor-selector-menu-" + locale}
                                    active={this.state.selectedLocale === locale}
                                    onClick={() => {
                                this.setState({ selectedLocale: locale});
                                this.forceUpdate();
                            }}>{locale}</MenuItem>;
                        })}
                    </Menu>
                    <Segment attached="bottom">
                        {this.state.supportedLocales.map((locale) => {
                            const serverTranslations = this.state.serverTranslations ? this.state.serverTranslations[locale] : {} as Translation;
                            const clientTranslations = this.state.clientTranslations ? this.state.clientTranslations[locale] : {} as Translation;
                            return <div key={"editor-pane-" + locale} className={this.state.selectedLocale !== locale ? "hideImportant" : ""} >
                                <h4>Server Keys</h4>
                                <Table celled striped>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHeaderCell>ID</TableHeaderCell>
                                            <TableHeaderCell>Text</TableHeaderCell>
                                        </TableRow>
                                    </TableHeader>
                                    {this.renderLanguageTranslationFields(
                                        defaultServerTranslations,
                                        this.state.descriptions,
                                        serverTranslations,
                                        defaultServerKeys,
                                        uniqueServerCustomKeys,
                                        (key, newText) => {
                                            const serverTrans = this.state.serverTranslations ? this.state.serverTranslations : {} as {[locale: string]: Translation};
                                            const trans = serverTrans[locale] ? serverTrans[locale] : {} as Translation;
                                            if (!trans.messages) trans.messages = {};
                                            trans.locale = locale;
                                            trans.messages[key] = newText;
                                            serverTrans[locale] = trans;
                                            console.log("new server trans: " + key   + " - " + newText);
                                            this.setState({
                                                serverTranslations: serverTrans,
                                            }, this.onEdit);
                                    })}
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Button fluid primary
                                                    disabled={!this.state.newServerTranslationId ||
                                                        !this.state.newServerTranslationTranslation ||
                                                        defaultServerKeysMap[this.state.newServerTranslationId] ||
                                                        instanceServerKeysMap[this.state.newServerTranslationId] ||
                                                        uniqueServerCustomKeysMap[this.state.newServerTranslationId]}
                                                    onClick={() => addNewServerCustomKey(locale)}><Icon name="plus"></Icon>Add</Button>
                                                <br />
                                                <Popup content={"Enter a unique id for your new translating phrase. Add button above will be disabled if the ID is empty or not unique"} trigger={<span>{"ID:"}</span>} />
                                                <input onChange={(e) => { this.setState({newServerTranslationId: e.target.value }, this.forceUpdate); }}/>
                                            </TableCell>
                                            <TableCell>
                                                <Popup content={"Describe your new translating phrase"} trigger={<span>{"Description:"}</span>} />
                                                <input onChange={(e) => { this.setState({newServerTranslationDescription: e.target.value }, this.forceUpdate); }}/>
                                                <Popup content={"Enter the actual translation in current locale: " + locale} trigger={<span>{"Translation:"}</span>} />
                                                <input onChange={(e) => { this.setState({newServerTranslationTranslation: e.target.value }, this.forceUpdate); }}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>

                                <h4>Client Keys</h4>
                                <Table celled striped>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHeaderCell>ID</TableHeaderCell>
                                            <TableHeaderCell>Text</TableHeaderCell>
                                        </TableRow>
                                    </TableHeader>
                                    {this.renderLanguageTranslationFields(
                                        defaultClientTranslations,
                                        this.state.descriptions,
                                        clientTranslations,
                                        defaultClientKeys,
                                        uniqueClientCustomKeys,
                                        (key, newText) => {
                                            const clientTrans = this.state.clientTranslations ? this.state.clientTranslations : {} as {[locale: string]: Translation};
                                            const trans = clientTrans[locale] ? clientTrans[locale] : {} as Translation;
                                            if (!trans.messages) trans.messages = {};
                                            trans.locale = locale;
                                            trans.messages[key] = newText;
                                            clientTrans[locale] = trans;
                                            console.log("new client trans: " + key   + " - " + newText);
                                            this.setState({
                                                clientTranslations: clientTrans,
                                            }, this.onEdit);
                                    })}
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Button fluid primary
                                                    disabled={!this.state.newClientTranslationId ||
                                                        !this.state.newClientTranslationTranslation ||
                                                        defaultClientKeysMap[this.state.newClientTranslationId] ||
                                                        instanceClientKeysMap[this.state.newClientTranslationId] ||
                                                        uniqueClientCustomKeysMap[this.state.newClientTranslationId]}
                                                    onClick={() => addNewClientCustomKey(locale)}><Icon name="plus"></Icon>Add</Button>
                                                <br />
                                                <Popup content={"Enter a unique id for your new translating phrase. Add button above will be disabled if the ID is empty or not unique"} trigger={<span>{"ID:"}</span>} />
                                                <input onChange={(e) => { this.setState({newClientTranslationId: e.target.value }, this.forceUpdate); }}/>
                                            </TableCell>
                                            <TableCell>
                                                <Popup content={"Describe your new translating phrase"} trigger={<span>{"Description:"}</span>} />
                                                <input onChange={(e) => { this.setState({newClientTranslationDescription: e.target.value }, this.forceUpdate); }}/>
                                                <Popup content={"Enter the actual translation in current locale: " + locale} trigger={<span>{"Translation:"}</span>} />
                                                <input onChange={(e) => { this.setState({newClientTranslationTranslation: e.target.value }, this.forceUpdate); }}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>;
                        })}
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>;
    }

    private renderLanguageTranslationFields = (defaultTranslation: Translation, descriptions: {[key: string]: TranslationDescription}, translation: Translation, defaultKeys: string[], customKeys: string[], onChangeCb: (key: string, newText: string) => void) => {
        // defaultKeys and customKeys are assumed to be unique
        // 1. go through default keys, get translations
        return <TableBody>
            {defaultKeys.map(key => {
                const exampleToShow = this.props.defaultTranslation && this.props.defaultTranslation.descriptions ? this.props.defaultTranslation.descriptions[key].example : "";
                const defaultText = defaultTranslation && defaultTranslation.messages[key] ? defaultTranslation.messages[key] : exampleToShow;
                const descriptionToShow = descriptions && descriptions[key] ? descriptions[key].description : (this.props.defaultTranslation && this.props.defaultTranslation.descriptions ? this.props.defaultTranslation.descriptions[key].description : "");
                return <TableRow key={"editor-" + key}>
                    <TableCell collapsing key={"language-row-" + key} width={3}>
                        <Popup content={key} trigger={<span>{key.length > 15 ? key.substring(0, 11) + "..." : key}</span>} />
                    </TableCell>
                    <TableCell>
                        <div>{descriptionToShow}</div>
                        <div className="locale-edit-field">
                            <Input
                                onChange={(e: any) => {
                                    // console.log(e.target.value);
                                    onChangeCb(key, e.target.value);
                                }}
                                defaultValue={translation && translation.messages && translation.messages[key] ? translation.messages[key] : defaultText}
                                />
                        </div>
                    </TableCell>
                </TableRow>;
            })}
            {customKeys.map(key => {
                const descriptionToShow = descriptions && descriptions[key] ? descriptions[key].description : (this.props.defaultTranslation && this.props.defaultTranslation.descriptions ? this.props.defaultTranslation.descriptions[key].description : "");
                return <TableRow key={"editor-custom-" + key}>
                    <TableCell>
                        <Popup content={key} trigger={<span>{key.length > 12 ? key.substring(0, 9) + "..." : key}</span>} />
                        <span style={{cursor: "pointer"}} onClick={() => {this.removeCustomKey(key); }}><Icon name="trash"></Icon></span>
                    </TableCell>
                    <TableCell>
                        <div>{descriptionToShow}</div>
                        <div className="locale-edit-field">
                            <input defaultValue={translation && translation.messages && translation.messages[key] ? translation.messages[key] : "" }
                                onChange={(e: any) => {
                                    // console.log(e.target.value);
                                    onChangeCb(key, e.target.value);
                                }}
                            />
                        </div>
                    </TableCell>
                </TableRow>;
            })}
        </TableBody>;
    }

    private getLocaleDropdownOptions = () => {
        const allLocales = locale.all;
        const uniqueLocales: {[key: string]: locale.ILocale} = {};
        allLocales.forEach(l => { uniqueLocales[l.tag] = l; });
        return Object.values(uniqueLocales).map(this.iLocaleToDropdown);
    }

    private getDefaultLocaleOptions = (currentDefaultLocale: string, supportLocales: string[]) => {
        let options = supportLocales;
        // console.log("current default locale: " + currentDefaultLocale);
        if (currentDefaultLocale) {
            let defaultFound: boolean = false;
            supportLocales.forEach(l => {
                if (l && l === currentDefaultLocale) {
                    defaultFound = true;
                }
            });
            if (!defaultFound && currentDefaultLocale) {
                options = [];
                options.push(currentDefaultLocale);
                options.push(...supportLocales);
            }
        }
        if (options && options.length > 0)
            return options.map(o => {
                return {key: o, value: o, text: o};
            });
        return [{key: DEFAULT_LOCALE, value: DEFAULT_LOCALE, text: DEFAULT_LOCALE}];
    }

    private iLocaleToDropdown = (iLocale: locale.ILocale) => {
        return {key: iLocale.tag, value: iLocale.tag, text: this.getILocaleName(iLocale)};
    }

    private getILocaleName = (iLocale: locale.ILocale) => {
        return `${iLocale.local ? iLocale.name + " / " + iLocale.local : iLocale.name} (${iLocale.tag})${iLocale.location ? " - " + iLocale.location : ""}`;
    }

    private makeElementsDistinct = (arr: string[]) => {
        const distinctElem: {[key: string]: boolean} = {};
        arr.forEach(a => distinctElem[a] = true);
        return Object.keys(distinctElem);
    }

    private isInArray = (item: string, arr: string[]) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === item) return true;
        }
        return false;
    }

    private getUniqueCustomTranslationKeys = (defaultKeys: string[], customKeys: string[]) => {
        // assumes defaultKeys and customKeys are both arrays of unique elements on their own
        const uniqueCustomKeys: {[key: string]: boolean} = {};
        const defaultKeyMap: {[key: string]: boolean} = {};
        if (defaultKeys) defaultKeys.forEach((key) => defaultKeyMap[key] = true);
        if (customKeys)
            customKeys.forEach((key) => {
                if (!defaultKeyMap[key]) uniqueCustomKeys[key] = true;
            });
        return Object.keys(uniqueCustomKeys);
    }

    private getUniqueTranslationKeys = (component: ComponentTranslation): string[] => {
        const keyMap: {[key: string]: boolean} = {};
        if (component.descriptions)
            Object.keys(component.descriptions).forEach((key) => keyMap[key] = true);
        if (component.serverTranslations)
            Object.keys(component.serverTranslations).forEach((serverKey) => {
                if (!serverKey || !component.serverTranslations[serverKey]) return;
                Object.keys(component.serverTranslations[serverKey].messages).forEach((key) => keyMap[key] = true);
            });
        if (component.clientTranslations)
            Object.keys(component.clientTranslations).forEach((clientKey) => {
                if (!clientKey || !component.clientTranslations[clientKey]) return;
                Object.keys(component.clientTranslations[clientKey].messages).forEach((key) => keyMap[key] = true);
            });
        return Object.keys(keyMap);
    }

    private getTranslationKeys = (translations: {[locale: string]: Translation}): string[] => {
        const keyMap: {[key: string]: boolean} = {};
        if (translations) {
            Object.keys(translations).forEach((k) => {
                if (!k || !translations[k]) return;
                Object.keys(translations[k].messages).forEach((key) => keyMap[key] = true);
            });
        }
        return Object.keys(keyMap);
    }

    // private getUniqueLocales = (): string[] => {
    //     const localesMap: {[key: string]: boolean} = {};
    //     this.getUniqueComponentLocales(this.props.defaultTranslation).forEach((key) => localesMap[key] = true);
    //     this.getUniqueComponentLocales(this.props.translation).forEach((key) => localesMap[key] = true);
    //     return Object.keys(localesMap);
    // }

    // private getUniqueComponentLocales = (component: ComponentTranslation): string[] => {
    //     if (!component || !component.serverTranslations) return [];
    //     return Object.keys(component.serverTranslations);
    // }

    private onEdit = () => {
        if (this.props.onChange) {
            this.props.onChange({
                descriptions: this.state.descriptions,
                clientTranslations: this.state.clientTranslations,
                serverTranslations: this.state.serverTranslations,
                defaultLocale: this.state.defaultLocale,
                supportedLocales: this.state.supportedLocales,
            } as ComponentTranslation);
        }
        this.forceUpdate();
    }

    private removeCustomKey = (key: string) => {
        // remove from descriptions
        const descriptions = this.state.descriptions;
        delete(descriptions[key]);

        // remove if this is in server translations
        const serverTrans = this.state.serverTranslations;
        Object.keys(serverTrans).forEach(l => {
            const trans = serverTrans[l];
            delete(trans.messages[key]);
            serverTrans[l] = trans;
        });

        // remove if this is in client translations
        const clientTrans = this.state.clientTranslations;
        Object.keys(clientTrans).forEach(l => {
            const trans = clientTrans[l];
            delete(trans.messages[key]);
            clientTrans[l] = trans;
        });

        this.onEdit(); // notify
    };
}

const strArrToHashMap = (arr: string[]): {[key: string]: boolean} => {
    return arr.reduce(function(map: {[key: string]: boolean}, obj) {
        map[obj] = true;
        return map;
    }, {});
};

export default connectAllProps(ComponentTranslationEditor);