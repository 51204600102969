import React from "react";
import connectAllProps from "../../../shared/connect";
import { Redirect } from "react-router-dom";
import { Container, Header } from "semantic-ui-react";
import BlockEditor from "./BlockEditor";
import { CONTAINER_STYLE } from "../../../shared/styles";
import { FormattedMessage } from "react-intl";
import { isMobile } from "../dimension";
import { pendingRedirect } from "../../../shared/redirect";
import { ComponentProps as Props } from "../../../shared/ComponentProps";
import BlockType from "../../../models/BlockType";
import BlockPreferences from "../../../models/BlockPreferences";
import DataSource from "../../../models/DataSource";
import SharingType from "../../../models/SharingType";

interface States { }

class CreateBlock extends React.Component<Props, States> {
    componentDidMount() {
        // this.props.state.blockState.loading = false;
        // console.log("loaded");
    }

    render(): React.ReactElement<any> {
        if (pendingRedirect(this.props)) {
            return <Redirect to={this.props.state.redirectTask.to} />;
        } else if (this.props.state.userState.currentUser) {
            const loading: boolean | undefined = this.props.state.blockState.loading;
            const containerStyle: any = isMobile() ? CONTAINER_STYLE :
                { ...CONTAINER_STYLE, paddingLeft: 20, paddingRight: 20 };
            return (
                <Container style={containerStyle}>
                    <Header size={"medium"}>
                        <FormattedMessage id="page.block.add" />
                    </Header>
                    <BlockEditor
                        onSubmit={this.addBlock}
                        submitTextId="component.button.submit"
                        loading={loading} />
                </Container>
            );
        } else {
        return <p>not cool</p>;
        }
    }
    private addBlock = (title: String, dataSource: String, sharingType: String): void => {
        if (this.props.state.userState.currentUser) {
            const ddd: BlockPreferences = {};
            this.props.actions.addBlock(title, BlockType.ContentCollectionBlock, DataSource.ManagedNoSql, ddd, SharingType.Open);
        }
    }
}

export default connectAllProps(CreateBlock);