import React, { RefObject } from "react";
import connectAllProps from "../../../shared/connect";
import fetch from "../../../shared/fetch";
import { ComponentProps } from "../../../shared/ComponentProps";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";
import { MessageDescriptor } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import GetCalendarInstancesResponse from "../../../models/response/GetCalendarInstancesResponse";
import { CalendarInstance } from "@airjam/types";

interface Props extends ComponentProps {
    onChange: (componentId: string, selectedInstance: CalendarInstance) => void;
    showRefresh: boolean;
    initialKey: string;
    componentId: string;
    key: string;
}

interface States {
    options: DropdownItemProps[];
}

class GoogleCalendarCalendarSelector extends React.Component<Props, States> {
    private _isMounted: boolean = false;
    private calendarSelection: string;
    private calendarInstances: {[id: string]: CalendarInstance} = {};
    private calendarInstanceRef: RefObject<HTMLInputElement>;
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.getString = this.props.intl.formatMessage;
        this.calendarSelection = "";
        this.calendarInstanceRef = React.createRef();
        this.state = {
            options: [{ key: "0", value: "0", text: this.getString({ id: "component.block.data_source_select" }) }]
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.refreshSheets();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render(): React.ReactElement<any> {
        let dropdownText: string = this.getOptionTextFromValue(this.props.initialKey);
        if (this.calendarSelection) dropdownText = this.getOptionTextFromValue(this.calendarSelection);

        return <div><Dropdown
            key={this.props.key}
            text={dropdownText}
            value={this.calendarSelection ? this.calendarSelection : this.props.initialKey}
            fluid
            selection
            onChange={(e: any, value: any) => {
                if (this.calendarSelection !== value.value) {
                    this.calendarSelection = value.value;
                    if (this.calendarInstanceRef.current) this.calendarInstanceRef.current.value = value.value;
                    this.props.onChange(this.props.componentId, this.calendarInstances[value.value]);
                    this.forceUpdate();
                }
            }}
            options={this.state.options}
        />
        <input hidden style={{ display: "none" }} ref={this.calendarInstanceRef} defaultValue={this.props.initialKey}/>
        </div>;
    }

    private getOptionTextFromValue(value: string): string {
        this.state.options.forEach((option: DropdownItemProps) => {
            if (option.value === value) return option.text?.toString();
        });
        return "";
    }

    private refreshSheets() {
        fetch(`/api/calendar/calendars`, undefined, "GET", true)
            .then((res: GetCalendarInstancesResponse) => {
                if (res && res.calendars && this._isMounted) {
                this.setState({
                    options: []
                });
                res.calendars.forEach((calendar: CalendarInstance) => {
                    this.calendarInstances[calendar.id] = calendar;
                    this.state.options.push({
                        key: calendar.id,
                        text: calendar.name ? calendar.name : calendar.summary,
                        value: calendar.id
                    });
                });
                if (this.state.options.length === 0) {
                        this.state.options.push({
                        key: 0,
                        text: "No calendars available",
                        value: 0
                    });
                }
                this.forceUpdate();
            }
        }).catch((error: Error) => {
            const msg = error.message as any;
            if (msg && msg.name && msg.name === "oauth") {
                alert("Your authentication with Google is expired, and you are now being logged out. Please log in again to re-authenticate with Google");
                this.props.actions.logout();
            }
        });
    }
}

export default connectAllProps(GoogleCalendarCalendarSelector);