import React from "react";
import { Appointment } from "../../../../models/Appointment";
import AppointmentComponentAttributes from "./AppointmentComponentAttributes";
import { Link } from "react-router-dom";

interface Props {
    appointment?: Appointment;
}
interface States {}
export default class BubbleInstructions extends React.Component<Props, States> {
    render(): React.ReactElement<any> {
        return <div>
            AirJam's Appointment component can be added to a Bubble app through a Bubble plugin. Follow this step-by-step guide to integrate AirJam components into your Bubble app.
            <br /><br/>
            You will need an Appointment component created to get started. Please refer to <b><Link to="how">this guide</Link></b> if you would like to learn how to create one.
            <ol>
                <li>Go to this <strong><a href="https://bubble.io/plugin/booking-and-appointments-1730152230209x863112966839992300">link</a></strong>, select the Bubble app where you'd like to enable the component, and click Install to activate it.</li>
                <li>You should now be able to see the Appointment element in the <strong>Design &gt; Visual Elements</strong> panel on your Bubble app's editor page. Drag and drop it onto the screen where you'd like the Appointment component to appear.</li>
                {this.props.appointment && this.props.appointment.calendarComponentId ? <li>
                    The ID of this component is <strong><code>{this.props.appointment.calendarComponentId}</code></strong>. Copy this and paste it into the <strong><code>componentId</code></strong> property of the element you dragged in the previous step.
                </li> : <li>
                    The plugin requires the Appointment component's ID to operate. You can find this ID in the top left corner of your component's dashboard page. If you haven't created a component yet, go to the component's console and create a new one. Once you have the component ID, enter it in the <strong><code>componentId</code></strong> property of the element you added in the previous step.
                </li>}
            </ol>
That's it! You should now be able to see your component on your Bubble app! Please check out the properties section below to learn how to customize your component to make it work better for your situation.
            <br />
            <h3>Properties</h3>
            Use following properties in your Bubble plugin to modify the behavior of your component.
            <AppointmentComponentAttributes appointment={this.props.appointment} idName="componentId" />
        </div>;
    }
}