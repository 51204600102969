import React from "react";
import { Container, Header, Image } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../../shared/styles";
interface Props {}

interface States {}
export default class Privacy extends React.Component<Props, States> {
    render() {
        return <Container text style={CONTAINER_STYLE} className="blogPage">
            <div>
                <Header size="large">
                    Creating new projects on AirJam
                    <Header.Subheader>
                        Create your own checkout flows with prebuilt UI components.
                    </Header.Subheader>
                </Header>
                <p><i>10 minutes read</i></p>
                <p>&nbsp;</p>
                <p>
                    Add AirJam components to your websites in minutes! Projects make it easy to organize and manage components for your services.
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <div>
                    <Image.Group size="mini">
                        <Image src="/images/languages/js.png" />
                        <Image src="/images/languages/ts.png" />
                        <Image src="/images/languages/react.png" />
                    </Image.Group>
                    <Header size="small">
                        Prerequisites
                    </Header>
                    Before starting, you need to:
                    <ul>
                        <li>Ensure that you have access to the codebase of your website or application.</li>
                        <li>Check access to your Google Spreadsheet documents that you will be using as the source for your components.
                            <ul>
                                <li>Ensure that all columns are labeled correctly and that there are no dangling columns or rows. AirJam assumes that the first row is for labels and may omit rows that do not have proper labels.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </div>
            <div>
                <Header size="small">
                    1. Create a project
                </Header>
                <p>
                    To get started with AirJam, let's create a project. All components within AirJam are organized as a part of a project. Think of a project as a collection of components, typically representing a single application.
                </p>
                <p>
                    To create a new project, make sure you are signed in to the main workspace. Then, click on the [New Project] button.
                </p>
                <Image src="/images/blog/new_project.png" />
                <p>&nbsp;</p>
                <p>
                    If you did not sign in through Google, you will be prompted to link your Google account to get started. Once you sign in and link your Google account, you will be directed to a page to begin creating the project.
                </p>
                <p>&nbsp;</p>
                <p>On the first page of the wizard, give your project a meaningful name and click next.</p>
                <Image src="/images/blog/first_step.png" />
                <p>&nbsp;</p>
                <p>
                    Next, select the number of components you want to create for your project. AirJam currently only supports TableView components, so you will only be asked to choose how many TableView components you want to create. Please note that you can always add or remove components after creating the project.
                </p>
                <Image src="/images/blog/component_count.png" />
                <p>Click "Next" to begin configuring your components.</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </div>
            <div>
                <Header size="small">
                    2. Configure your TableView component(s)
                </Header>
                <p>
                    To begin, select a data source for your component from the Google spreadsheets available in your Google Docs account. Choose the spreadsheet document, a specific sheet tab within that document, and a meaningful name for your component.
                </p>
                <p>
                    After selecting a sheet, the wizard will display the columns it has discovered in that sheet, along with a default data type to display. Double check that each column has the appropriate data type for display. These selections will determine how your data is formatted and displayed to end users.
                </p>
                <p>
                    If you wish to hide certain columns from end users, click on the eye icon at the end of each column row to show or hide that particular column. AirJam will ensure that data from hidden fields is not exposed to end users.
                </p>
                <Image src="/images/blog/configure_data.png" />
                <p>
                    Click "Next" to begin configuring the look and feel of your component.
                </p>
                <p>&nbsp;</p>
                <p>
                    The "Look and Feel" section determines how your data is presented to users. The TableView component can be displayed as a list, gallery, graph, table, or map. Each type of visualization requires specific data formatting. For example, the map type requires each row of data to have either an address or latitude and longitude column to plot the data on a map. The graph type requires all columns used for plotting to be in numeric format.
                </p>
                <p>&nbsp;</p>
                <p>
                    Themes are available for each view type. For instance, the graph view type has themes such as bar graphs and pie charts. The example above shows theme options for the gallery view type, which has themes such as graphic menu and card list. Visual style typically specifies color and font configurations for the selected theme. To display your component properly, you must select a view type, a theme, and a visual style.
                </p>
                <Image src="/images/blog/configure_look.png" />
                <p>
                    Once you have selected your view type, theme, and style, you will be presented with options to map your data to the chosen template. Each template specifies the fields it wants to display in its view, and what type of field can be chosen for each of these fields. For example, the graphic menu template has a field called “item image”, which is used to display the main image for a single item. The template requires a field that can be displayed as a link to be chosen for that field. The link is used to vend the image for that menu item.
                </p>
                <p>&nbsp;</p>
                <p>
                    After selecting the appropriate field mappings and other options, such as sorting and pagination, click "Next" to complete the configuration of the component.
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </div>
            <div>
                <Header size="small">
                    3. Complete project configuration.
                </Header>
                <p>
                    After configuring all of your components, the wizard will display the final page. On this page, you will see a button to create a new project with your chosen configurations. Simply click on [Create Project] to create the project and be redirected back to your workspace.
                </p>
                <Image src="/images/blog/wizard_finish.png" />
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </div>
            <div>
                <Header size="small">
                    4. Publish your component
                </Header>
                <p>
                    Each component needs to be published to be available publicly. Any changes made to components from the workspace can be saved, but they will not take effect until the changes are published.
                </p>
                <p>
                    To publish a component, click on your project within your workspace, and select the desired component from the left panel of your project workspace.
                </p>
                <p>
                    After selecting the component, you will notice the "Save" and "Publish" buttons located on the right-hand panel. To make your new component available to the public, click on the "Publish" button. It's worth noting that the same workspace can be used to modify your component selections, and any changes made will be publicly available to your end-users once you click the "Publish" button.
                </p>
                <Image src="/images/blog/publish_component.png" />
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </div>
            <div>
                <Header size="small">
                    5. Add your new component to your application
                </Header>
                <p>
                    Every component includes instructions on how to add it to your application. To access these instructions, click on the "Overview" tab located on the left-hand panel for each specific component. This will provide you with a step-by-step guide on how to incorporate the component into your application.
                </p>
                <Image src="/images/blog/embed.png" />
                <p>&nbsp;</p>
            </div>
        </Container>;
    }
}