import { Timestamp } from "mongodb";

export enum CallPage {
    TableView = "table_view",
    Uploader = "uploader",
    Calendar = "calendar"
}

export enum PageActionType {
    GetComponent = "get_component",
    GetComponentData = "get_component_data",
    GetComponentStyle = "get_component_style",
    UploadFile = "upload_file",
    IngressSize = "ingress_size",
    OutgressSize = "outgress_size",
    ViewCount = "view_count"
}

export enum ActionStatus {
    Success = "success",
    Failed = "failed",
    NotFound = "not_found"
}

export enum CallWindow {
    TODAY = "today",
    WEEK = "week",
    MONTH = "30d"
}

export interface TsSliceData {
    table: number;
    _start: string;
    _stop: string;
    _time: string;
    _value: any;
    start: Date;
    stop: Date;
    time: Date;
}
export interface ProjectTsSliceData extends TsSliceData {
    project: string;
    id: string;
}

export function populateTsSliceDataDates(slice: TsSliceData) {
    slice.start = new Date(slice._start);
    slice.stop = new Date(slice._stop);
    slice.time = new Date(slice._time);
    return slice;
}