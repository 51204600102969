import React, { Fragment } from "react";
// eslint-disable-next-line
import { Route, Switch, match } from "react-router";
import ProjectDetail from "../components/project/ProjectDetail";
import ProjectList from "../components/project/ProjectList";
import CreateProject from "../components/project/CreateProject";
import EditProject from "../components/project/EditProject";
import connectAllProps from "../../shared/connect";
import { ComponentProps as Props } from "../../shared/ComponentProps";

interface States {}

class Workspace extends React.Component<Props, States> {
    componentDidMount() {
        this.props.actions.getProjects();
    }
    componentDidUpdate(prevProps: Props) {
        if (prevProps.state.projectState.valid && !this.props.state.projectState.valid) {
            this.props.actions.getProjects();
        }
        if (!prevProps.state.projectState.valid && this.props.state.projectState.valid) {
            this.forceUpdate();
        }

        if ((!prevProps.state.userState.currentUser && this.props.state.userState.currentUser)) {
            this.props.actions.restoreEditCache();
        }
    }
    render(): any {
        const match: match<any> = this.props.match;
        return <Fragment>
            <Switch>
                <Route exact path={match.url} render={(props) => <ProjectList {...props} />} />
                <Route path={`${match.url}/create`} render={(props) => <CreateProject {...props} />} />
                <Route path={`${match.url}/edit/:projectId`} render={(props) => <EditProject {...props} />} />
                <Route path={`${match.url}/:projectId`} render={(props) => <ProjectDetail {...props} />} />
            </Switch>
        </Fragment>;
    }
}

export default connectAllProps(Workspace);