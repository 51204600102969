/**
 * Sort predicates for dates.
 */
import Post from "../models/Post.d";
import Block from "../models/Block.d";
import Project from "../models/Project.d";
import BlockView from "../models/BlockView.d";
import { TableView } from "../models/TableView";
import { Uploader } from "../models/Uploader";
import { Calendar } from "../models/Calendar";

export const byCreatedAtLatestFirst = (first: Post | Project | Block | BlockView | TableView | Uploader | Calendar, second: Post | Project | Block | BlockView | TableView | Uploader | Calendar): number => {
    if (!first || !first.createdAt) {
        return 1;
    }
    if (!second || !second.createdAt) {
        return -1;
    }
    const firstDate: any = new Date(first.createdAt);
    const secondDate: any = new Date(second.createdAt);
    return secondDate - firstDate;
};

export const byCreatedAtOldestFirst = (first: Post | Project | Block | BlockView | TableView | Uploader | Calendar, second: Post | Block | Project | BlockView | TableView | Uploader | Calendar): number => {
    return byCreatedAtLatestFirst(second, first);
};

export const byCommentedAtLatestFirst = (first: Post, second: Post): number => {
    if (!first || !first.lastCommentedAt) {
        return 1;
    }
    if (!second || !second.lastCommentedAt) {
        return -1;
    }
    const firstDate: any = new Date(first.lastCommentedAt);
    const secondDate: any = new Date(second.lastCommentedAt);
    return secondDate - firstDate;
};

export const byCommentedAtOldestFirst = (first: Post, second: Post): number => {
    return byCommentedAtLatestFirst(second, first);
};