import React, { Fragment } from "react";
import connectAllProps from "../../../shared/connect";
import { Calendar } from "../../../models/Calendar";
import { byCreatedAtLatestFirst } from "../../../shared/date";
import { Container, Segment, Header, Icon, Button } from "semantic-ui-react";
import CalendarItem from "./CalendarItem";
import { CONTAINER_STYLE } from "../../../shared/styles";
import Loading from "./Loading";
import { FormattedMessage } from "react-intl";
import FabAction from "../../../models/client/FabAction";
import { ComponentProps as Props } from "../../../shared/ComponentProps";
import { Link } from "react-router-dom";

interface States {}

class CalendarList extends React.Component<Props, States> {
    render(): React.ReactElement<any> {
        return <Container text style={CONTAINER_STYLE}>
            {this.renderCreateCalendarSection()}
            {this.renderCalendars()}
            {this.renderLoadMore()}
        </Container>;
    }
    componentDidMount() {
        this.props.actions.resetRedirectTask();
        this.addFabActions();
    }
    componentDidUpdate(prevProps: Props) {
        if ((prevProps.state.calendarState.loading
            && !this.props.state.calendarState.loading) ||
            (!prevProps.state.userState.currentUser
            && this.props.state.userState.currentUser)) {
            this.addFabActions();
        }
    }
    componentWillUnmount() {
        this.props.actions.setFabActions([]);
    }
    private renderCalendars = (): React.ReactElement<any> => {
        if (this.props.state.calendarState.loading) {
            return <Loading />;
        } else {
            return <Fragment>
            {
                this.props.state.calendarState.data
                .sort(byCreatedAtLatestFirst).map(
                    (calendar: Calendar) => <CalendarItem key={calendar._id} calendar={calendar} />
                )
            }
            </Fragment>;
        }
    }
    private addFabActions = (): void => {
        if (this.props.state.userState.currentUser) {
            const editUri: string = "/calendars/create";
            const actions: FabAction[] = [{
                text: this.props.intl.formatMessage({id: "page.calendar.add"}),
                icon: "add",
                onClick: () => { this.props.history.push(editUri); },
            }];
            this.props.actions.setFabActions(actions);
        }
    }
    private renderCreateCalendarSection = (): React.ReactElement<any> | undefined => {
        const calendars: Calendar [] = this.props.state.calendarState.data;
        if (this.props.state.calendarState.loading) {
            return <Loading />;
        } else if (this.props.state.userState.currentUser) {
            if (!calendars || calendars.length === 0) {
                return <Segment placeholder textAlign="center">
                        <Header icon>
                            <Link to="/calendars/create">
                                <Icon name="plus circle" />
                                You do not have any components yet.
                            </Link>
                        </Header>
                        Click on the + button below to create a new component.
                    </Segment>;
            }
            return <></>;
        } else {
            if (calendars && calendars.length > 0) {
                return undefined;
            } else {
                return <></>;
            }
        }
    }

    private renderLoadMore = (): React.ReactElement<any> | undefined => {
        const calendars: Calendar [] = this.props.state.calendarState.data;
        if (this.props.state.calendarState.hasMore) {
            const loadingMore: boolean | undefined = this.props.state.calendarState.loadingMore;
            const createdAt: string | undefined = calendars[calendars.length - 1].createdAt;
            if (!createdAt) {
                return undefined;
            }
            return <Button fluid basic
                onClick={() => { this.loadMore(createdAt); }}
                loading={loadingMore}
                disabled={loadingMore} >
                <Button.Content>
                    <FormattedMessage id="page.calendar.load_more" />
                </Button.Content>
            </Button>;
        } else {
            return undefined;
        }
    }

    private loadMore = (createdAt: string): void => {
        this.props.actions.getMoreCalendars(createdAt);
    }
}

export default connectAllProps(CalendarList);